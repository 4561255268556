import { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import BurdCrumbs from "../../components/burdCrumbs";
import { add } from "../../assets/svgIcons";
import Modal from "../../components/modal";
import { alertsData } from "./data";
import './alerts.css'
import { Spinner } from "reactstrap";
const Alerts = () => {
  const [addLoading,setAddLoading]=useState(false)
  const [showAddNewAlert,setShowAddNewAlert]=useState(false)
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [alerts,setAlerts]=useState([]);
  const [newAlert,setNewAlert]=useState({
    title:'',
    description:'',
  })
  const getAlerts=()=>{
    setAlerts(alertsData)
  }
  const handleAddNewALert=()=>{
    setAddLoading(true)
    const data_send={
      ...newAlert
    }
    setAddLoading(false)
  }
  useEffect(()=>{
    getAlerts()
  },[])
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <div className="ed_bur">
              <BurdCrumbs title={"Alerts"} />
              <span onClick={()=>{
                setShowAddNewAlert(true)
              }} style={{width:'40px',fontSize:'40px',cursor:'pointer'}}>{add}</span>
            </div>
              <div className="alerts">
                {
                  alerts.map((item,index)=>{
                    return (
                      <div className="alert bg-light">
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    )
                  })
                }
              </div>
              <Modal
                open={showAddNewAlert}
                toggle={setShowAddNewAlert}
                headerTitle={"Add New Alert"}
                children={
                  <div>
                    <form onSubmit={(e)=>{
                      e.preventDefault();
                      handleAddNewALert()
                    }} action="">
                      <div className="col-lg-12 mb-2">
                        <label className="font-19 mb-2" htmlFor="">Title:</label>
                        <input className="form-control" placeholder="Title" type="text" />
                      </div>
                      <div className="col-lg-12 mb-2">
                        <label className="font-19 mb-2" htmlFor="">Description:</label>
                        <textarea name="" className="form-control" id="" cols="30" rows="10"></textarea>
                      </div>
                      <div className="text-center">
                        <button disabled={addLoading} style={{cursor:addLoading?'no-drop':'pointer'}} className="btn btn-success">
                          {
                            addLoading?<Spinner/>:'Add'
                          }
                        </button>
                      </div>
                    </form>
                  </div>
                }
              />
          </div>
        }
      />
    </div>
  );
};

export default Alerts;
