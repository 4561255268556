import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader, Toggle } from "rsuite";
import { Duplicate, addition } from "../../assets/svgIcons";
import BurdCrumbs from "../../components/burdCrumbs";
import { Input } from "../../components/form-elements";
import TextArea from "../../components/form-elements/textarea";
import Modal from "../../components/modal";
import TableLayout from "../../components/table";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
import { perms } from "../../data/side_nav_data";
import { exportToCSV } from "../../functions";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import Delete from "../chains/delete";

const TABS = [
  { id: 1, title: "Videos" },
  { id: 2, title: "PDFs" },
  // { id: 3, title: "Audios" },
];
const ChapterContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [vidPdfType, setVidPdfType] = useState("vid");
  const [deleted, setDeleted] = useState(false);
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [data, setData] = useState(false);
  const [videoTitle, setVideoTitle] = useState(false);
  const [videoDescription, setVideoDescription] = useState(false);
  const [addVideoOpen, setAddVideoOpen] = useState(false);
  const [editVideoOpen, setEditVideoOpen] = useState(false);
  const [pdfTitle, setPdfTitle] = useState(false);
  const [pdfDescription, setPdfDescription] = useState(false);
  const [pdfFile, setPdfFile] = useState(false);
  const [addPDFOpen, setAddPDFOpen] = useState(false);
  const [copy, setCopy] = useState(false);
  const [videoTopic, setVideoTopic] = useState(null);
  const [videoLoom, setVideoLoom] = useState(null);
  //
  const [editPDFOpen, setEditPDFOpen] = useState(false);
  const [audioTitle, setAudioTitle] = useState(false);
  const [audioDescription, setAudioDescription] = useState(false);
  const [audioFile, setAudioFile] = useState(false);
  const [addAudioOpen, setAddAudioOpen] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [copyAll, setCopyAll] = useState(false);
  const [getNumber, setNumber] = useState(false);
  const [video_id, set_video_id] = useState(null);
  const [deleteId, setDeleteId] = useState(false);
  const [editId, setEditId] = useState(false);
  const [opened, setOpened] = useState(null);
  const [videoNumber, setVideoNumber] = useState(null);
  const [videoData, setVideoData] = useState({});
  const [videoLink, setVideoLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tab, setTab] = useState(1);
  const [videoTimer, setVideoTimer] = useState(null);
  const [newData, setNewData] = useState(false);
  const [chains, setChains] = useState(false);
  const [selectedChain, setSelectedChain] = useState(false);
  const [courses, setCourses] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(false);
  const [chapters, setChapters] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState(false);
  const [selectedContent, setSelectedContent] = useState([]);
  const [currentNumber, setCurrentNumber] = useState(false);
  const [cipherData, setCipherData] = useState(false);
  useEffect(() => {}, [getNumber]);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (copy || copyAll) fetchChainsData();
  }, [copy, copyAll]);
  useEffect(() => {
    if (selectedChain) fetchCoursesData();
  }, [selectedChain]);
  useEffect(() => {
    if (selectedCourse) fetchChaptersData();
  }, [selectedCourse]);
  const pdfsHeaders = [
    {
      label: "ID",
      dataIndex: "video_id",
      search: true,
      sort: true,
    },
    perms?.includes("*5_5") || perms?.startsWith("5_5")
      ? {
          label: "Choose",
          dataIndex: "video_id",
          type: "children",
          children: ({ headers, row, index, lastIndex }) => {
            return (
              <input
                type="checkbox"
                name=""
                id=""
                checked={
                  selectedContent.filter(
                    (item) => item.video_id == row?.video_id
                  )?.length
                }
                onChange={(item) => {
                  if (
                    selectedContent.filter(
                      (item) => item.video_id == row?.video_id
                    )?.length
                  ) {
                    setSelectedContent(
                      selectedContent.filter(
                        (item) => item.video_id !== row?.video_id
                      )
                    );
                  } else {
                    selectedContent.push(row);
                    setSelectedContent([...selectedContent]);
                  }
                }}
              />
            );
          },
        }
      : {},
    perms?.includes("*5_3") ||
    perms?.startsWith("5_3") ||
    perms?.includes("*5_5") ||
    perms?.startsWith("5_5")
      ? {
          label: "Actions",
          type: "children",
          children: ({ headers, row, index, lastIndex }) => {
            return (
              <div className="menu_Open_close">
                <div
                  className="open_close"
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() =>
                    setOpened(opened == row?.video_id ? null : row?.video_id)
                  }
                >
                  <img
                    src="https://res.cloudinary.com/duovxefh6/image/upload/v1701489400/menu_hrpzcb.png"
                    alt=""
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
                {row?.video_id == opened ? (
                  <div
                    className={
                      index == lastIndex - 1 ||
                      index == lastIndex - 2 ||
                      index == lastIndex - 3
                        ? "actions-views row-actions-view"
                        : "actions-views column-actions-view"
                    }
                  >
                    <button
                      className={
                        row?.hidden == "no"
                          ? "btn btn-danger"
                          : "btn btn-success"
                      }
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setDeleteId(row);
                      }}
                    >
                      {row?.hidden == "no" ? "Hide" : "show"}
                    </button>
                    {perms?.includes("*5_5") || perms?.startsWith("5_5") ? (
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          setCopy(row);
                        }}
                      >
                        Duplicate
                      </button>
                    ) : null}
                    {perms?.includes("*5_3") || perms?.startsWith("5_3") ? (
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          setEditId(row);
                          if (row?.type == "video") {
                            setEditVideoOpen(true);
                          } else if (row?.type == "pdf") {
                            setEditPDFOpen(true);
                          }
                        }}
                      >
                        Edit
                      </button>
                    ) : null}{" "}
                    <button
                      className="btn btn-danger"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setDeleted({
                          value: row?.video_id,
                          table: "video",
                          column: "video_id",
                        });
                      }}
                    >
                      Delete
                    </button>
                    {/* setEditId */}
                  </div>
                ) : null}
              </div>
            );
          },
        }
      : {},
    perms?.includes("*5_6") || perms?.startsWith("5_6")
      ? {
          label: "Number",
          dataIndex: "video_number",
          search: true,
          sort: true,
          type: "children",
          children: ({ headers, row }) => {
            const handleBlur = async (video_id, video_number) => {
              set_video_id(null);
              setArrangeNumber(video_id, video_number);
            };

            const handleFocus = () => {
              setCurrentNumber(row?.video_number);
              set_video_id(row?.video_id);
            };

            const handleClick = () => {
              set_video_id(row?.video_id);
            };

            return (
              <div
                style={{ width: "fit-content", cursor: "pointer" }}
                onClick={handleClick}
              >
                <input
                  style={{ width: "120px", cursor: "pointer" }}
                  type="text"
                  onChange={(e) =>
                    setVideoData({ id: row?.video_id, number: e.target.value })
                  }
                  onFocus={() => handleFocus()}
                  defaultValue={
                    videoData?.id == row?.video_id
                      ? videoData?.number
                      : row?.video_number
                  }
                  value={
                    videoData?.id == row?.video_id
                      ? videoData?.number
                      : row?.video_number
                  }
                  disabled={loader}
                  className={
                    video_id === row?.video_id
                      ? "MR_input_form_element active"
                      : "MR_input_form_element lazy"
                  }
                  onBlur={async (e) => {
                    await handleBlur(row?.video_id, e.target.value);
                  }}
                />
              </div>
            );
          },
        }
      : {},
    // {
    //   label: "Sort",
    //   search: true,
    //   type: "children",
    //   children: ({ row, lastIndex, index }) => {
    //     return (
    //       <div className="sorting">
    //         {index !== 0 ? (
    //           <span
    //             style={{ cursor: "pointer", color: "green" }}
    //             onClick={() => {
    //               sortContent(row?.video_id, "up");
    //             }}
    //           >
    //             {UpArrow}
    //           </span>
    //         ) : null}
    //         {index !== lastIndex ? (
    //           <span
    //             style={{ cursor: "pointer", color: "red" }}
    //             onClick={() => {
    //               sortContent(row?.video_id, "down");
    //             }}
    //           >
    //             {DownArrow}
    //           </span>
    //         ) : null}
    //       </div>
    //     );
    //   },
    // },
    {
      label: "Type",
      dataIndex: "type",
      search: true,
      type: "children",
      children: ({ row }) => {
        return (
          <>
            <p> {row?.type} </p>
            {row?.type == "pdf" ? (
              <>
                {" "}
                Bunny{" "}
                <Toggle
                  checked={row?.pdf_type == "normal"}
                  onChange={(e) =>
                    changePdfType(
                      row?.video_id,
                      row?.pdf_type != "normal" ? "normal" : "bunny"
                    )
                  }
                />{" "}
                Normal{" "}
              </>
            ) : null}
          </>
        );
      },
      sort: true,
    },
    // {
    //   label: "time",
    //   dataIndex: "time",
    //   sort: true,
    // },
    {
      label: "Title",
      dataIndex: "video_title",
      search: true,
      sort: true,
    },
    // {
    //   label: "Description",
    //   dataIndex: "video_description",
    //   search: true,
    //   sort: true,
    // },
    {
      label: "View Questions",
      type: "children",
      children: ({ row }) => {
        return (
          <button
            className="btn btn-info"
            onClick={() =>
              navigate(
                "/chains/courses/chapters/VidoeQuestion?type=video&video_id=" +
                  row?.video_id
              )
            }
          >
            View
          </button>
        );
      },
    },
  ];
  const [uploadVideo, setUploadVideo] = useState(false);
  const [videoBunnyFile, setVideoBunnyFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoBunny, setVideoBunny] = useState(null);

  const uploadBunny = async (e) => {
    const formData = new FormData();
    formData.append("file", videoBunnyFile);
    if (editId?.video_title) {
      formData.append("title", editId?.video_title);
      formData.append("desc", "Education Video");
      // if (
      //   (!editId?.video_title && !editId?.video_title?.length) ||
      //   (!editId?.video_description && !editId?.video_description?.length)
      // ) {
      //   toast.error("video title and description are required");
      //   return;
      // }
    } else {
      formData.append("title", videoTitle);

      formData.append("desc", "Education Video");
      // if (
      //   (!videoTitle && !videoTitle?.length) ||
      //   (!videoDescription && !videoDescription?.length)
      // ) {
      //   toast.error("video title and description are required");
      //   return;
      // }
    }

    setUploadVideo(true);
    try {
      const response = await axios.post(
        BASE_URL + "upload_videos.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((loaded / total) * 100);
            setUploadProgress(progress);
          },
        }
      );
      console.log(response);
      if (response?.data?.id) {
        setUploadVideo(false);
        setVideoBunny((prev) => response.data?.id);
        console.log(videoBunny);
        if (editId?.video_title)
          setEditId({ ...editId, bunny_url: response.data?.id });
        setUploadProgress(0);
        toast.success("Uploadded Successfully");
      } else {
        setUploadVideo(false);
        setUploadProgress(0);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadVideo(false);
      toast.error("Error When Upload File");
    }
  };

  const [awsOptions, setAwsOptions] = useState({});

  const handleDrop = async (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const uploadResponse = await fetch(BASE_URL + "upload.php", {
        method: "POST",
        body: formData,
      });
      console.log(uploadResponse);
      if (uploadResponse.ok) {
        console.log("File uploaded successfully!");
      } else {
        console.error("Error uploading file:", uploadResponse.statusText);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };
  const fetchChainsData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "courses/chains/select_chain.php",
        {
          university_id: 1,
          grade_id: 1,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setChains(response?.data?.message);
    } catch (err) {
      setChains([]);
      toast.error(err?.message);
    }
  };
  const fetchCoursesData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "courses/courses/select_course_chain.php",
        {
          chain_id: selectedChain,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      setCourses(response?.data?.message);
    } catch (err) {
      setCourses([]);
      toast.error(err?.message);
    }
  };
  const fetchChaptersData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "courses/chapters/select_course_chapters.php",
        {
          course_id: selectedCourse,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      setChapters(response?.data?.message);
    } catch (err) {
      setChapters([]);
      toast.error(err?.message);
    }
  };
  window.addEventListener("click", (e) => {
    if (
      opened &&
      !e.target?.classList?.contains("actions-views") &&
      !e.target?.parentNode?.classList?.contains("open_close")
    ) {
      setOpened(null);
    }
  });
  const fetchData = async (tab) => {
    try {
      const response = await axios.post(
        BASE_URL + "content/select_video_by_chapter_id.php",
        {
          type:
            tab == 1 ? "video" : tab == 2 ? "pdf" : tab == 3 ? "audio" : null,
          chapter_id: location?.state?.chapter_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setData(
        response?.data?.message?.sort((a, b) => {
          return parseInt(a.video_number) - parseInt(b.video_number);
        })
      );
      return response?.data?.message?.sort((a, b) => {
        return parseInt(a.video_number) - parseInt(b.video_number);
      });
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const addVideo = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    try {
      const response = await axios.post(BASE_URL + "content/insert_video.php", {
        video_title: videoTitle,
        video_description: videoDescription,
        type: "video",
        chapter_id: location?.state?.chapter_id,
        video_url: videoLink,
        video_number: videoNumber,
        video_loom_url: videoLoom,
        cipher_data: cipherData,
        bunny_url: videoBunny,
        topic: videoTopic,
        video_timer: videoTimer,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setShowAdd(false);
        setCipherData(null)
        // window.location.reload();
        setVideoTitle(null);
        setVideoLink(null);
        setVideoDescription(null);
        setEditId(null);
        setVideoDescription(null);
        setVideoLink(null);
        setVideoTitle(null);
        setVideoBunny(null);
        setVideoLink(null);
        setVideoLoom(null);
        setVideoNumber(null);
        setVideoTimer(null);
        form.reset();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const addPdf = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("file_attachment", pdfFile);
    try {
      const pdfUrl = await axios.post(BASE_URL + "uploud_pdf.php", formData);
      if (pdfUrl?.data?.status == "success") {
        const response = await axios.post(
          BASE_URL + "content/insert_video.php",
          {
            chapter_id: location?.state?.chapter_id,
            video_title: pdfTitle,
            video_url: pdfUrl?.data?.message,
            video_description: pdfDescription,
            type: "pdf",
            video_number: videoNumber,
            topic: videoTopic,
            admin_id: userData?.user_id,
            access_token: userData?.access_token,
          }
        );
        if (response.data?.status == "success") {
          toast.success(response.data?.message);
          fetchData();
          setShowAdd(false);
          form.reset();
          setVideoNumber(null);
          setPdfTitle(null);
          setPdfFile(null);
          setEditId(null);
          setVideoDescription(null);
          setVideoLink(null);
          setVideoTitle(null);
          setVideoLink(null);
          setVideoLoom(null);
          setVideoNumber(null);
          // window.location.reload();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      } else {
        setPdfFile(false);
        toast.error("Failed on Upload Image");
        setLoading(false);
      }
      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const updateVideo = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    try {
      const response = await axios.post(BASE_URL + "content/update_video.php", {
        video_title: videoTitle ? videoTitle : editId?.video_title,
        video_description: videoDescription
          ? videoDescription
          : editId?.video_description,
        type: "video",
        chapter_id: editId?.chapter_id,
        video_url: videoLink ? videoLink : editId?.video_url,
        video_loom_url: videoLoom ? videoLoom : editId?.video_loom_url,
        bunny_url: videoBunny ? videoBunny : editId?.bunny_url,
        cipher_data: cipherData ? cipherData : editId?.cipher_data,
        // videoBunny
        topic: videoTopic ? videoTopic : editId?.topic,
        video_id: editId?.video_id,
        video_timer: videoTimer ? videoTimer : editId?.time,
        video_number: videoNumber ? videoNumber : editId?.video_number,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setEditVideoOpen(false);
        setEditId(null);
        setVideoDescription(null);
        setVideoLink(null);
        setVideoTitle(null);
        setVideoLink(null);
        setVideoLoom(null);
        setVideoBunny(null);
        setVideoTimer(null);
        setVideoNumber(null);
        // window.location.reload();
        form.reset();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const updatePdf = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("file_attachment", pdfFile);
    try {
      const pdfUrl = await axios.post(BASE_URL + "uploud_pdf.php", formData);
      if (pdfUrl?.data?.status == "success" || !pdfFile) {
        const response = await axios.post(
          BASE_URL + "content/update_video.php",
          {
            chapter_id: editId?.chapter_id,
            video_id: editId?.video_id,
            video_title: pdfTitle ? pdfTitle : editId?.video_title,
            video_url:
              pdfUrl?.data?.message && pdfFile
                ? pdfUrl?.data?.message
                : editId?.video_url,
            video_description: pdfDescription
              ? pdfDescription
              : editId?.video_description,
            video_number: videoNumber ? videoNumber : editId?.video_number,
            type: "pdf",
            admin_id: userData?.user_id,
            access_token: userData?.access_token,
          }
        );
        if (response.data?.status == "success") {
          toast.success(response.data?.message);
          fetchData();
          setEditId(null);
          setEditPDFOpen(false);
          form.reset();
          setVideoNumber(null);
          setPdfTitle(null);
          setPdfFile(null);
          setLoading(false);
          setEditId(null);
          setVideoDescription(null);
          setVideoLink(null);
          setVideoTitle(null);
          setVideoLink(null);
          setVideoNumber(null);
          setVideoLoom(null);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      } else {
        setPdfFile(false);
        toast.error("Failed on Upload Image");
        setLoading(false);
      }
      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const addAudio = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("file_attachment", audioFile);
    try {
      const audioUrl = await axios.post(
        BASE_URL + "uploud_audio.php",
        formData
      );
      if (audioUrl?.data?.status == "success") {
        const response = await axios.post(
          BASE_URL + "content/insert_audio.php",
          {
            chapter_id: location?.state?.chapter_id,
            audio_title: audioTitle,
            audio_url: audioUrl?.data?.message,
            audio_description: audioDescription,
            admin_id: userData?.user_id,
            access_token: userData?.access_token,
          }
        );

        if (response.data?.status == "success") {
          toast.success(response.data?.message);
          fetchData();
          setShowAdd(false);
          window.location.reload();
          form.reset();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      } else {
        setAudioFile(false);
        toast.error("Failed on Upload Image");
        setLoading(false);
      }
      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const handleDeleteContent = () => {
    const data_send = {
      video_id: deleteId?.video_id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
      hidden: deleteId?.hidden == "no" ? "yes" : "no",
    };
    axios
      .post(
        BASE_URL + "content/show_hide_content.php",
        JSON.stringify(data_send),
        {
          headers: {
            "Content-Type": "Application/Json",
          },
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          setDeleteId(false);
          fetchData();
          toast.success(res.data.massage);
        } else if (res.data.status == "error") {
          toast.error(res.data.massage);
        } else {
          toast.error("Something Went Error");
        }
      });
  };
  const setArrangeNumber = async (video_id, video_number) => {
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await axios
      .post(BASE_URL + "content/update_video_num.php", {
        video_id,
        video_number,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      })
      .then(async (response) => {
        toast.info(response?.data?.message);
        const newFData = await fetchData();
        setNewData(newFData);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  const copyContent = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    try {
      const response = await axios.post(
        BASE_URL + "content/insert_video.php",
        copy?.type == "video"
          ? {
              chapter_id: selectedChapter,
              video_title: copy?.video_title,
              video_url: copy?.video_url,
              video_description: copy?.video_description,
              type: copy?.type,
              video_number: copy?.video_number,
              video_loom_url: copy?.video_loom_url,
              bunny_url: copy?.bunny_url,
              video_timer: copy?.time,
              topic: copy?.topic,
              admin_id: userData?.user_id,
              access_token: userData?.access_token,
            }
          : copy?.type == "pdf"
          ? {
              chapter_id: selectedChapter,
              video_title: copy?.video_title,
              video_url: copy?.video_url,
              cipher_data: copy?.cipher_data,
              video_description: copy?.video_description,
              type: copy?.type,
              video_number: copy?.video_number,
              topic: copy?.topic,
              admin_id: userData?.user_id,
              access_token: userData?.access_token,
            }
          : {}
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setShowAdd(false);
        form.reset();
        setVideoNumber(null);
        setPdfTitle(null);
        setPdfFile(null);
        setEditId(null);
        setVideoDescription(null);
        setVideoLink(null);
        setVideoTitle(null);
        setVideoLink(null);
        setVideoLoom(null);
        setVideoNumber(null);
        // window.location.reload();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const copyManyContent = async () => {
    setLoading(true);

    try {
      selectedContent?.map(async (item) => {
        const response = await axios.post(
          BASE_URL + "content/insert_video.php",
          item?.type == "video"
            ? {
                chapter_id: selectedChapter,
                video_title: item?.video_title,
                video_url: item?.video_url,
                video_description: item?.video_description,
                cipher_data: item?.cipher_data,
                type: item?.type,
                video_number: item?.video_number,
                video_loom_url: item?.video_loom_url,
                bunny_url: item?.bunny_url,
                video_timer: item?.time,
                topic: item?.topic,
                admin_id: userData?.user_id,
                access_token: userData?.access_token,
              }
            : item?.type == "pdf"
            ? {
                chapter_id: selectedChapter,
                video_title: item?.video_title,
                video_url: item?.video_url,
                video_description: item?.video_description,
                type: item?.type,
                video_number: item?.video_number,
                topic: item?.topic,
                admin_id: userData?.user_id,
                access_token: userData?.access_token,
              }
            : {}
        );
        if (response.data?.status == "success") {
          toast.success(
            "Successed to Transfer " +
              " Content - " +
              item?.video_title +
              " - With Type - " +
              item?.type
          );

          setLoading(false);
        } else {
          setLoading(false);
          toast.error(
            "Failed to Transfer " +
              " Content - " +
              item?.video_title +
              " - With Type - " +
              item?.type
          );
        }
      });

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const changePdfType = async (video_id, pdf_type) => {
    try {
      const response = await axios.post(
        BASE_URL + "content/update_pdf_type.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          video_id,
          pdf_type, //'normal', 'bunny'
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();

        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  if (!location?.state?.chapter_id) {
    navigate(-1);
  }

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <BurdCrumbs
              title={"Chaper: (" + location?.state?.chapter_name + ")"}
            />
            {(perms?.includes("*17_4") || perms?.startsWith("17_4")) &&
            data?.length ? (
              <button
                className="btn btn-primary exportBtn"
                onClick={() => {
                  exportToCSV(
                    data,
                    location?.state?.chapter_name + " - Content Data"
                  );
                }}
              >
                Export
              </button>
            ) : null}
            <div className="row-btns" style={{ display: "flex" }}>
              {" "}
              {perms?.includes("*6_2") || perms?.startsWith("6_2") ? (
                <button
                  className="btn btn-primary btn_ind"
                  onClick={() => {
                    if (tab == 1) {
                      setAddVideoOpen(true);
                      setAddPDFOpen(false);
                      setAddAudioOpen(false);
                    } else if (tab == 2) {
                      setAddVideoOpen(true);
                    } else if (tab == 3) {
                      setAddVideoOpen(false);
                      setAddPDFOpen(false);
                      setAddAudioOpen(true);
                    }
                  }}
                >
                  <span>{addition}</span>
                  <span>Add </span>
                </button>
              ) : null}
              {perms?.includes("*6_5") || perms?.startsWith("6_5") ? (
                <>
                  {" "}
                  {selectedContent?.length ? (
                    <button
                      className="btn btn-primary btn_ind"
                      onClick={() => {
                        setCopyAll(true);
                      }}
                    >
                      <span>{Duplicate}</span>
                      <span>Duplicate ({selectedContent?.length})</span>
                    </button>
                  ) : null}
                </>
              ) : null}
            </div>
            <TableLayout
              setUrlPage={setNumber}
              headers={pdfsHeaders}
              data={data}
            />

            <Modal
              headerTitle={"Edit Video"}
              open={editVideoOpen}
              toggle={setEditVideoOpen}
              children={
                <form
                  action="#"
                  className="content_upload"
                  onSubmit={!loading ? updateVideo : (e) => e.preventDefault()}
                >
                  <div className="inputField">
                    <label htmlFor="video_title">Video Title</label>
                    <Input
                      type={"text"}
                      id={"video_title"}
                      name="video_title"
                      onChange={(target) => setVideoTitle(target.value)}
                      defaultValue={editId?.video_title}
                    />
                  </div>
                  {/* <div className="inputField">
                    <label htmlFor="topic"> Topic</label>
                    <Input
                      type={"text"}
                      id={"topic"}
                      name="topic"
                      defaultValue={editId?.topic}
                      onChange={(target) => setVideoTopic(target.value)}
                    />
                  </div> */}
                  {/* <div className="inputField">
                    <label htmlFor="video_description">Video Description</label>
                    <TextArea
                      type={"text"}
                      id={"video_description"}
                      name="video_description"
                      defaultValue={editId?.video_description}
                      onChange={(target) => setVideoDescription(target.value)}
                    />
                  </div> */}
                  <div className="rowDiv upload">
                    <div className="inputField">
                      <label htmlFor="bunny">CDN File</label>
                      <input
                        type={"file"}
                        id={"bunny"}
                        name="bunny"
                        // required={true}

                        onChange={(target) =>
                          setVideoBunnyFile((prev) => target.target.files[0])
                        }
                      />
                    </div>

                    {!uploadVideo ? (
                      <div
                        className="btn btn-success"
                        onClick={() => uploadBunny()}
                      >
                        Upload
                      </div>
                    ) : (
                      <div
                        className={
                          uploadProgress < 40
                            ? "loader-container"
                            : "loader-container active"
                        }
                      >
                        <div
                          className={"loader"}
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                        <span>{uploadProgress}%</span>
                        <Loader />
                      </div>
                    )}
                  </div>
                  <div className="inputField">
                    <label htmlFor="bunny">Video</label>
                    <Input
                      type={"text"}
                      id={"bunny"}
                      name="bunny"
                      // required={true}
                      value={videoBunny}
                      onChange={(target) => {
                        setVideoBunny((prev) => target.value);
                        setEditId({ ...editId, bunny_url: target.value });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="bunny">Bunny</label>
                    <Input
                      type={"text"}
                      id={"bunny"}
                      name="bunny"
                      // required={true}
                      defaultValue={editId?.bunny_url}
                      onChange={(target) => setVideoBunny(target.value)}
                    />
                  </div>{" "}
                  <div className="inputField">
                    <label htmlFor="bunnyurl"> Video Cipher</label>
                    <input
                      type={"text"}
                      id={"bunnyurl"}
                      name="bunnyurl"
                      value={editId?.cipher_data}
                      onChange={(target) => {
                        // setCipherData(target?.target?.value);
                        setEditId({ ...editId, cipher_data: target.value });
                      }}
                    />
                  </div>
                  {/* <div className="inputField">
                    <label htmlFor="timer"> Time</label>
                    <Input
                      type={"text"}
                      id={"timer"}
                      name="timer"
                      defaultValue={editId?.time}
                      onChange={(target) => setVideoTimer(target.value)}
                    />
                  </div>*/}
                  <div className="inputField">
                    <label htmlFor="timer"> Video Number</label>
                    <Input
                      type={"text"}
                      id={"Number"}
                      name="video_number"
                      defaultValue={editId?.video_number}
                      onChange={(target) => setVideoNumber(target.value)}
                    />
                  </div>
                  <button className="btn btn-success">
                    {!loading ? "Save" : <Loader />}
                  </button>
                </form>
              }
            />

            <Modal
              headerTitle={
                `Add ${vidPdfType == "vid" ? "Video" : "PDF"} To Chapter: ` +
                location?.state?.chapter_name
              }
              open={addVideoOpen}
              toggle={() => {
                setAddVideoOpen(false);
                setVidPdfType("vid");
              }}
              children={
                <>
                  {/* <div className="inputField"> */}
                  <label htmlFor="video_title">Content Type</label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setVidPdfType(e.target.value);
                    }}
                  >
                    <option value="vid">video</option>
                    <option value="pdf">pdf</option>
                  </select>
                  {/* </div> */}
                  {vidPdfType == "vid" ? (
                    <form
                      action="#"
                      className="content_upload"
                      onSubmit={!loading ? addVideo : (e) => e.preventDefault()}
                    >
                      <div className="inputField">
                        <label htmlFor="video_title">Video Title</label>
                        <Input
                          type={"text"}
                          id={"video_title"}
                          name="video_title"
                          required={true}
                          onChange={(target) => setVideoTitle(target.value)}
                        />
                      </div>
                      {/* <div className="inputField">
                        <label htmlFor="topic"> Topic</label>
                        <Input
                          type={"text"}
                          id={"topic"}
                          name="topic"
                          required={true}
                          onChange={(target) => setVideoTopic(target.value)}
                        />
                      </div> */}
                      {/* <div className="inputField">
                        <label htmlFor="video_description">
                          Video Description
                        </label>
                        <TextArea
                          type={"text"}
                          id={"video_description"}
                          name="video_description"
                          onChange={(target) =>
                            setVideoDescription(target.value)
                          }
                        />
                      </div> */}

                      <div className="rowDiv upload">
                        <div className="inputField">
                          <label htmlFor="bunny">Video File</label>
                          <input
                            type={"file"}
                            id={"bunny"}
                            name="bunny"
                            // required={true}

                            onChange={(target) =>
                              setVideoBunnyFile(
                                (prev) => target.target.files[0]
                              )
                            }
                          />
                        </div>
                        {!uploadVideo ? (
                          <div
                            className="btn btn-success"
                            onClick={() => uploadBunny()}
                          >
                            Upload
                          </div>
                        ) : (
                          <div
                            className={
                              uploadProgress < 40
                                ? "loader-container"
                                : "loader-container active"
                            }
                          >
                            <div
                              className={"loader"}
                              style={{ width: `${uploadProgress}%` }}
                            ></div>
                            <span>{uploadProgress}%</span>
                            <Loader />
                          </div>
                        )}
                      </div>

                      <div className="inputField">
                        <label htmlFor="bunnyurl"> Video Link</label>
                        <input
                          type={"text"}
                          id={"bunnyurl"}
                          name="bunnyurl"
                          value={videoBunny}
                          onChange={(target) =>
                            setVideoBunny(target?.target?.value)
                          }
                        />
                      </div>

                      <div className="inputField">
                        <label htmlFor="bunnyurl"> Video Cipher</label>
                        <input
                          type={"text"}
                          id={"bunnyurl"}
                          name="bunnyurl"
                          value={cipherData}
                          onChange={(target) =>
                            setCipherData(target?.target?.value)
                          }
                        />
                      </div>
                      {/* <div className="inputField">
                        <label htmlFor="timer"> Time</label>
                        <Input
                          type={"text"}
                          id={"timer"}
                          name="timer"
                          required={true}
                          onChange={(target) => setVideoTimer(target.value)}
                        />
                      </div> */}
                      <div className="inputField">
                        <label htmlFor="video_number">Video Number</label>
                        <Input
                          type={"text"}
                          id={"video_number"}
                          name="video_number"
                          onChange={(target) => setVideoNumber(target.value)}
                        />
                      </div>

                      <button className="btn btn-success">
                        {!loading ? "Save" : <Loader />}
                      </button>
                    </form>
                  ) : (
                    <form
                      action="#"
                      className="content_upload"
                      onSubmit={!loading ? addPdf : (e) => e.preventDefault()}
                    >
                      <div className="inputField">
                        <label htmlFor="pdf_title">PDF Title</label>
                        <Input
                          type={"text"}
                          id={"pdf_title"}
                          name="pdf_title"
                          required={true}
                          onChange={(target) => setPdfTitle(target.value)}
                        />
                      </div>
                      {/* <div className="inputField">
                        <label htmlFor="topic"> Topic</label>
                        <Input
                          type={"text"}
                          id={"topic"}
                          name="topic"
                          required={true}
                          onChange={(target) => setVideoTopic(target.value)}
                        />
                      </div> */}
                      <div className="inputField">
                        <label htmlFor="pdf_title">PDF Number</label>
                        <Input
                          type={"number"}
                          id={"pdf_title"}
                          name="pdf_title"
                          onChange={(target) => setVideoNumber(target.value)}
                        />
                      </div>
                      {/* <div className="inputField">
                        <label htmlFor="pdf_description">PDF Description</label>
                        <TextArea
                          type={"text"}
                          id={"pdf_description"}
                          name="pdf_description"
                          onChange={(target) => setPdfDescription(target.value)}
                        />
                      </div> */}
                      <div className="inputField">
                        <label htmlFor="pdf_file">Upload Pdf</label>
                        <Input
                          type={"file"}
                          id={"pdf_file"}
                          name="pdf_file"
                          required={true}
                          onChange={(target) => setPdfFile(target.files[0])}
                          showImage={false}
                        />
                        <button className="btn btn-success">
                          {!loading ? "Save" : <Loader />}
                        </button>
                      </div>
                    </form>
                  )}
                </>
              }
            />

            <Modal
              headerTitle={"Edit PDF"}
              open={editPDFOpen}
              toggle={setEditPDFOpen}
              children={
                <form
                  action="#"
                  className="content_upload"
                  onSubmit={!loading ? updatePdf : (e) => e.preventDefault()}
                >
                  <div className="inputField">
                    <label htmlFor="pdf_title">PDF Title</label>
                    <Input
                      type={"text"}
                      id={"pdf_title"}
                      name="pdf_title"
                      required={true}
                      defaultValue={editId?.video_title}
                      onChange={(target) => setPdfTitle(target.value)}
                    />
                  </div>
                  {/* <div className="inputField">
                    <label htmlFor="topic">Topic</label>
                    <Input
                      type={"text"}
                      id={"topic"}
                      name="topic"
                      defaultValue={editId?.topic}
                      onChange={(target) => setVideoTopic(target.value)}
                    />
                  </div> */}
                  <div className="inputField">
                    <label htmlFor="pdf_description">PDF Description</label>
                    <TextArea
                      type={"text"}
                      id={"pdf_description"}
                      name="pdf_description"
                      defaultValue={editId?.video_description}
                      onChange={(target) => setPdfDescription(target.value)}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="timer"> PDF Number</label>
                    <Input
                      type={"text"}
                      id={"Number"}
                      name="video_number"
                      defaultValue={editId?.video_number}
                      onChange={(target) => setVideoNumber(target.value)}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="pdf_file">Upload Pdf</label>
                    <Input
                      type={"file"}
                      id={"pdf_file"}
                      name="pdf_file"
                      onChange={(target) => setPdfFile(target.files[0])}
                      showImage={false}
                    />
                    <button className="btn btn-success">
                      {!loading ? "Save" : <Loader />}
                    </button>
                  </div>
                </form>
              }
            />
            <Modal
              headerTitle={
                "Add Audio To Chapter: " + location?.state?.chapter_name
              }
              open={addAudioOpen}
              toggle={setAddAudioOpen}
              children={
                <form
                  action="#"
                  className="content_upload"
                  onSubmit={!loading ? addAudio : (e) => e.preventDefault()}
                >
                  <div className="inputField">
                    <label htmlFor="audio_title">Audio Title</label>
                    <Input
                      type={"text"}
                      id={"audio_title"}
                      name="audio_title"
                      onChange={(target) => setAudioTitle(target.value)}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="audio_description">Audio Description</label>
                    <TextArea
                      type={"text"}
                      id={"audio_description"}
                      name="audio_description"
                      onChange={(target) => setAudioDescription(target.value)}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="audio_file">Upload Audio</label>
                    <Input
                      type={"file"}
                      id={"audio_file"}
                      name="audio_file"
                      onChange={(target) => setAudioFile(target.files[0])}
                      showImage={false}
                    />
                    <button className="btn btn-success">
                      {!loading ? "Save" : <Loader />}
                    </button>
                  </div>
                </form>
              }
            />
            <Modal
              headerTitle={"Copy Content"}
              open={copy}
              toggle={() => {
                setCopy(false);
              }}
              children={
                <>
                  {/* <div className="inputField"> */}
                  <label htmlFor="video_title">Choose Chain</label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setSelectedChain(e.target.value);
                    }}
                  >
                    <option value={null}></option>
                    {!chains ? (
                      <Loader />
                    ) : chains && chains?.length && Array.isArray(chains) ? (
                      chains.map((item, index) => {
                        return (
                          <option value={item?.chain_id}>
                            {item?.chain_name}
                          </option>
                        );
                      })
                    ) : null}
                  </select>
                  <label htmlFor="video_title">Choose Course</label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setSelectedCourse(e.target.value);
                    }}
                  >
                    <option value={null}></option>
                    {!courses ? (
                      <Loader />
                    ) : courses && courses?.length && Array.isArray(courses) ? (
                      courses.map((item, index) => {
                        return (
                          <option value={item?.course_id}>
                            {item?.course_name}
                          </option>
                        );
                      })
                    ) : null}
                  </select>
                  <label htmlFor="video_title">Choose Chapter</label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setSelectedChapter(e.target.value);
                    }}
                  >
                    <option value={null}></option>
                    {!chapters ? (
                      <Loader />
                    ) : chapters &&
                      chapters?.length &&
                      Array.isArray(chapters) ? (
                      chapters.map((item, index) => {
                        return (
                          <option value={item?.chapter_id}>
                            {item?.chapter_title}
                          </option>
                        );
                      })
                    ) : null}
                  </select>{" "}
                  {/* </div> */}
                  <form
                    action="#"
                    className="content_upload"
                    onSubmit={
                      !loading ? copyContent : (e) => e.preventDefault()
                    }
                  >
                    <div className="inputField">
                      <label htmlFor="video_title">New Content Name</label>
                      <input
                        type="text"
                        className="MR_input_form_element"
                        value={copy?.video_title}
                        onChange={(e) => {
                          setCopy({ ...copy, video_title: e.target.value });
                        }}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="video_number">New Content Number</label>
                      <input
                        type="number"
                        className="MR_input_form_element"
                        value={copy?.video_number}
                        onChange={(e) => {
                          setCopy({ ...copy, video_number: e.target.value });
                        }}
                      />
                    </div>
                    <button className="btn btn-success">
                      {!loading ? "Save" : <Loader />}
                    </button>
                  </form>
                </>
              }
            />

            <Modal
              headerTitle={"Copy Content"}
              open={copyAll}
              toggle={() => {
                setCopyAll(false);
              }}
              children={
                <>
                  {/* <div className="inputField"> */}
                  <label htmlFor="video_title">Choose Chain</label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setSelectedChain(e.target.value);
                    }}
                  >
                    <option value={null}></option>
                    {!chains ? (
                      <Loader />
                    ) : chains && chains?.length && Array.isArray(chains) ? (
                      chains.map((item, index) => {
                        return (
                          <option value={item?.chain_id}>
                            {item?.chain_name}
                          </option>
                        );
                      })
                    ) : null}
                  </select>
                  <label htmlFor="video_title">Choose Course</label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setSelectedCourse(e.target.value);
                    }}
                  >
                    <option value={null}></option>
                    {!courses ? (
                      <Loader />
                    ) : courses && courses?.length && Array.isArray(courses) ? (
                      courses.map((item, index) => {
                        return (
                          <option value={item?.course_id}>
                            {item?.course_name}
                          </option>
                        );
                      })
                    ) : null}
                  </select>
                  <label htmlFor="video_title">Choose Chapter</label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setSelectedChapter(e.target.value);
                    }}
                  >
                    <option value={null}></option>
                    {!chapters ? (
                      <Loader />
                    ) : chapters &&
                      chapters?.length &&
                      Array.isArray(chapters) ? (
                      chapters.map((item, index) => {
                        return (
                          <option value={item?.chapter_id}>
                            {item?.chapter_title}
                          </option>
                        );
                      })
                    ) : null}
                  </select>{" "}
                  <button
                    className="btn btn-success"
                    onClick={() => (!loading ? copyManyContent() : null)}
                  >
                    {!loading ? "Save" : <Loader />}
                  </button>
                </>
              }
            />

            <Modal
              open={deleteId}
              toggle={setDeleteId}
              headerTitle={"Ahoq Hide Content"}
              children={
                <>
                  <h5>{"Are You Sure To Show / Hide Content " + " ?"}</h5>
                  <div className="flex-box">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        handleDeleteContent();
                      }}
                    >
                      Yes
                    </button>

                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setDeleteId(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />
          </div>
        }
      />
      <Delete data={deleted} setData={setDeleted} fetchData={fetchData} />
      {/* <LiveChatPlugin /> */}
    </div>
  );
};

export default ChapterContent;
