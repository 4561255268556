import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import { Input } from "../../components/form-elements";
import { addition } from "../../assets/svgIcons";
import { userData } from "../../data/fake_login_data";
import { perms } from "../../data/side_nav_data";
import { exportToCSV } from "../../functions";
const Reasons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [hiddenId, setHiddenId] = useState(false);
  const [reasonName, setReasonName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [editId, setEditId] = useState(false);
  const [opened, setOpened] = useState(null);
  const headers = [
    {
      label: "ID",
      dataIndex: "ban_reason_id",
      search: true,
      sort: true,
    },
    {
      label: "reason",
      dataIndex: "ban_reason",
      search: true,
      sort: true,
    },

    {
      label: "Current status",
      type: "children",
      children: ({ row }) => {
        if (row.hidden !== "yes") {
          return <span className="text-success textCurrent">Active</span>;
        } else {
          return <span className="text-danger textCurrent">Inactive</span>;
        }
      },
    },
    perms?.includes("*13_3") || perms?.startsWith("13_3")
      ? {
          label: "Actions",
          type: "children",
          children: ({ headers, row, index, lastIndex }) => {
            return (
              <div className="menu_Open_close">
                <div
                  className="open_close"
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() =>
                    setOpened(
                      opened == row?.ban_reason_id ? null : row?.ban_reason_id
                    )
                  }
                >
                  <img
                    src="https://res.cloudinary.com/duovxefh6/image/upload/v1701489400/menu_hrpzcb.png"
                    alt=""
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
                {row?.ban_reason_id == opened ? (
                  <div
                    className={
                      index == lastIndex - 1 ||
                      index == lastIndex - 2 ||
                      index == lastIndex - 3
                        ? "actions-views row-actions-view"
                        : "actions-views column-actions-view"
                    }
                  >
                    {/* <button
                  className="btn btn-danger"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setDeleteId(row);
                  }}
                >
                  Delete
                </button> */}
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setEditId(row);
                      }}
                    >
                      Edit
                    </button>
                    {/* setEditId */}
                  </div>
                ) : null}
              </div>
            );
          },
        }
      : {},
    perms?.includes("*13_5") || perms?.startsWith("13_5")
      ? {
          label: "Change status",
          type: "children",
          children: ({ row }) => {
            if (row.hidden !== "yes") {
              return (
                <span
                  className="btn btn-success"
                  style={{ color: "black" }}
                  onClick={() =>
                    setHiddenId({
                      reason_id: row?.reason_id,
                      reason_name: row?.reason_name,
                      status: "yes",
                    })
                  }
                >
                  Active
                </span>
              );
            } else {
              return (
                <span
                  className="btn btn-danger"
                  style={{ color: "black" }}
                  onClick={() =>
                    setHiddenId({
                      reason_id: row?.reason_id,
                      reason_name: row?.reason_name,
                      status: "no",
                    })
                  }
                >
                  Inactive
                </span>
              );
            }
          },
        }
      : {},

    // setHiddenId
  ];

  useEffect(() => {
    fetchData();
  }, []);

  window.addEventListener("click", (e) => {
    if (
      opened &&
      !e.target?.classList?.contains("actions-views") &&
      !e.target?.parentNode?.classList?.contains("open_close")
    ) {
      setOpened(null);
    }
  });

  const fetchData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "ban/select_ban_reason.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          university_id: 1,
        }
      );
      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const addReason = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    try {
      const response = await axios.post(BASE_URL + "ban/insert_reason.php", {
        ban_reason: reasonName?.value,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });

      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setShowAdd(false);
        form.reset();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const handleDeleteReason = () => {
    const data_send = {
      reason_id: deleteId?.reason_id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
    };
    axios
      .post(BASE_URL + "reasons/delete_reason.php", JSON.stringify(data_send))
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setDeleteId(false);
          fetchData();
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      });
  };
  const handleEditReason = (e) => {
    e.preventDefault();
    console.log(editId?.reason_id);
    const data_send = {
      ban_reason_id: editId?.ban_reason_id,
      ban_reason: reasonName,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
    };
    try {
      axios.post(BASE_URL + "ban/update_reason.php", data_send).then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setEditId(false);
          fetchData();
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const hideUniv = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL + "reasons/show_hide_reason.php",
        {
          reason_id: hiddenId?.reason_id,
          hidden: hiddenId?.status,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          university_id: 1,
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setHiddenId(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span>Reasons</span>
              {perms?.includes("*13_2") || perms?.startsWith("13_2") ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowAdd(true)}
                >
                  <span>{addition}</span>
                  <span>Add Reason</span>
                </button>
              ) : null}
            </h4>
            {(perms?.includes("*17_7") || perms?.startsWith("17_7")) &&
            data?.length ? (
              <button
                className="btn btn-primary exportBtn"
                onClick={() => {
                  exportToCSV(data, "Reasons Data");
                }}
              >
                Export
              </button>
            ) : null}
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />
          </div>
        }
      />
      <Modal
        headerTitle={"Add Reason"}
        open={showAdd}
        toggle={setShowAdd}
        children={
          <form
            action="#"
            onSubmit={!loading ? addReason : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="reason_name">Reason Name</label>
              <Input
                type={"text"}
                id={"reason_name"}
                name={"reason_name"}
                onChange={setReasonName}
              />
            </div>

            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
      <Modal
        open={hiddenId}
        toggle={setHiddenId}
        headerTitle={"Show / Hide Reason"}
        children={
          <>
            <h5>
              Are You Sure To{" "}
              {hiddenId?.status == "true" ? "Active - " : "Inactive - "}
              {hiddenId?.reason_name} ?
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  return !loading ? hideUniv() : null;
                }}
              >
                {!loading ? "Yes" : <Loader />}
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setHiddenId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />
      <Modal
        open={deleteId}
        toggle={setDeleteId}
        headerTitle={"Delete Reason"}
        children={
          <>
            <h5>
              {"Are You Sure To Delete Unversity - " +
                deleteId?.reason_name +
                " ?"}
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  handleDeleteReason();
                }}
              >
                Yes
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setDeleteId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />

      <Modal
        headerTitle={"Edit Reason"}
        open={editId}
        toggle={setEditId}
        children={
          <form
            action="#"
            onSubmit={!loading ? handleEditReason : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="reason_name">Reason Name</label>
              <Input
                type={"text"}
                id={"reason_name"}
                name={"reason_name"}
                defaultValue={editId?.ban_reason}
                onChange={(e) => setReasonName(e.value)}
              />
            </div>

            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
    </div>
  );
};

export default Reasons;
