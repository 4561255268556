import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import { userData } from "../../data/fake_login_data";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "rsuite";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useLocation, useSearchParams } from "react-router-dom";
import "./studentcourses.css";

const StudentProfile = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [courses, setCourses] = useState(null);
  const [chapters, setChapters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useSearchParams();
  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL + "students/select_st_profile.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          student_id: param.get("stu_id"),
          type: "id",
        }
      );
      if (response?.data?.status === "success") {
        console.log(response?.data?.message);
        setData(response?.data?.message);
      } else toast.error("Student Not Found");
      setLoading(false);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setChapters(
      data?.subscriptions?.filter((item) => item?.sub_type === "chapter")
    );
    setCourses(
      data?.subscriptions?.filter((item) => item?.sub_type === "course")
    );
  }, [data]);

  return (
    <div id="home" className="enquiry">
      <DefaultLayout
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span>Student Profile</span>
            </h4>

            <div className="student_info">
              <img
                src={
                  data?.student_photo && data?.student_photo?.length
                    ? data?.student_photo
                    : "https://camp-coding.tech/dr_elmatary/profile_icons/1032037.png"
                }
                alt=""
              />
              <div className="text">
                <span>
                  {data?.student_name} - {data?.uni_name} - {data?.grade_name}
                </span>
                <span>{data?.student_email}</span>
                <span>{data?.student_phone}</span>
              </div>
            </div>
            <div className="cardData">
              {data ? (
                <table border="1">
                  <tbody>
                    {data
                      ? Object.keys(data)?.map((item) => {
                          console.log(item);
                          if (item != "subscriptions")
                            return (
                              <tr className="cardRow" key={item}>
                                <td>{item}</td>
                                <td>
                                  <div className="tdRow">
                                    <span>{data[item]}</span>
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                      : null}
                  </tbody>
                </table>
              ) : null}
            </div>
            <h2 className="student_courses_h2">Subscription Courses</h2>
            <div className="courses_student">
              {courses && courses?.length ? (
                courses?.map((item) => {
                  console.log("course Item:", item); // Log the entire item object

                  return (
                    <div
                      style={{
                        textAlign: "start",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={
                          item?.course_details?.course_photo &&
                          item?.course_details?.course_photo?.length
                            ? item?.course_details?.course_photo
                            : "https://elmatary.com/El_Matary_Platform/platform/admin/images/225557437_1693865054item_img.jpeg"
                        }
                        alt=""
                      />
                      <h5 style={{ marginBottom: "15px" }}>
                        <span style={{ fontWeight: "900", fontSize: "15px" }}>
                          Course :{" "}
                        </span>{" "}
                        <em
                          style={{
                            fontStyle: "normal",
                            fontWeight: "300",
                            fontSize: "14.1px",
                          }}
                        >
                          {" "}
                          {item?.course_details?.course_name}{" "}
                        </em>
                      </h5>
                      <div>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: "900", fontSize: "15px" }}>
                            Subscription date :{" "}
                          </span>{" "}
                          <em style={{ fontStyle: "normal" }}>
                            {" "}
                            {item?.date}{" "}
                          </em>
                        </p>
                      </div>

                      <button className="btn btn-danger">
                        Cancel Subscription
                      </button>
                    </div>
                  );
                })
              ) : (
                <h4 style={{ textAlign: "center" }}>No Subscription</h4>
              )}
            </div>
            <h2 className="student_courses_h2">Subscription Chapters</h2>
            <div className="courses_student">
              {" "}
              {chapters?.length && chapters ? (
                chapters?.map((item) => {
                  console.log("Chapter Item:", item); // Log the entire item object

                  return (
                    <div
                      style={{
                        textAlign: "start",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={
                          item?.chapter_details?.chapter_photo &&
                          item?.chapter_details?.chapter_photo?.length
                            ? item?.chapter_details?.chapter_photo
                            : "https://elmatary.com/El_Matary_Platform/platform/admin/images/225557437_1693865054item_img.jpeg"
                        }
                        alt=""
                      />
                      <h5 style={{ marginBottom: "15px" }}>
                        <span style={{ fontWeight: "900", fontSize: "15px" }}>
                          Chapters :{" "}
                        </span>{" "}
                        <em
                          style={{
                            fontStyle: "normal",
                            fontWeight: "300",
                            fontSize: "14.1px",
                          }}
                        >
                          {" "}
                          {item?.chapter_details?.chapter_title}{" "}
                        </em>
                      </h5>
                      <div>
                        <p style={{ margin: 0 }}>
                          <span style={{ fontWeight: "900", fontSize: "15px" }}>
                            Subscription date :{" "}
                          </span>{" "}
                          <em style={{ fontStyle: "normal" }}>
                            {" "}
                            {item?.date}{" "}
                          </em>
                        </p>
                      </div>

                      <button className="btn btn-danger">
                        Cancel Subscription
                      </button>
                    </div>
                  );
                })
              ) : (
                <h4 style={{ textAlign: "center" }}>No Subscription</h4>
              )}
            </div>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
};

export default StudentProfile;
