import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/modal";
import { Input } from "../../components/form-elements";
import { userData } from "../../data/fake_login_data";
import { BASE_URL } from "../../constants";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";

const Cards = () => {
  const navigate = useNavigate();
  const [newSrc, setNewSrc] = useState(null);
  const [srcObj, setSrcObj] = useState(null);
  const [file, setFile] = useState(null);
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Courses, setCourses] = useState(null);
  const [Chapters, setChapters] = useState(null);
  const [newCardData, setNewCardData] = useState({
    admin_id: userData?.user_id,
    access_token: userData?.access_token,
    order_title: null,
    count: null,
    sub_type: "course", //'course', 'chapter'
    courses_ids: null,
  });
  // setNewCardData
  const [createCards, setCreateCards] = useState(false);
  // setCreateCards
  const [cardsPosition, setCardsPosition] = useState({
    cardCode: {
      top: 49,
      left: 82,
      font: 17,
    },
    cardId: {
      top: 166,
      left: 19,
      font: 13,
    },
    cardImage: {
      width: 385,
      height: 248,
    },
    templet_name: null,
  });

  useEffect(() => {
    if (srcObj) {
      setNewSrc(URL.createObjectURL(srcObj));
      setFile(srcObj);
    }
  }, [srcObj]);
  useEffect(() => {
    if (newCardData?.sub_type == "course") {
      getCourses();
    }

    if (newCardData?.sub_type == "chapter") {
      getChapters();
    }
  }, [newCardData?.sub_type]);
  const getCourses = async () => {
    const univsAndGradesChainsCourses = await axios.post(
      BASE_URL + "subscriptions/cards/select_courses.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );
    setCourses(univsAndGradesChainsCourses?.data?.message);
  };
  const getChapters = async () => {
    const univsAndGradesChainsCoursesChapters = await axios.post(
      BASE_URL + "courses/chapters/select_course_chapters.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );

    setChapters(univsAndGradesChainsCoursesChapters?.data?.message);
  };

  const createTemplate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("image", srcObj);
    try {
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
      if (imageUpload?.data?.status == "success") {
        const data_send = {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          templet_name: cardsPosition.templet_name,
          image_url: imageUpload?.data?.message,
          card_width: cardsPosition.cardImage?.width,
          card_height: cardsPosition.cardImage?.height,
          code_fontsize: cardsPosition.cardCode?.font,
          code_top: cardsPosition.cardCode?.top,
          code_left: cardsPosition.cardCode?.left,
          id_fontsize: cardsPosition.cardId?.font,
          id_top: cardsPosition.cardId?.top,
          id_left: cardsPosition.cardId?.left,
        };
        axios
          .post(
            BASE_URL + "subscriptions/cards/design_templets/create_templet.php",
            JSON.stringify(data_send)
          )
          .then((res) => {
            if (res.data.status == 'success') {
              toast.success(res.data.message);
              // fetchData();
            } else if (res.data.status == 'error') {
              toast.error(res.data.message);
            } else {
              toast.error("Something Went Error");
            }
          });
      }
    } catch (e) {}
  };
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={null}
        children={
          <div className="childs">
            <div className="rowDiv">
              <div className="columnDiv">
                <div className="columnDiv">
                  <label htmlFor="">Code Position Top</label>
                  <input
                    type="number"
                    value={cardsPosition?.cardCode?.top}
                    onChange={(e) =>
                      setCardsPosition({
                        ...cardsPosition,
                        cardCode: {
                          ...cardsPosition?.cardCode,
                          top: e.target?.value,
                        },
                      })
                    }
                    onFocus={() => {
                      setActive("code");
                    }}
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="">Code Position Left</label>
                  <input
                    type="number"
                    value={cardsPosition?.cardCode?.left}
                    onFocus={() => {
                      setActive("code");
                    }}
                    onChange={(e) =>
                      setCardsPosition({
                        ...cardsPosition,
                        cardCode: {
                          ...cardsPosition?.cardCode,
                          left: e.target?.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="">Code Font Size</label>
                  <input
                    type="number"
                    value={cardsPosition?.cardCode?.font}
                    onChange={(e) =>
                      setCardsPosition({
                        ...cardsPosition,
                        cardCode: {
                          ...cardsPosition?.cardCode,
                          font: e.target?.value,
                        },
                      })
                    }
                    onFocus={() => {
                      setActive("code");
                    }}
                  />
                </div>
              </div>
              <div className="columnDiv">
                <div className="columnDiv">
                  <label htmlFor="">Card Width</label>
                  <input
                    type="number"
                    onFocus={() => {
                      setActive("card");
                    }}
                    value={cardsPosition?.cardImage?.width}
                    onChange={(e) =>
                      setCardsPosition({
                        ...cardsPosition,
                        cardImage: {
                          ...cardsPosition?.cardImage,
                          width: e.target?.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="">Card Height</label>
                  <input
                    type="number"
                    onFocus={() => {
                      setActive("card");
                    }}
                    value={cardsPosition?.cardImage?.height}
                    onChange={(e) =>
                      setCardsPosition({
                        ...cardsPosition,
                        cardImage: {
                          ...cardsPosition?.cardImage,
                          height: e.target?.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="columnDiv">
                <div className="columnDiv">
                  <label htmlFor="">Id Position Top</label>
                  <input
                    type="number"
                    onFocus={() => {
                      setActive("id");
                    }}
                    value={cardsPosition?.cardId?.top}
                    onChange={(e) =>
                      setCardsPosition({
                        ...cardsPosition,
                        cardId: {
                          ...cardsPosition?.cardId,
                          top: e.target?.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="">Id Position Left</label>
                  <input
                    type="number"
                    onFocus={() => {
                      setActive("id");
                    }}
                    value={cardsPosition?.cardId?.left}
                    onChange={(e) =>
                      setCardsPosition({
                        ...cardsPosition,
                        cardId: {
                          ...cardsPosition?.cardId,
                          left: e.target?.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="">Id Font</label>
                  <input
                    type="number"
                    onFocus={() => {
                      setActive("id");
                    }}
                    value={cardsPosition?.cardId?.font}
                    onChange={(e) =>
                      setCardsPosition({
                        ...cardsPosition,
                        cardId: {
                          ...cardsPosition?.cardId,
                          font: e.target?.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                createTemplate(e);
              }}
            >
              <div className="cardContainer">
                <div className="inputField">
                  <label htmlFor="">Template Name</label>
                  <Input
                    type={"text"}
                    id={"template_name"}
                    name={"template_name"}
                    defaultValue={newCardData?.order_title}
                    onChange={(e) =>
                      setCardsPosition({
                        ...cardsPosition,
                        templet_name: e.value,
                      })
                    }
                  />
                </div>
                <input
                  type="file"
                  name="cardImage"
                  onChange={(e) => setSrcObj(e.target?.files[0])}
                />
                <div
                  className="cardImage"
                  style={{
                    backgroundImage: `url(${newSrc})`,
                    backgroundSize: '100% 100%',
                    margin: '5px',
                    width: `${cardsPosition?.cardImage?.width + 'px'}`,
                    height: `${cardsPosition?.cardImage?.height + 'px'}`,
                    border: active == "card" ? ".1px solid red" : ".1px solid",
                    display: 'inline-block',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div>
                    <p
                      style={{
                        letterSpacing: '8px',
                        fontWeight: 'bold',
                        padding: '3px 4px',
                        background: active == "code" ? "red" : "transparent",
                        fontSize: `${cardsPosition?.cardCode?.font + "px"}`,
                        width: "fit-content",
                        position: 'absolute',
                        margin: `${cardsPosition?.cardCode?.top + "px"} 0 0 ${
                          cardsPosition?.cardCode?.left + "px"
                        }`,
                      }}
                    >
                      12287592286296
                    </p>

                    <p
                      style={{
                        color: 'black',
                        fontSize: `${cardsPosition?.cardId?.font + "px"}`,
                        position: 'absolute',
                        background: active == "id" ? "red" : "transparent",
                        letterSpacing: '3px',
                        margin: `${cardsPosition?.cardId?.top + "px"} 0 0 ${
                          cardsPosition?.cardId?.left + "px"
                        }`,
                        // width: '157px',
                        textAlign: 'center',
                      }}
                    >
                      1701
                    </p>
                  </div>
                </div>
              </div>

              <span
                className="preview btn btn-success"
                onClick={() => {
                  localStorage.setItem(
                    "cardsToken",
                    btoa(JSON.stringify({ pos: cardsPosition, src: newSrc }))
                  );
                  window.open("/CardsPreview", "_blanck");
                }}
              >
                Preview
              </span>

              <button className="preview">Create Template</button>
            </form>
          </div>
        }
      />
    </div>
  );
};

export default Cards;
