import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import "../subjects/style.css";
import '../courseNotification/coursenotifications.css'
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { Loader } from "rsuite";
import { ChevDown } from "../../assets/svgIcons";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
import { courNotifications } from "./data";
import { Spinner } from "reactstrap";
const ChapterNotifications = () => {
  const location = useLocation();
  const [couNotifications,setCourNotifications]=useState([]);
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [AssignLoading, setAssignLoading] = useState(false);
  const [banId, setBanId] = useState(false);
  const [resetId, setResetId] = useState(false);
  const [data, setData] = useState(false);
  const [uData, setUData] = useState(false);
  const [stu_id, set_stu_id] = useState(false);
  const [showAddModal,setShowAddModal]=useState(false);
  const [addLoading,setAddLoading]=useState(false)
  const [newNot,setNewNot]=useState({
    not_head:'',
    not_type:'type1',
    send_to:'',
    message:'',
    image:'',
  })
  const [imgLoading,setImgLoading]=useState(false)
  const [img,setImg]=useState(null);

  const headers = [
    {
      label: "ID",
      dataIndex: "not_id",
      sort: true,
      search: true,
    },
    {
      label: "Image",
      dataIndex: "image",
      type:'children',
      children:({row,header})=>{
        return(
          <img style={{width:'100px'}} src={row.image} alt="" />
        )
      }
    },
    {
      label: "Date",
      dataIndex: "date",
      sort: true,
      search: true,
    },
    {
      label: "Message",
      dataIndex: "mess_text",
      sort: true,
      search: true,
    },
  ];
  const handleUpImage=()=>{
    const formData=new FormData();
    formData.append('image',img);
    setImgLoading(true);
    setImgLoading(false);
  }
  const getNotifications=()=>{
    setCourNotifications(courNotifications);
  }
  const handleAddNewNot=()=>{
    const data_send={
      ...newNot
    }
    setAddLoading(true)
    setAddLoading(false)
  }
  useEffect(()=>{
    getNotifications()
  },[])
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <div className="bur_addnot p-2 d-flex align-items-center justify-content-between">
              <BurdCrumbs title={"Chapter Notifications"} />
              <button onClick={()=>{
                setShowAddModal(true)
              }} className="btn btn-success">Add</button>
            </div>
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={courNotifications}
            />
          </div>
        }
      />

            <Modal
              open={showAddModal}
              toggle={setShowAddModal}
              headerTitle={"Add New Notification in This Chapter"}
              children={
                <div className="add_new_not">
                  <div className="row row-gap-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="not_img">Notification Image</label>
                      <input onChange={(e)=>{
                        setImg(e.target.files[0])
                      }} type="file" id="not_img" />
                    </div>
                    <div className="col-lg-12 col-md-12 text-center col-sm-12">
                      <button style={{cursor:imgLoading?'no-drop':'pointer'}} disabled={imgLoading} onClick={()=>{
                        handleUpImage()
                      }} className="btn btn-success">
                        {
                          imgLoading?<Spinner/>:'Upload'
                        }
                      </button>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="not_head">Notification Headline</label>
                      <input onChange={(e)=>{
                        setNewNot({...newNot,not_head:e.target.value})
                      }} type="text" placeholder="Notification Headline" id="not_head" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="not_typ">Select Type of Notification</label>
                      <select value={newNot.not_type} onChange={(e)=>{
                        setNewNot({...newNot,not_type:e.target.value})
                        }}
                        id="not_typ"
                      >
                        <option value="type1">Type One</option>
                        <option value="type2">Type Two</option>
                      </select>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="snd_not">Send Notification</label>
                      <select value={newNot.send_to} onChange={(e)=>{
                        setNewNot({...newNot,send_to:e.target.value})
                        }}  name="" id="snd_not">
                          <option value="android">Android Only</option>
                          <option value="android">IOS Only</option>
                          <option value="android">both</option>
                      </select>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <label htmlFor="not_mes">Notification Message</label>
                      <textarea onChange={(e)=>{
                        setNewNot({...newNot,message:e.target.value})
                        }}  name="" id="not_mes" cols="30" rows="10">
                      </textarea>
                    </div>
                    <div className="text-center">
                      <button disabled={addLoading} style={{cursor:addLoading?'no-drop':'pointer'}} onClick={()=>{
                        handleAddNewNot()
                      }} className="btn btn-success">
                        {
                          addLoading?<Spinner/>:'Add'
                        }
                      </button>
                    </div>
                  </div>
                </div>
              }
            />
    </div>
  );
};

export default ChapterNotifications;
