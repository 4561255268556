import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import './roles.css'
import { assignedRoles, rolesData } from "../DashPermissions/data";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
const Roles = () => {
  const navigate=useNavigate();
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [showAddModal,setShowAddModal]=useState(false);
  const [newRole,setNewRole]=useState({
    title:'',
    description:'',
  })
  const [addLoading,setAddLoading]=useState(false)
  const [roles,setRoles]=useState([]);
  const headers = [
    {
      label: "ID",
      dataIndex: "id",
      sort: true,
      search: true,
    },
    {
      label: "Role Name",
      dataIndex: "title",
      sort: true,
      search: true,
    },
    {
      label: "Description",
      dataIndex: "description",
      sort: true,
      search: true,
    },
    {
      label: "Action",
      dataIndex: "edit",
      type:'children',
      children:({row,header})=>{
        return (
          <div>
            <button onClick={()=>{
              navigate('/editroles')
            }} className="btn btn-success">Edit</button>
          </div>
        )
      }
    },
  ];
  const getRules=()=>{
    setRoles(rolesData)
    console.log(rolesData)
  }
  const handleAddRole=()=>{
    if(newRole.description==''||newRole.title==''){
      toast.warn('Enter All Data');
      return;
    }
    setAddLoading(true)
    const data_send={
      ...newRole
    }
    axios.post('',JSON.stringify(data_send))
    .then((res)=>{
      if(res.data.status=='success'){
        toast.success(res.data.message);
      }
      else if(res.data.message){
        toast.error(res.data.message)
      }
      else {
        toast.error('حدث خطأ ما')
      }
    }).finally(()=>{
      setAddLoading(false)
    })
  }
  useEffect(()=>{
    getRules()
  },[])
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <div className="add_div_bur d-flex align-items-center justify-content-between p-2">
              <BurdCrumbs title={"Roles"} />
              <button onClick={()=>{
                navigate("/dashperms")
                // setShowAddModal(true)
              }} className="btn btn-success">Add</button>
            </div>
            <div className="roletable">
              <TableLayout
                searchHeaderKet={searchHeaderKet}
                headers={headers}
                data={roles}
              />
            </div>
          </div>
        }
      />
        <Modal
          open={showAddModal}
          toggle={setShowAddModal}
          headerTitle={"Add New Role"}
          children={
            <div className="">
              <form onSubmit={(e)=>{
                e.preventDefault()
                handleAddRole()
              }}>
                <div className="mb-1">
                  <label htmlFor="title" className="mb-1 font-17">Role Name</label>
                  <input onChange={(e)=>{
                    setNewRole({...newRole,title:e.target.value})
                  }} type="text" id="title" placeholder="Role Name" className="form-control" />
                </div>
                <div className="my-2">
                  <label htmlFor="des" className="mb-1 font-17">Role Description</label>
                  <textarea onChange={(e)=>{
                    setNewRole({...newRole,description:e.target.value})
                  }} name="" className="form-control" id="" cols="30" rows="10"></textarea>
                </div>
                <div style={{textAlign:'center',marginTop:"10px"}}>
                  <button disabled={addLoading} style={{cursor:addLoading?'no-drop':'pointer'}} className="btn btn-success">
                  {addLoading?<Spinner/>:'Add'}
                  </button>
                </div>
              </form>
            </div>
          }
        />
    </div>
  );
};

export default Roles;
