import ticketsfil from '../assets/images/adjust-control-icon 1.png';
import orderbyfil from '../assets/images/Group.png';
import search from '../assets/images/search.png';
import sort1 from '../assets/images/sort1.png';
import sort2 from '../assets/images/sort2.png';
import sort3 from '../assets/images/sort3.png';
import sort4 from '../assets/images/sort4.png';

export const images={
  ticketsfil,
  orderbyfil,
  search,
  sort1,
  sort2,
  sort3,
  sort4
}
