export const courNotifications=[
  {
    not_id:84375453,
    date:'08-14-20224:00 PM',
    track:'Cairo University --> Law College --> Class 1 -->انتساب --> Subject 1',
    mess_text:'Lorem Ipsum..',
    image:require('../../assets/images/message.png'),
  },
  {
    not_id:84375453,
    date:'08-14-20224:00 PM',
    track:'Cairo University --> Law College --> Class 1 -->انتساب --> Subject 1',
    mess_text:'Lorem Ipsum..',
    image:require('../../assets/images/message.png'),
  },
  {
    not_id:84375453,
    date:'08-14-20224:00 PM',
    track:'Cairo University --> Law College --> Class 1 -->انتساب --> Subject 1',
    mess_text:'Lorem Ipsum..',
    image:require('../../assets/images/message.png'),
  },
  {
    not_id:84375453,
    date:'08-14-20224:00 PM',
    track:'Cairo University --> Law College --> Class 1 -->انتساب --> Subject 1',
    mess_text:'Lorem Ipsum..',
    image:require('../../assets/images/message.png'),
  },
  {
    not_id:84375453,
    date:'08-14-20224:00 PM',
    track:'Cairo University --> Law College --> Class 1 -->انتساب --> Subject 1',
    mess_text:'Lorem Ipsum..',
    image:require('../../assets/images/message.png'),
  },
  {
    not_id:84375453,
    date:'08-14-20224:00 PM',
    track:'Cairo University --> Law College --> Class 1 -->انتساب --> Subject 1',
    mess_text:'Lorem Ipsum..',
    image:require('../../assets/images/message.png'),
  },
  {
    not_id:84375453,
    date:'08-14-20224:00 PM',
    track:'Cairo University --> Law College --> Class 1 -->انتساب --> Subject 1',
    mess_text:'Lorem Ipsum..',
    image:require('../../assets/images/message.png'),
  },
]
