// export const logginData = {
//   email: "admin@admin.com",
//   passowrd: "1231",
//   name: "Elfeky Center-Admin",
// };

import axios from "axios";
import { BASE_URL } from "../constants";

let loggedIn = false;
let userData = false;
const storedAccount = localStorage.getItem("account");

if (storedAccount) {
  try {
    const parsedAccount = JSON.parse(atob(storedAccount));
    loggedIn = true;
    userData = parsedAccount;
  } catch (error) {
    localStorage.removeItem("account");
    loggedIn = false;
  }
}

export { loggedIn, userData };
