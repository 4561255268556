import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import '../Roles/roles.css'
import { assignedRoles, rolesData } from "../DashPermissions/data";
import { adminRolesData, allRoles } from "./data";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { dots } from "../../assets/svgIcons";
import './adminroles.css'
const AdminRoles = () => {
  const navigate=useNavigate();
  const [showChangeStatusModal,setShowChangeStatusModal]=useState(false)
  const [rowData,setRowData]=useState({});
  const [showRolesModal,setShowRolesModal]=useState(false)
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [showAddModal,setShowAddModal]=useState(false);
  const [showChangeRolesModal,setShowChangeRolesModal]=useState(false)
  const [newRole,setNewRole]=useState({
    title:'',
    description:'',
  })
  const [addLoading,setAddLoading]=useState(false)
  const [roles,setRoles]=useState([]);
  const [allRolesData,setAllRolesData]=useState([])
  const headers = [
    {
      label: "ID",
      dataIndex: "id",
      sort: true,
      search: true,
    },
    {
      label: "User Name",
      dataIndex: "user_name",
      sort: true,
      search: true,
    },
    {
      label: "Password",
      dataIndex: "password",
      sort: true,
      search: true,
    },
    {
      label: "Roles & Data Restrictions",
      dataIndex: "roldat",
      sort: true,
      search: true,
    },
    {
      label: "Action",
      dataIndex: "edit",
      type:'children',
      children:({row,header})=>{
        return (
          <div className="ac_parent">
            <span onClick={()=>{
              setRoles(roles.map((item)=>{
                if(item.id==row.id){
                  return {...item,showDots:!item.showDots}
                }
                else return {...item,showDots:false}
              }))
            }} style={{ cursor:'pointer' }}>{dots}</span>
            {
              row.showDots?(
                <div className="action_list">
                  <h6
                    onClick={()=>{
                      setShowChangeRolesModal(true)
                    }}
                  >Change Roles</h6>
                  <h6
                    onClick={()=>{
                      setShowRolesModal(true);
                      setRowData(row)
                    }}
                  >Roles Details</h6>
                </div>
              ):(
                null
              )
            }
          </div>
        )
      }
    },
  ];
  const getRules=()=>{
    setRoles(adminRolesData.map((item,index)=>{
      return {...item,showDots:false}
    }))
  }
  const getAllRoles=()=>{
    setAllRolesData(allRoles);
  }
  const handleAddRole=()=>{
    if(newRole.description==''||newRole.title==''){
      toast.warn('Enter All Data');
      return;
    }
    setAddLoading(true)
    const data_send={
      ...newRole
    }
    axios.post('',JSON.stringify(data_send))
    .then((res)=>{
      if(res.data.status=='success'){
        toast.success(res.data.message);
      }
      else if(res.data.message){
        toast.error(res.data.message)
      }
      else {
        toast.error('حدث خطأ ما')
      }
    }).finally(()=>{
      setAddLoading(false)
    })
  }
  const handleChangeStatus=(data)=>{
    let list=[...allRolesData];
    setAllRolesData(list.map((item,index)=>{
      return item.id==data.id?{...item,checked:!item.checked}:{...item};
    }))
    setShowChangeStatusModal(false)
  }
  const handleChangeRoles=()=>{
    const data_send={
      ...rowData
    }
    setShowChangeRolesModal(false);
    toast.success('success')
  }
  useEffect(()=>{
    getRules()
    getAllRoles()
  },[])
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <div className="add_div_bur d-flex align-items-center justify-content-between p-2">
              <BurdCrumbs title={"Admin"} />
            </div>
            <div className="roletable">
              <TableLayout
                searchHeaderKet={searchHeaderKet}
                headers={headers}
                data={roles}
              />
            </div>
          </div>
        }
      />
          <div className="changeroldiv">
          <Modal
            open={showChangeRolesModal}
            toggle={setShowChangeRolesModal}
            headerTitle={"Change Roles"}
            children={
              <div className="change_admin_roles">
                {
                  allRolesData.map((item,index)=>{
                    return(
                      <div className="admin_role">
                        <div className="top">
                          <h3>{item.title}</h3>
                          {
                            item.checked?(
                              <button onClick={()=>{
                                setShowChangeStatusModal(true);
                                setRowData(item);
                              }} className="success">unassign</button>
                            ):(
                              <button onClick={()=>{
                                setShowChangeStatusModal(true);
                                setRowData(item);
                              }} className="danger">assign</button>
                            )
                          }
                        </div>
                        <p>{item.description||<p>No Description</p>}</p>
                      </div>
                    )
                  })
                }
                <div className="changebtnparent mt-2 text-center">
                  <button onClick={()=>{
                    handleChangeRoles()
                  }} className="btn btn-success">change</button>
                </div>
              </div>
            }
          />
          </div>
        <Modal
          open={showRolesModal}
          toggle={setShowRolesModal}
          headerTitle={"Admin Roles"}
          children={
            <div className="admin_roles">
              <div className="row justify-content-between row-gap-1">
                {
                  rowData.roles&&rowData?.roles.map((item,index)=>{
                    return(
                      <div className="admin_role card col-lg-6 col-md-6 col-sm-12">
                        <h3>{item.title}</h3>
                        <p>{item.description||<h4>No Description</h4>}</p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }
        />
        <Modal
          open={showChangeStatusModal}
          toggle={setShowChangeStatusModal}
          headerTitle={"Admin Roles"}
          children={
            <div className="admin_roles">
              <div className="row justify-content-between row-gap-1">
                <p>
                  Are You Sure That You Want To {rowData.checked?`Unassign This Role (${rowData.title}) From This Admin`:`Assign This Role (${rowData.title}) To This Admin`}
                </p>
                <div className="btns-actions d-flex align-items-center gap-2">
                  <button onClick={()=>{
                    handleChangeStatus(rowData);
                  }} className="btn btn-success">Yes</button>
                  <button onClick={()=>{
                    setShowChangeStatusModal(false)
                  }} className="btn btn-danger">No</button>
                </div>
              </div>
            </div>
          }
        />
    </div>
  );
};

export default AdminRoles;
