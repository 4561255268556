import React, { useState, useRef, useEffect } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';

const MovableComponent = ({ id, position, setPosition }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const deltaX = event.clientX - startPosition.x;
    const deltaY = event.clientY - startPosition.y;
    const newX = position.x + deltaX;
    const newY = position.y + deltaY;

    setPosition({ x: newX, y: newY });
    setStartPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setIsDragging(true);
    setStartPosition({
      x: touch.clientX,
      y: touch.clientY,
    });
  };

  const handleTouchMove = (event) => {
    if (!isDragging) return;
    const touch = event.touches[0];
    const deltaX = touch.clientX - startPosition.x;
    const deltaY = touch.clientY - startPosition.y;
    const newX = position.x + deltaX;
    const newY = position.y + deltaY;

    setPosition({ x: newX, y: newY });
    setStartPosition({
      x: touch.clientX,
      y: touch.clientY,
    });
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: position.x,
        top: position.y,
        cursor: isDragging ? 'grabbing' : 'grab',
        zIndex: '1232323213',
        userSelect: "none",
      }}
      className="cards"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <h3>{id}</h3>
    </div>
  );
};

const CardContainer = () => {
  const containerRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [cards, setCards] = useState([
    {
      id: {
        id: 0,
        label: "Id",
        position: { x: 30, y: 30 },
      },
      code: {
        id: 0,
        label: "code",
        position: { x: 60, y: 60 },
      },
    },
  ]);
  const [newId, setNewId] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(null); // حالة لتخزين رابط الصورة

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setMaxWidth(container.clientWidth);
      setMaxHeight(container.clientHeight);
    }
  }, []);

  const handleCardMove = (id, newPosition, cardType) => {
    const updatedCards = cards.map((card) =>
      card.id.id === id || card.code.id === id
        ? {
            ...card,
            [cardType]: { ...card[cardType], position: newPosition },
          }
        : card
    );
    setCards(updatedCards);
  };

  const handleAddCard = (index) => {
    setCards([
      ...cards,
      {
        id: {
          id: cards?.length + 1,
          label: "Id",
          position: { x: 30, y: 30 },
        },
        code: {
          id: cards?.length + 1,
          label: "code",
          position: { x: 60, y: 60 },
        },
      },
    ]);
    setNewId('');
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageUrl = e.target.result;
      setBackgroundImage(imageUrl); // تحديث حالة رابط الصورة بالرابط الجديد
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: 'min(800px, 100%)',
        height: '600px',
        // border: '1px solid black',
      }}
    >
      <div style={{ height: "50px" }}>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        <button onClick={handleAddCard}>Add Card id and code</button>
      </div>
      {cards.map((card, index) => (
        <div style={{ display: "flex" }} key={index}>
          <MovableComponent
            id={card.id.label}
            position={card.id.position}
            setPosition={(newPosition) =>
              handleCardMove(card.id.id, newPosition, "id")
            }
          />
          <MovableComponent
            id={card.code.label}
            position={card.code.position}
            setPosition={(newPosition) =>
              handleCardMove(card.code.id, newPosition, "code")
            }
          />
        </div>
      ))}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          border: '1px solid black',
          backgroundImage: `url(${backgroundImage})`, // استخدام رابط الصورة كخلفية
          backgroundSize: 'cover',
          backgroundRepeatL: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
    </div>
  );
};

const ChainsCourses = () => {
  const [searchkey, setSearchHeaderKey] = useState();
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <>
            <CardContainer />
          </>
        }
      />
    </div>
  );
};

export default ChainsCourses;
