import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import BurdCrumbs from "../../components/burdCrumbs";
import "./setting.css";
import {
  Link,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { settingsSideNavData } from "../../data/side_nav_data";
import { BASE_URL } from "../../constants";
import axios from "axios";
import { Loader } from "rsuite";
import { userData } from "../../data/fake_login_data";
import { toast } from "react-toastify";

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState({});
  if (location?.pathname === "/settings") {
    navigate("TTS");
  }

  const [data, setData] = useState(null);
  const fetchData = () => {
    axios
      .post(BASE_URL + "settings/select_setting.php", {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      })
      .then((res) => {
        setData(res?.data?.message[0]);
      })
      .catch((err) => {
        setData({});
      });
  };

  const updateSettings = () => {
    setLoading({ type: "updateSettings", status: true });
    axios
      .post(BASE_URL + "settings/update_setting.php", {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
        ...data,
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success("Updated");
          setLoading({});
          fetchData();
        } else {
          toast.success(res?.data?.message);
          setLoading({});
        }
      })
      .catch((err) => {
        setData({});
        setLoading({});
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div id="home">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="settings">
              <aside className="settingsAside">
                {" "}
                {settingsSideNavData && settingsSideNavData?.length ? (
                  <>
                    <BurdCrumbs title={"Settings"} />
                    <ul className="sideNavList">
                      {settingsSideNavData.map((item, index) => {
                        return (
                          <li key={index}>
                            {!item?.subLinks && !item?.subLinks?.length ? (
                              <Link
                                className={
                                  location?.pathname?.includes(item?.path)
                                    ? "active"
                                    : ""
                                }
                                to={item?.path}
                              >
                                <span>{item?.icon}</span>
                                <span>{item?.label}</span>
                              </Link>
                            ) : (
                              <a style={{ cursor: "pointer" }}>
                                <span>{item?.icon}</span>
                                <span>{item?.label}</span>
                              </a>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : null}
              </aside>
              <div className="container_settings">
                <h1>TTS Settings</h1>
                {!data ? (
                  <Loader />
                ) : data && Object?.values(data)?.length ? (
                  <form
                    action="#"
                    onSubmit={(e) => {
                      e.preventDefault();
                      return loading?.type == "updateSettings" &&
                        loading?.status
                        ? null
                        : updateSettings();
                    }}
                  >
                    <div className="form">
                      <div className="type">
                        <span>Android & Hawawi</span>
                        <div className="inputField">
                          <label htmlFor="tts_interval_ios">TTS Interval</label>
                          <input
                            type="number"
                            value={data?.tts_interval}
                            onChange={(e) =>
                              setData({ ...data, tts_interval: e.target.value })
                            }
                          />
                        </div>
                        <div className="inputField">
                          <label htmlFor="tts_interval_ios">TTS Speed</label>
                          <input
                            type="number"
                            value={data?.tts_speed}
                            onChange={(e) =>
                              setData({ ...data, tts_speed: e.target.value })
                            }
                          />
                        </div>
                        <div className="inputField">
                          <label htmlFor="tts_interval_ios">
                            TTS Sound Value
                          </label>
                          <input
                            type="number"
                            value={data?.tts_sound_val}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tts_sound_val: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="type">
                        <span>IOS</span>
                        <div className="inputField">
                          <label htmlFor="tts_interval_ios">
                            TTS Interval IOS
                          </label>
                          <input
                            type="number"
                            value={data?.tts_interval_ios}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tts_interval_ios: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="inputField">
                          <label htmlFor="tts_interval_ios">
                            TTS Speed IOS
                          </label>
                          <input
                            type="number"
                            value={data?.tts_speed_ios}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tts_speed_ios: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="inputField">
                          <label htmlFor="tts_interval_ios">
                            TTS Sound Value IOS
                          </label>
                          <input
                            type="number"
                            value={data?.tts_sound_val_ios}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tts_sound_val_ios: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-primary">
                      {loading?.type == "updateSettings" && loading?.status ? (
                        <Loader />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </form>
                ) : (
                  <h3> No Settings</h3>
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Settings;
