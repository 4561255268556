import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import "../subjects/style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { Loader } from "rsuite";
import { ChevDown } from "../../assets/svgIcons";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
const Supscriptions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const univ_id = location?.state?.university_id;
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [AssignLoading, setAssignLoading] = useState(false);
  let [choosedDocs, setChoosedDocs] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [banId, setBanId] = useState(false);
  const [resetId, setResetId] = useState(false);
  const [data, setData] = useState(false);
  const [uData, setUData] = useState(false);
  const [stu_id, set_stu_id] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [opened, setOpened] = useState(null);
  const [allData, setAllData] = useState(null);
  const [selectedUniv, setSelectedUniv] = useSearchParams({ university_id: 1 });
  const [univGrades, setUnivGrades] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [Chains, setChains] = useState(null);
  const [selectedChain, setSelectedChain] = useState(null);
  const [Courses, setCourses] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [Chapters, setChapters] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);

  const getAllUnivs = async () => {
    try {
      setLoading(true);
      const univsAndGrades = await axios.post(
        BASE_URL + "general/select_universities_and_grades.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      setLoading(false);
      setAllData(univsAndGrades?.data?.message);
    } catch (err) {
      setLoading(false);
    }
  };
  const getChains = async () => {
    try {
      setLoading(true);

      const univsAndGradesChains = await axios.post(
        BASE_URL + "courses/chains/select_chain.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          grade_id: selectedUniv.get("grade_id"),
        }
      );
      if (univsAndGradesChains?.data?.message?.length) {
        setSelectedChain(univsAndGradesChains?.data?.message[0]?.chain_id);
        setChains(univsAndGradesChains?.data?.message);
      } else {
        setSelectedChain(null);
        setChains([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    } 
  };
  const getCourses = async () => {
    const univsAndGradesChainsCourses = await axios.post(
      BASE_URL + "courses/courses/select_course_chain.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
        chain_id: selectedChain,
      }
    );
    setCourses(univsAndGradesChainsCourses?.data?.message);
    if (univsAndGradesChainsCourses?.data?.message?.length)
      setSelectedCourse(
        univsAndGradesChainsCourses?.data?.message[0]?.course_id
      );
  };
  const getChapters = async () => {
    const univsAndGradesChainsCoursesChapters = await axios.post(
      BASE_URL + "courses/chapters/select_course_chapters.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
        course_id: selectedCourse,
      }
    );
    if (univsAndGradesChainsCoursesChapters?.data?.message?.length)
      univsAndGradesChainsCoursesChapters?.data?.message.unshift({
        chapter_title: "All",
        chapter_id: null,
      });
    setChapters(univsAndGradesChainsCoursesChapters?.data?.message);
  };
  useEffect(() => {
    getAllUnivs();
  }, []);
  useEffect(() => {
    if (selectedUniv.get("university_id")) {
      setUnivGrades(
        allData?.filter(
          (item) => item?.university_id == selectedUniv?.get("university_id")
        )[0]?.grades
      );
      console.log(
        allData?.filter(
          (item) => item?.university_id == selectedUniv?.get("university_id")
        )[0]?.grades
      );
    }
  }, [selectedUniv.get("university_id"), allData]);
  useEffect(() => {
    if (univGrades)
      setSelectedUniv(
        (prevState) => {
          prevState.set("grade_id", univGrades[0]?.grade_id);
          return prevState;
        },
        { replace: true, state: location?.state }
      );
  }, [univGrades, selectedUniv?.get("university_id")]);
  useEffect(() => {
    if (selectedUniv.get("grade_id") && univGrades) {
      getChains();
    }
  }, [selectedUniv.get("grade_id"), univGrades]);
  useEffect(() => {
    if (!Chains || !Chains?.length) {
      setCourses(null);
      setSelectedCourse(null);
    }
  }, [Chains]);
  useEffect(() => {
    if (!Courses || !Courses?.length) {
      setChapters(null);
      setSelectedChapter(null);
    }
  }, [Courses]);
  useEffect(() => {
    if (selectedChain) getCourses();
  }, [selectedChain]);
  useEffect(() => {
    if (selectedCourse) getChapters();
    setSelectedChapter(false);
  }, [selectedCourse]);

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <BurdCrumbs title={"Supscription"} />
            <div className="Supscription">
              {!allData ? (
                <Loader />
              ) : allData && allData?.length && Array?.isArray(allData) ? (
                <div>
                  <label>Choose university</label>
                  <select
                    value={selectedUniv.get("university_id")}
                    onChange={(e) => {
                      setSelectedUniv(
                        (prevState) => {
                          prevState.set("university_id", e.target.value);
                          return prevState;
                        },
                        { replace: true, state: location?.state }
                      );
                    }}
                  >
                    {allData?.map((item, index) => {
                      return (
                        <option value={item?.university_id} key={index}>
                          {item?.university_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}
              {univGrades &&
              univGrades?.length &&
              Array?.isArray(univGrades) ? (
                <div>
                  <label>Choose Grade</label>
                  <select
                    value={selectedUniv.get("grade_id")}
                    onChange={(e) => {
                      setSelectedUniv(
                        (prevState) => {
                          prevState.set("grade_id", e.target.value);
                          return prevState;
                        },
                        { replace: true, state: location?.state }
                      );
                    }}
                  >
                    {univGrades?.map((item, index) => {
                      return (
                        <option value={item?.grade_id} key={index}>
                          {item?.grade_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}
              {Chains && Chains?.length > 0 && Array?.isArray(Chains) ? (
                <div>
                  <label>Choose Chain</label>
                  <select
                    onChange={(e) => {
                      setSelectedChain(e.target.value);
                    }}
                  >
                    {Chains?.map((item, index) => {
                      return (
                        <option value={item?.chain_id} key={index}>
                          {item?.chain_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}
              {Courses && Courses?.length && Array?.isArray(Courses) ? (
                <div>
                  <label>Choose Course</label>
                  <select
                    onChange={(e) => {
                      setSelectedCourse(e.target.value);
                    }}
                  >
                    {Courses?.map((item, index) => {
                      return (
                        <option value={item?.course_id} key={index}>
                          {item?.course_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}
              {Chapters && Chapters?.length && Array?.isArray(Chapters) ? (
                <div>
                  <label>Choose Chapter</label>
                  <select
                    onChange={(e) => {
                      setSelectedChapter(e.target.value);
                    }}
                  >
                    {Chapters?.map((item, index) => {
                      return (
                        <option value={item?.chapter_id} key={index}>
                          {item?.chapter_title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}
            </div>
            {selectedCourse || selectedChapter ? (
              <div className="typeSub">
                Subscription By{" "}
                {!selectedChapter || selectedChapter == "All"
                  ? "Course"
                  : "Chapter"}
              </div>
            ) : null}
            {selectedCourse || selectedChapter ? (
              <div className="subcriptionsTypes">
                <div
                  onClick={() => {
                    navigate("supscriptionType", {
                      state: {
                        type: "card",
                        university_id: selectedUniv.get("university_id"),
                        grade_id: selectedUniv.get("grade_id"),
                        chain_id: selectedChain,
                        course_id: selectedCourse,
                        chapter_id: selectedChapter,
                        subType:
                          !selectedChapter || selectedChapter == "All"
                            ? "Course"
                            : "Cahpter",
                        chainDetails:
                          !selectedChapter || selectedChapter == "All"
                            ? Courses?.filter(
                                (item) => item?.course_id == selectedCourse
                              )
                            : Chapters?.filter(
                                (item) => item?.chapter_id == selectedChapter
                              ),
                      },
                    });
                  }}
                >
                  <span> card Subscription</span>
                  <img
                    src={require("../../assets/images/Animation - 1702467081534.gif")}
                  />
                </div>
                <div
                  onClick={() =>
                    !selectedChapter || selectedChapter == "All"
                      ? navigate("/chains/courses/subscriptions", {
                          state: {
                            chain_id: selectedChain,
                            university_id: selectedUniv.get("university_id"), // Extract the necessary value
                            grade_id: selectedUniv.get("grade_id"), // Assuming selectedCourse is a string or number
                            course_id: selectedCourse,
                            course_name: Courses?.filter(
                              (item) => item?.course_id == selectedCourse
                            )[0]?.course_name,
                          },
                        })
                      : navigate("/chains/courses/chapters/subscriptions", {
                          state: {
                            chain_id: selectedChain,
                            university_id: selectedUniv.get("university_id"), // Extract the necessary value
                            grade_id: selectedUniv.get("grade_id"), // Assuming selectedCourse is a string or number
                            chapter_id: selectedChapter,
                            chapter_name: Chapters?.filter(
                              (item) => item?.chapter_id == selectedChapter
                            )[0]?.chapter_title,
                          },
                        })
                  }
                >
                  <span> Student Subscription</span>
                  <img
                    src={require("../../assets/images/Animation - 1702467153507.gif")}
                  />
                </div>
              </div>
            ) : null}
          </div>
        }
      />
    </div>
  );
};

export default Supscriptions;
