import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Filters from "./Filters/Filters";
import "./courses.css";
import "./Filters/filters.css";
const Courses = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const univ_id = location?.state?.university_id;
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  return (
    <div id="home">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="top_subjects">
              <Filters pageName={'Courses'} />
              <div className="subjects">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => {
                  return (
                    <div className="subject">
                      <div className="sub_img">
                        <img
                          src={require("../../assets/images/image 13.png")}
                          alt=""
                        />
                      </div>
                      <div className="sub_details">
                        <h4>Subject Name</h4>
                        <p>
                          Cairo University Law College Class 1 انتظام University
                          Section
                        </p>
                        <div className="actions">
                          <button>Edit</button>
                          {/* <button>Delete</button> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Courses;
