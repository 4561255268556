import React from 'react';
import "./style.css";
import {
  EmailIcon,
  LoginIcon,
  PasswordHide,
  PasswordIcon,
  PasswordShow,
} from '../../assets/svgIcons';
import { Loader } from 'rsuite';
import { logginData } from '../../data/fake_login_data';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../constants';
import axios from 'axios';
const Login = () => {
  const [passwordShow, setpasswordShow] = React.useState(false);
  const [loginLoader, setLoginLoader] = React.useState(false);
  const LogIn = (e) => {
    setLoginLoader(true);
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    axios
      .post(BASE_URL + "auth/login_2.php", {
        user_email: email,
        password: password,
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          localStorage.setItem(
            "account",
            btoa(JSON.stringify(res?.data?.message))
          );
          window.location.href = "/";
          toast.success("Success");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => toast.error(err?.message))
      .finally(() => setLoginLoader(false));
  };
  return (
    <div className="auth">
      <div className="left_auth">
        <h2>Elfeky Center</h2>
      </div>
      <div className="login_form">
        <div className="login_text">
          <h3>
            Let's Login{"     "} {LoginIcon}
          </h3>
          <p>Hey, Enter your details to get sign in to your account</p>
        </div>
        <form action="#" onSubmit={!loginLoader ? LogIn : null}>
          <label htmlFor="email">Email</label>
          <div className="input_field">
            <div className="prefix_icon">{EmailIcon}</div>
            <input type="email" placeholder="Email" required name="email" />
          </div>

          <label htmlFor="Password">Password</label>
          <div className="input_field">
            <div className="prefix_icon">{PasswordIcon}</div>
            <input
              type={!passwordShow ? "password" : "text"}
              placeholder="Password"
              name="password"
              required
            />
            <div
              className="suffix_icon"
              onClick={() => setpasswordShow(!passwordShow)}
            >
              {passwordShow ? PasswordShow : PasswordHide}
            </div>
          </div>
          <button className="btn btn-success" disabled={loginLoader}>
            {LoginIcon} {!loginLoader ? <span> Sign In </span> : <Loader />}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
