import React, { useEffect, useState } from 'react';
import "./style.css";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../constants';
import { userData } from '../../data/fake_login_data';
import DefaultLayout from '../../layouts/defaultLayout';
import { Loader } from 'rsuite';
import { addition } from '../../assets/svgIcons';
const CardsTempletes = () => {
  const [getParams] = useSearchParams();
  const navigate = useNavigate();
  const [newSrc, setNewSrc] = useState(null);
  const [cards, setCards] = useState(null);
  const getTemplates = async () => {
    const univsAndGradesChainsCoursesChapters = await axios.post(
      BASE_URL +
        "subscriptions/cards/design_templets/select_design_templets.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );
    setCards(univsAndGradesChainsCoursesChapters?.data?.message);
  };
  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <DefaultLayout>
      <>
        <button className="btn btn-primary" onClick={() => navigate("/Cards")}>
          <span>{addition}</span>
          <span>Create Templates</span>
        </button>
        <div className="CardsPreview">
          {!cards ? (
            <Loader />
          ) : (
            cards?.length &&
            cards.map((card, index) => {
              return (
                <div
                  className="cardImage"
                  key={index}
                  style={{
                    backgroundImage: `url(${card?.image_url})`,
                    backgroundSize: '100% 100%',
                    margin: '5px',
                    width: `${card?.card_width + 'px'}`,
                    height: `${card?.card_height + 'px'}`,
                    display: 'inline-block',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div className="previewOverLay">
                    <span>{card?.templet_name}</span>
                    <button
                      className="previewButton btn btn-success"
                      onClick={() => {
                        localStorage.setItem(
                          "cardsToken",
                          btoa(
                            JSON.stringify({
                              pos: {
                                cardCode: {
                                  top: card?.code_top,
                                  left: card?.code_left,
                                  font: card?.code_fontsize,
                                },
                                cardId: {
                                  top: card?.id_top,
                                  left: card?.id_left,
                                  font: card?.id_fontsize,
                                },
                                cardImage: {
                                  width: card?.card_width,
                                  height: card?.card_height,
                                },
                              },
                              src: card?.image_url,
                            })
                          )
                        );
                        window.open("/CardsPreview", "_blanck");
                      }}
                    >
                      Preview
                    </button>
                  </div>

                  <div>
                    <p
                      style={{
                        letterSpacing: '8px',
                        fontWeight: 'bold',
                        padding: '3px 4px',
                        fontSize: `${card?.code_fontsize + "px"}`,
                        position: 'absolute',
                        margin: `${card?.code_top + "px"} 0 0 ${
                          card?.code_left + "px"
                        }`,
                      }}
                    >
                      12287592286296
                    </p>

                    <p
                      style={{
                        color: 'black',
                        fontSize: `${card?.id_fontsize + "px"}`,
                        position: 'absolute',
                        letterSpacing: '3px',
                        margin: `${card?.id_top + "px"} 0 0 ${
                          card?.id_left + "px"
                        }`,
                        // width: '157px',
                        width: "fit-content",
                        textAlign: 'center',
                      }}
                    >
                      1701
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </>
    </DefaultLayout>
  );
};

export default CardsTempletes;
