import React, { useEffect } from "react";
import { sideNavData } from "../../data/side_nav_data";
import { NavLink } from "react-router-dom";
import { Logo, Logout, Menu } from "../../assets/svgIcons";
import "./style.css";
import ProfileMenu from "../../components/profile-menu";

const Aside = ({ showAside, setShowAside }) => {
  useEffect(() => {
    if (
      document.querySelectorAll(".sideNavList li") &&
      document.querySelectorAll(".sideNavList li")?.length
    ) {
      document.querySelectorAll(".sideNavList li")?.forEach((item) => {
        item?.addEventListener("click", (e) => {
          if (item?.querySelector(".sideNavSubList")) {
            const sideNavSubList = item.querySelector(".sideNavSubList");
            if (sideNavSubList) {
              if (parseInt(sideNavSubList?.style?.height?.split("px")[0])) {
                sideNavSubList.style.height = 0 + "px";
              } else {
                sideNavSubList.style.height =
                  sideNavSubList?.scrollHeight + "px";
              }
            }
          }
        });
      });
    }
  }, [sideNavData]);
  return (
    <aside className={showAside ? "show" : ""}>
      <a className="side_nav_logo">
        <span
          className="menu"
          onClick={() => {
            if (setShowAside) {
              setShowAside(!showAside);
            }
          }} 
        >
          {Menu}  
        </span>
        <a href="/"> <img src={require("../../assets/images/LOGO EL-Feky.png")} alt="" width={"130"}/></a>
      </a>
      <ProfileMenu />
      {sideNavData && sideNavData?.length ? (
        <ul className="sideNavList">
          {sideNavData.map((item, index) => {
            if (item?.label)
              return (
                <li key={index}>
                  {!item?.subLinks && !item?.subLinks?.length ? (
                    <NavLink to={item?.path}>
                      <span>{item?.icon}</span>
                      <span>{item?.label}</span>
                    </NavLink>
                  ) : (
                    <a style={{ cursor: "pointer" }}>
                      <span>{item?.icon}</span>
                      <span>{item?.label}</span>
                    </a>
                  )}
                  <ul className="sideNavSubList">
                    {item?.subLinks && item?.subLinks?.length
                      ? item?.subLinks?.map((sub_item, index) => {
                          if (sub_item?.label)
                            return (
                              <li key={index}>
                                <NavLink to={sub_item?.path}>
                                  <span>{sub_item?.icon}</span>
                                  <span>{sub_item?.label}</span>
                                </NavLink>
                              </li>
                            );
                        })
                      : null}
                  </ul>
                </li>
              );
          })}
        </ul>
      ) : null}
      <div className="logout">
        <button
          className="btn btn-danger"
          onClick={() => {
            localStorage.removeItem("account");
            window.location.href = "/";
          }}
        >
          <span>{Logout}</span>
          <span>Logout</span>
        </button>
      </div>
    </aside>
  );
};

export default Aside;
