import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../components/modal";
import TableLayout from "../../components/table";
import DefaultLayout from "../../layouts/defaultLayout";
import "../subjects/style.css";
import "./style.css";

import ReactSelect from "react-select";
import makeAnimated from 'react-select/animated';
import { addition } from "../../assets/svgIcons";
import { Input } from "../../components/form-elements";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
import { exportToCSV } from "../../functions";
const CardsList = () => {
  const location = useLocation();
  const printForm = useRef();
  const [newSrc, setNewSrc] = useState(null);
  const [srcObj, setSrcObj] = useState(null);
  const [file, setFile] = useState(null);
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [AssignLoading, setAssignLoading] = useState(false);
  let [choosedDocs, setChoosedDocs] = useState([]);
  const [Courses, setCourses] = useState([]);
  const [Chapters, setChapters] = useState([]);
  const [newCardData, setNewCardData] = useState({
    admin_id: userData?.user_id,
    access_token: userData?.access_token,
    order_title: null,
    count: null,
    sub_type: "course", //'course', 'chapter'
    courses_ids: null,
  });
  // setNewCardData
  const [createCards, setCreateCards] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [banId, setBanId] = useState(false);
  const [templateDesign, setTemplateDesign] = useState(null);
  const [data, setData] = useState(false);
  const [uData, setUData] = useState(false);
  const [template, setTemplate] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [preview, setPreviewCards] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [card, setCard] = useState(null);

  const animatedComponents = makeAnimated();
  const headers = [
    {
      label: "ID",
      dataIndex: "card_id",
      sort: true,
      search: true,
    },
    {
      label: "card_code",
      dataIndex: "card_code",
      sort: true,
      search: true,
    },
    {
      label: "card_type",
      dataIndex: "card_type",
      sort: true,
      search: true,
    },
    {
      label: "Create Date",
      dataIndex: "create_date",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return <div>{row?.create_date}</div>;
      },
    },
    {
      label: "Used Date",
      dataIndex: "used_date",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return <div>{row?.used_date}</div>;
      },
    },
    {
      label: "created by admin name",
      dataIndex: "created_by_admin_name",
      search: true,
      sort: true,
    },
  ];

  const getCard = async () => {
    const univsAndGradesChainsCourses = await axios.post(
      BASE_URL + "subscriptions/cards/select_order_cards_list.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
        order_id: location?.state?.cardId?.cards_order_id,
      }
    );
    if (univsAndGradesChainsCourses?.data?.message?.length) {
      setData(univsAndGradesChainsCourses?.data?.message);
    }
  };
  useEffect(() => {
    getCard();
  }, []);

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <button
              style={{ margin: "20px" }}
              className="btn btn-primary exportBtn"
              onClick={() => {
                exportToCSV(
                  data?.map((item) => ({ "Card Code": item?.card_code })),
                  "Cards Data"
                );
              }}
            >
              Export
            </button>
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />
          </div>
        }
      />
    </div>
  );
};

export default CardsList;
