import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./dashlogs.css";
import "../subjects/style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { Loader, Toggle } from "rsuite";
import { ChevDown, backIcon } from "../../assets/svgIcons";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
import { dashLogsData, logsDetails } from "./data";
const DeviceLogs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const univ_id = location?.state?.university_id;
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [physical, setPhysical] = useState("all");
  const [installation, setIntallation] = useState("all");
  const [IsSecure, setIsSecure] = useState("all");
  const [SimCard, setSimCard] = useState("all");
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);

  const headers2 = [
    {
      label: "log_id",
      dataIndex: "log_id",
      sort: true,
      // search: true,
    },

    // {
    //   label: "Admin Name",
    //   dataIndex: "user_name",
    //   sort: true,
    //   search: true,
    // },

    {
      label: "student_id",
      dataIndex: "student_id",
      sort: true,
      search: true,
    },

    {
      label: "student_name",
      dataIndex: "student_name",
      sort: true,
      search: true,
    },
    {
      label: "student_phone",
      dataIndex: "student_phone",
      sort: true,
      search: true,
    },
    {
      label: "type",
      dataIndex: "type",
      sort: true,
      search: true,
    },
    {
      label: "skip_sim_card",
      dataIndex: "sim_card",
      type: "children",
      children: ({ row }) => {
        row.sim_card = row?.sim_card == 0 ? 0 : 1;
        return row?.sim_card == 0 ? "No" : "Yes";
      },
      sort: true,
      // search: true,
    },
    {
      label: "sim",
      dataIndex: "sim",
      sort: true,
      search: true,
    },
    {
      label: "known",
      dataIndex: "known",
      children: ({ row }) => {
        return row?.known;
      },
      // sort: true,
      // search: true,
    },
    {
      label: "isSecure",
      dataIndex: "isSecure",
      children: ({ row }) => {
        return row?.isSecure;
      },
      // sort: true,
      // search: true,
    },
    {
      label: "physical",
      dataIndex: "physical",
      children: ({ row }) => {
        return !row?.physical;
      },
      // sort: true,
      // search: true,
    },
    {
      label: "Date & Time",
      dataIndex: "date",
      sort: true,
      search: true,
    },
    {
      label: "Profile",
      type: "children",
      children: ({ row }) => {
        return (
          <a
            className="btn btn-primary"
            href={"/profile?stu_id=" + row?.student_id}
            style={{ textDecoration: "none" }}
            target="_blanck"
          >
            Show Profile
          </a>
        );
      },
      // sort: true,
      // search: true,
    },
  ];
  /*{
  "log_id": "34",
  "student_id": "7391",
  "physical": "1",
  "known": "1",
  "isSecure": "1",
  "sim": "--",
  "date": "2023-12-26 16:46:01",
};*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        BASE_URL + "logs/select_device_logs.php",
        {
          physical: physical,
          known: installation,
          isSecure: IsSecure,
          sim: SimCard,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setData(response?.data?.message);
      setLoading(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <div className="log_login">
              <BurdCrumbs title={"Dashboard Logs"} />
            </div>
            <div className="logsFilterBtns">
              <div className="filterBtn">
                <div className="filterToggle">
                  {" "}
                  <span>Special</span>
                  <Toggle
                    checked={physical == "all"}
                    onChange={(e) => setPhysical(e ? "all" : "Special")}
                  />
                  <span>All</span>
                </div>
                <div className="filterName">Physical</div>
                {physical != "all" ? (
                  <div className="radiosFilterbtns">
                    <div className="inputField">
                      <label htmlFor="PhysicalYes">Yes</label>
                      <input
                        type="radio"
                        name="Physical"
                        id="PhysicalYes"
                        value={"PhysicalYes"}
                        onChange={(e) => setPhysical("yes")}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="PhysicalNo">No</label>
                      <input
                        type="radio"
                        name="Physical"
                        id="PhysicalNo"
                        value={"PhysicalNo"}
                        onChange={(e) => setPhysical("no")}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="filterBtn">
                <div className="filterToggle">
                  {" "}
                  <span>Special</span>
                  <Toggle
                    checked={installation == "all"}
                    onChange={(e) => setIntallation(e ? "all" : "Special")}
                  />
                  <span>All</span>
                </div>
                <div className="filterName">Installation Source</div>
                {installation != "all" ? (
                  <div className="radiosFilterbtns">
                    <div className="inputField">
                      <label htmlFor="InstallationYes">Yes</label>
                      <input
                        type="radio"
                        name="Installation"
                        id="InstallationYes"
                        value={"InstallationYes"}
                        onChange={(e) => setIntallation("yes")}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="InstallationNo">No</label>
                      <input
                        type="radio"
                        name="Installation"
                        id="InstallationNo"
                        value={"InstallationNo"}
                        onChange={(e) => setIntallation("no")}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="filterBtn">
                <div className="filterToggle">
                  {" "}
                  <span>Special</span>
                  <Toggle
                    checked={IsSecure == "all"}
                    onChange={(e) => setIsSecure(e ? "all" : "Special")}
                  />
                  <span>All</span>
                </div>
                <div className="filterName">isSecure</div>
                {IsSecure != "all" ? (
                  <div className="radiosFilterbtns">
                    <div className="inputField">
                      <label htmlFor="isSecureYes">Yes</label>
                      <input
                        type="radio"
                        name="isSecure"
                        id="isSecureYes"
                        value={"isSecureYes"}
                        onChange={(e) => setIsSecure("yes")}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="isSecureNo">No</label>
                      <input
                        type="radio"
                        name="isSecure"
                        id="isSecureNo"
                        value={"isSecureNo"}
                        onChange={(e) => setIsSecure("no")}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="filterBtn">
                <div className="filterToggle">
                  {" "}
                  <span>Special</span>
                  <Toggle
                    checked={SimCard == "all"}
                    onChange={(e) => setSimCard(e ? "all" : "Special")}
                  />
                  <span>All</span>
                </div>
                <div className="filterName">Simcard</div>
                {SimCard != "all" ? (
                  <div className="radiosFilterbtns">
                    <div className="inputField">
                      <label htmlFor="SimcardYes">Have_Value</label>
                      <input
                        type="radio"
                        name="Simcard"
                        id="SimcardYes"
                        value={"SimcardYes"}
                        onChange={(e) => setSimCard("yes")}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="SimcardNo">Not_Have_Value</label>
                      <input
                        type="radio"
                        name="Simcard"
                        id="SimcardNo"
                        value={"SimcardNo"}
                        onChange={(e) => setSimCard("no")}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="filterBTN">
              <button
                className="btn btn-primary"
                style={{ margin: "auto" }}
                onClick={() => (loading ? null : fetchData())}
              >
                {loading ? <Loader /> : "Search"}
              </button>
            </div>
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers2}
              data={data}
            />
          </div>
        }
      />
    </div>
  );
};

export default DeviceLogs;
