export const rolesData=[
  {
    id:0,
    title:'Manage User Comments',
    checked:false,
    description:'Manage user comments on content and posts (for example: delete or edit the comment)',
  },
  {
    id:1,
    checked:false,
    title:'Mute Users',
    description:'Ban users from commenting on posts and content',
  },
  {
    id:2,
    checked:false,
    title:'Ban Users Device',
    description:'Ability to ban user device from his profile or his comment',
  },
  {
    id:3,
    checked:false,
    title:'Ban Users',
    description:'',
  },
  {
    id:4,
    checked:false,
    title:'Post and Create Votes',
    description:'post and create votes - add images or attachments in general or pin posts if he can post he can turn off the comments as well or control his post in general',
  },
  {
    id:5,
    checked:false,
    title:'Manage other People’s Posts',
    description:'Ability to manage other people’s posts (not his post)',
  },
  {
    id:6,
    checked:false,
    title:'Start Live',
    description:'Ability to start live (even if he can post he should not be able to start live if he doesn’t have permission)',
  },
  {
    id:7,
    checked:false,
    title:'Delete And Edit Posts',
    description:'',
  },
  {
    id:8,
    checked:false,
    title:'Change Post Privacy ',
    description:'',
  },
  {
    id:9,
    checked:false,
    title:'Unbannable',
    description:'Unbannable from the auto ban system when posting links or typing inappropriate words',
  },
  {
    id:10,
    checked:false,
    title:'Permission to Send Notifications',
    description:'Permission to send notifications to users while posting (notifications will only be sent to people who have access to that home page only)',
  },
]


export const assignedRoles=[
  {
    id:0,
    title:'Manage User Comments',
    checked:true,
    description:'Manage user comments on content and posts (for example: delete or edit the comment)',
  },
  {
    id:1,
    checked:true,
    title:'Mute Users',
    description:'Ban users from commenting on posts and content',
  },
]
