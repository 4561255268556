import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import { search } from '../../../assets/svgIcons';
import "./style.css";
import "./studentcourses.css";
import axios from 'axios';
import { BASE_URL } from '../../../constants';
import { userData } from '../../../data/fake_login_data';
import { ToastContainer, toast } from 'react-toastify';
import { Loader, Toggle } from 'rsuite';
import TableLayout from '../../../components/table';
import { createPath } from 'react-router-dom';
import Modal from '../../../components/modal';
const EnquiryStudents = () => {
  const [searchValue, setSearchValue] = useState(null);
  const [data, setData] = useState(null);
  const [type, setType] = useState(1);
  const [courses, setCourses] = useState(null);
  const [chapters, setChapters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resetId, setResetId] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        BASE_URL + "students/select_st_profile.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          student_id: !type
            ? searchValue?.startsWith("2")
              ? searchValue.slice(1, searchValue?.length)
              : searchValue
            : searchValue,
          type: type ? "id" : "phone",
        }
      );

      if (response?.data?.status == "success") {
        setData(response?.data?.message);
      } else toast.error("Student Not Found");
      setLoading(false);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    setChapters(
      data?.subscriptions?.filter((item) => item?.sub_type === 'chapter')
    );
    setCourses(
      data?.subscriptions?.filter((item) => item?.sub_type === 'course')
    );
  }, [data]);
  const resetSimCard = async (sim_card) => {
    try {
      const response = await axios.post(
        BASE_URL + "students/update_sim_card.php",
        {
          student_id: data?.student_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          sim_card: sim_card == 0 ? 1 : 0,
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const resetUser = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL + "students/update_serial.php",
        {
          student_id: data?.student_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setResetId(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const hiddenArr = [
    "student_token",
    "student_grade",
    "university_id",
    "student_photo",
    "earphone_permission",
    "all_permission",
    "subscriptions",
    "phone_jack",
  ];
  return (
    <div id="home" className="enquiry">
      <DefaultLayout
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span>Enquiries</span>
            </h4>
            <div className="inquiresType">
              <span>By Student Phone - </span>
              <Toggle checked={type} onChange={(e) => setType(e)} />
              <span> - By Student Id</span>
            </div>
            <div className="inquiries">
              <div className="searchCustomInput">
                <label htmlFor="search">{search}</label>
                <input
                  type="search"
                  id="search"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={() =>
                  !loading
                    ? searchValue && searchValue?.length
                      ? fetchData()
                      : toast.error("Enter Value")
                    : null
                }
              >
                {!loading ? "Search" : <Loader />}
              </button>
            </div>
            {data ? (
              <>
                <div className="student_info">
                  <img
                    src={
                      data?.student_photo && data?.student_photo?.length
                        ? data?.student_photo
                        : 'https://camp-coding.tech/dr_elmatary/profile_icons/1032037.png'
                    }
                    alt=""
                  />
                  <div className="text">
                    <span>
                      {data?.student_name} - {data?.uni_name} -{" "}
                      {data?.grade_name}
                    </span>
                    <span>{data?.student_email}</span>
                    <span>{data?.student_phone}</span>
                  </div>
                </div>
                <div className="cardData">
                  {data ? (
                    <table border="1">
                      <tbody>
                        {data
                          ? Object.keys(data)?.map((item) => {
                              if (
                                !hiddenArr.filter((h_item) => h_item == item)
                                  ?.length
                              )
                                return (
                                  <tr className="cardRow" key={item}>
                                    <td>{item}</td>
                                    <td>
                                      <div className="tdRow">
                                        {item == "sim_card" ? (
                                          <div className="reset">
                                            <Toggle
                                              checked={data?.sim_card != 0}
                                              onChange={() =>
                                                resetSimCard(data?.sim_card)
                                              }
                                            />
                                          </div>
                                        ) : null}
                                        {item == "reset_count" ? (
                                          <button
                                            className="btn btn-danger"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => {
                                              setResetId(data);
                                            }}
                                          >
                                            Reset
                                          </button>
                                        ) : null}
                                        <span>{data[item]}</span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                            })
                          : null}
                      </tbody>
                    </table>
                  ) : null}
                </div>
                <h2 className="student_courses_h2">Subscription Courses</h2>
                <div className="courses_student">
                  {courses && courses?.length ? (
                    courses?.map((item) => {
                      return (
                        <div
                          style={{
                            textAlign: 'start',
                            cursor: 'pointer',
                          }}
                        >
                          <img
                            src={
                              item?.course_details?.course_photo &&
                              item?.course_details?.course_photo?.length
                                ? item?.course_details?.course_photo
                                : 'https://elmatary.com/El_Matary_Platform/platform/admin/images/225557437_1693865054item_img.jpeg'
                            }
                            alt=""
                          />
                          <h5 style={{ marginBottom: '15px' }}>
                            <span
                              style={{ fontWeight: '900', fontSize: '15px' }}
                            >
                              Course :{" "}
                            </span>{" "}
                            <em
                              style={{
                                fontStyle: 'normal',
                                fontWeight: '300',
                                fontSize: '14.1px',
                              }}
                            >
                              {" "}
                              {item?.course_details?.course_name}{" "}
                            </em>
                          </h5>
                          <div>
                            <p style={{ margin: 0 }}>
                              <span
                                style={{
                                  fontWeight: '900',
                                  fontSize: '15px',
                                }}
                              >
                                Subscription date :{" "}
                              </span>{" "}
                              <em style={{ fontStyle: 'normal' }}>
                                {" "}
                                {item?.date}{" "}
                              </em>
                            </p>
                          </div>

                          <button className="btn btn-danger">
                            Cancel Subscription
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <h4 style={{ textAlign: 'center' }}>No Subscription</h4>
                  )}
                </div>
                <h2 className="student_courses_h2">Subscription Chapters</h2>
                <div className="courses_student">
                  {" "}
                  {chapters?.length && chapters ? (
                    chapters?.map((item) => {
                      return (
                        <div
                          style={{
                            textAlign: 'start',
                            cursor: 'pointer',
                          }}
                        >
                          <img
                            src={
                              item?.chapter_details?.chapter_photo &&
                              item?.chapter_details?.chapter_photo?.length
                                ? item?.chapter_details?.chapter_photo
                                : 'https://elmatary.com/El_Matary_Platform/platform/admin/images/225557437_1693865054item_img.jpeg'
                            }
                            alt=""
                          />
                          <h5 style={{ marginBottom: '15px' }}>
                            <span
                              style={{ fontWeight: '900', fontSize: '15px' }}
                            >
                              Chapters :{" "}
                            </span>{" "}
                            <em
                              style={{
                                fontStyle: 'normal',
                                fontWeight: '300',
                                fontSize: '14.1px',
                              }}
                            >
                              {" "}
                              {item?.chapter_details?.chapter_title}{" "}
                            </em>
                          </h5>
                          <div>
                            <p style={{ margin: 0 }}>
                              <span
                                style={{
                                  fontWeight: '900',
                                  fontSize: '15px',
                                }}
                              >
                                Subscription date :{" "}
                              </span>{" "}
                              <em style={{ fontStyle: 'normal' }}>
                                {" "}
                                {item?.date}{" "}
                              </em>
                            </p>
                          </div>

                          <button className="btn btn-danger">
                            Cancel Subscription
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <h4 style={{ textAlign: 'center' }}>No Subscription</h4>
                  )}
                </div>
              </>
            ) : null}
          </div>
        }
      />
      <Modal
        open={resetId}
        toggle={setResetId}
        headerTitle={"Reset Student"}
        children={
          <>
            <h5>
              {"Are You Sure To Reset User - " + resetId?.student_name + " ?"}
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  return !loading ? resetUser() : null;
                }}
              >
                {!loading ? "Yes" : <Loader />}
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setResetId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />
      <ToastContainer />
    </div>
  );
};

export default EnquiryStudents;
