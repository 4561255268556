import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import "../subjects/style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { Loader } from "rsuite";
import { ChevDown } from "../../assets/svgIcons";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
import { perms } from "../../data/side_nav_data";
const Banned = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const univ_id = location?.state?.university_id;
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [AssignLoading, setAssignLoading] = useState(false);
  let [choosedDocs, setChoosedDocs] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [banId, setBanId] = useState(false);
  const [resetId, setResetId] = useState(false);
  const [data, setData] = useState(false);
  const [uData, setUData] = useState(false);
  const [stu_id, set_stu_id] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [opened, setOpened] = useState(null);
  useEffect(() => {
    if (stu_id) fetchUData();
  }, [stu_id]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchUData = async () => {
    try {
      setAssignLoading(true);
      const response = await axios.post(
        BASE_URL + "students/select_student_material.php",
        {
          student_id: stu_id?.student_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setAssignLoading(false);
      setUData(response?.data?.message);
    } catch (err) {
      toast.error(err?.message);
      setAssignLoading(false);
    }
  };
  const headers = [
    {
      label: "ID",
      dataIndex: "student_id",
      sort: true,
      search: true,
    },
    perms?.includes("*10_2") || perms?.startsWith("10_2")
      ? {
          label: "Un Ban",
          type: "children",
          children: ({ headers, row }) => {
            return (
              <div>
                {AssignLoading ? (
                  <Loader />
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setResetId(row);
                    }}
                  >
                    Un Ban
                  </button>
                )}
              </div>
            );
          },
        }
      : {},
    {
      label: "Ban_reason",
      dataIndex: "Ban_reason",
      sort: true,
      search: true,
    },
    {
      label: "Banned_date",
      dataIndex: "Banned_date",
      sort: true,
      search: true,
    },

    {
      label: "Student Name",
      dataIndex: "student_name",
      search: true,
    },
    {
      label: "Student Phone",
      dataIndex: "student_phone",
      search: true,
      sort: true,
    },
  ];
  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + "ban/select_ban_user.php", {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });

      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };

  const resetUser = async () => {
    setLoading(true);

    try {
      const response = await axios.post(BASE_URL + "ban/unban_user.php", {
        student_id: resetId?.student_id,
        ban_id: resetId?.ban_id,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setResetId(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <BurdCrumbs title={"Banned Students"} />
            {(perms?.includes("*17_5") || perms?.startsWith("17_5")) &&
            data?.length ? (
              <button
                className="btn btn-primary exportBtn"
                onClick={() => {
                  exportToCSV(data, "Banned Student Data");
                }}
              >
                Export
              </button>
            ) : null}
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />
            <Modal
              open={showExport}
              toggle={setShowExport}
              headerTitle={"Export Data As Excel File"}
              children={
                <div className="flex-box">
                  {choosedDocs && choosedDocs?.length ? (
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        exportToCSV(choosedDocs, "cients Data");
                      }}
                    >
                      Export Only Selected
                    </button>
                  ) : null}

                  <button
                    className="btn btn-primary"
                    width={"fit-content"}
                    onClick={() => {
                      exportToCSV(data, "Student Data");
                    }}
                  >
                    Export all Data
                  </button>
                </div>
              }
            />

            <Modal
              open={resetId}
              toggle={setResetId}
              headerTitle={"Un Ban Student"}
              children={
                <>
                  <h5>
                    {"Are You Sure To Un Ban User - " +
                      resetId?.student_name +
                      " ?"}
                  </h5>
                  <div className="flex-box">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        return !loading ? resetUser() : null;
                      }}
                    >
                      {!loading ? "Yes" : <Loader />}
                    </button>

                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setResetId(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default Banned;
