import React, { useState } from 'react'
import {BiSearch} from 'react-icons/bi'
import { images } from '../../../constants/images'
import { useNavigate } from 'react-router-dom'

const Filters = ({pageName}) => {
  const navigate=useNavigate()
  const [showFilters,setShowFiltes]=useState(false);
  const [showOrdersFilters,setShowOrdersFilters]=useState(false)
  const [ticketFilters,setTicketFilters]=useState([
      {id:1,label:"University",checked:true},
      {id:2,label:"College",checked:false},
      {id:3,label:"Class",checked:false},
      {id:4,label:"Account Type",checked:false},
      {id:5,label:"Section",checked:false},
  ])
  const [orderFilters,setOrderFilters]=useState([
    {
      id:1,
      img:images.sort1,
      label:'A to Z',
      checked:false,
    },
    {
      id:2,
      img:images.sort2,
      label:'Z to A',
      checked:false,
    },
    {
      id:3,
      img:images.sort3,
      checked:false,
      label:'Ascending',
    },
    {
      id:4,
      img:images.sort4,
      checked:false,
      label:'Descending',
    },
  ])
  const handleChangeTicketFilter=(index)=>{
    setTicketFilters(ticketFilters.map((item,ind)=>{
      if(index==ind){
        return {...item,checked:true}
      }
      else {
        return {...item,checked:false}
      }
    }))
  }
  const handleChangeOrderFilters=(index)=>{
    setOrderFilters(orderFilters.map((item,ind)=>{
      if(index==ind){
        return {...item,checked:!item.checked}
      }
      else {
          return{...item,checked:false}
      }
    }))
  }
  return (
    <div className='filtersComp'>
      <h4>{pageName}</h4>
      <div className="filters">
        <div className="filtick">
          <img src={images.search} alt="" />
          {/* <BiSearch/> */}
          <input placeholder='Search for Tickets' type="text" />
          <img onClick={()=>{
            setShowFiltes(!showFilters)
          }} style={{ cursor:'pointer' }} src={images.ticketsfil} alt="" />
          {
            showFilters?(
              <div className="ticketsfilters">
                  {
                    ticketFilters.map((item,index)=>{
                      return(
                        <div className="ticket" key={index}>
                          {
                            <input checked={item.checked} onClick={()=>{
                              handleChangeTicketFilter(index)
                            }} type="checkbox" name="" id="" />
                          }
                          <p>{item.label}</p>
                        </div>
                      )
                    })
                  }
              </div>
            ):(null)
          }
        </div>
        <div className="filsubid">
        <img src={images.search} alt="" />
          <input placeholder='Search By Subjects' type="text" />
        </div>
        <div className="filorderby">
        <img src={images.search} alt="" />
          <input placeholder='Search Subject ID' type="text" />
        </div>
        <div className="filorderby">
          <input placeholder='Order By' type="text" />
          <img style={{ cursor:'pointer' }} onClick={()=>{
            setShowOrdersFilters(!showOrdersFilters)
          }} src={images.orderbyfil} alt="" />
          {
            showOrdersFilters?(
              <div className="orderfilters">
            {
              orderFilters.map((item,index)=>{
                return(
                  <div onClick={()=>{
                    handleChangeOrderFilters(index)
                  }} className={item.checked?'orderfilter active':'orderfilter'} key={index}>
                    <img src={item.img} alt="" />
                    <p>{item.label}</p>
                  </div>
                )
              })
            }
          </div>
            ):(null)
          }

        </div>
        <h5
          onClick={()=>{
            navigate("/createcourse")
          }}
        >Create Courses</h5>
      </div>
    </div>
  )
}

export default Filters
