// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";

const initialState = { data: {}, status: false };

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async ({ dispatch }) => {
    try {
      const res = await axios.post(BASE_URL + "admin_user/get_admin_data.php", {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });

      if (res?.data?.status === "success") {
        res.data.message.user_id = res.data.message?.admin_user_id;
        res.data.message.access_token = res.data.message?.token_value;
        localStorage.setItem(
          "account",
          btoa(JSON.stringify(res?.data?.message))
        );
        userData?.getAcc();
        dispatch(getPermissions(userData?.permission));
      } else {
        if (localStorage.getItem("account")) {
          localStorage.removeItem("account");
          window?.location?.reload();
        }
      }
    } catch (err) {
      console.error(err);
      localStorage.removeItem("account");
      window?.location?.reload();
    }
  }
);

const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    getData: (state, action) => {},
    getPermissions: (state, action) => {
      try {
        state.data = CryptoJS.AES.decrypt(
          decodeURIComponent(action.payload),
          "*&&^##/TS/Dashboard/ADMINS/##^&&*"
        ).toString(CryptoJS.enc.Utf8);
        state.status = true;
      } catch (error) {
        localStorage.removeItem("account");
        window?.location?.reload();
        state.status = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      try {
        state.data = CryptoJS.AES.decrypt(
          decodeURIComponent(action.payload),
          "*&&^##/TS/Dashboard/ADMINS/##^&&*"
        ).toString(CryptoJS.enc.Utf8);
        state.status = true;
      } catch (error) {
        localStorage.removeItem("account");
        window?.location?.reload();
        state.status = false;
      }
    });
  },
});

export const { getPermissions } = userSlice.actions;
export default userSlice.reducer;
