import React, { useState } from "react";
import Modal from "../../components/modal";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { toast } from "react-toastify";
import { userData } from "../../data/fake_login_data";

function Delete({ data, setData, fetchData }) {
  const [loading, setLoading] = useState(false);
  const deleteFunction = async (e) => {
    setLoading(true);
    e.preventDefault();
    await axios
      .post(BASE_URL + "deleteFunction.php", {
        ...data,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      })
      .then(async (response) => {
        toast.info(response?.data?.message);
        setData(null);
        await fetchData();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Modal
      headerTitle={"Delete"}
      open={data}
      toggle={() => setData(null)}
      children={
        <form
          action="#"
          onSubmit={!loading ? deleteFunction : (e) => e.preventDefault()}
        >
          <h1>Are You Sure...?</h1>

          <button className="btn btn-danger">{"OK"}</button>
        </form>
      }
    />
  );
}

export default Delete;
