import React, { useEffect, useState } from 'react';
import "./style.css";
import { useSearchParams } from 'react-router-dom';
const CardsPreviewPrint = () => {
  const [getParams] = useSearchParams();
  const [newSrc, setNewSrc] = useState(null);
  const [cards, setCards] = useState([]);
  const [cardsPosition, setCardsPosition] = useState({});
  useEffect(() => {
    if (getParams) {
      setCardsPosition(
        JSON.parse(atob(localStorage.getItem("cardsToken")))?.pos
      );
      setNewSrc(JSON.parse(atob(localStorage.getItem("cardsToken")))?.src);
      setCards(JSON.parse(atob(localStorage.getItem("cardsToken")))?.cards);
    }
  }, [getParams]);
  useEffect(() => {
    console.log(cardsPosition);
  }, [cardsPosition]);
  return (
    <div className="CardsPreview">
      {cards?.map((card) => {
        return (
          <div
            className="cardImage"
            style={{
              backgroundImage: `url(${newSrc})`,
              backgroundSize: '100% 100%',
              margin: '5px',
              width: `${cardsPosition?.cardImage?.width + 'px'}`,
              height: `${cardsPosition?.cardImage?.height + 'px'}`,
              display: 'inline-block',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div>
              <p
                style={{
                  letterSpacing: '8px',
                  fontWeight: 'bold',
                  padding: '3px 4px',
                  fontSize: `${cardsPosition?.cardCode?.font + "px"}`,
                  position: 'absolute',
                  margin: `${cardsPosition?.cardCode?.top + "px"} 0 0 ${
                    cardsPosition?.cardCode?.left + "px"
                  }`,
                }}
              >
                {card?.card_code}
              </p>

              <p
                style={{
                  color: 'black',
                  fontSize: `${cardsPosition?.cardId?.font + "px"}`,
                  position: 'absolute',
                  letterSpacing: '3px',
                  margin: `${cardsPosition?.cardId?.top + "px"} 0 0 ${
                    cardsPosition?.cardId?.left + "px"
                  }`,
                  // width: '157px',
                  width: "fit-content",
                  textAlign: 'center',
                }}
              >
                {card?.card_id}
              </p>
            </div>
          </div>
        );
      })}{" "}
    </div>
  );
};

export default CardsPreviewPrint;
