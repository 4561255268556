import React from 'react';
import './style.css';
import DefaultLayout from '../../layouts/defaultLayout';

const NotFound = () => {
  return (
    <div id="home">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="not-found">
              <h1>404</h1>
              <p>Page not found.</p>
              <img src={require("./notfound.png")} alt="404" />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default NotFound;
