import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import TableLayout from "../../components/table";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants";
import { Input } from "../../components/form-elements";
import { Loader } from "rsuite";
import Modal from "../../components/modal";
import { addition } from "../../assets/svgIcons";
import { userData } from "../../data/fake_login_data";
import { perms } from "../../data/side_nav_data";
import { exportToCSV } from "../../functions";
const Topics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [topicName, setTopicName] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [topicLogo, setTopicLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hiddenId, setHiddenId] = useState(false);
  const [editId, setEditId] = useState(false);
  const [opened, setOpened] = useState(null);
  const headers = [
    {
      label: "ID",
      dataIndex: "topic_id",
      search: true,
      sort: true,
    },

    {
      label: "Show Courses",
      type: "children",
      children: ({ headers, row }) => {
        return (
          <div>
            <button
              className="btn btn-primary"
              onClick={() =>
                navigate("courses", {
                  state: {
                    topic_id: row?.topic_id,
                    topic_name: row?.topic_name,
                    university_id: 1,
                    grade_id: 1,
                  },
                })
              }
            >
              Show
            </button>
          </div>
        );
      },
    },
    {
      label: "topic_name",
      dataIndex: "topic_name",
      sort: true,
    },
    {
      label: "Current status",
      type: "children",
      children: ({ row }) => {
        if (row.hidden == "yes") {
          return <span className="text-success textCurrent">InActive</span>;
        } else {
          return <span className="text-danger textCurrent">Active</span>;
        }
      },
    },
    {
      label: "Actions",
      type: "children",
      children: ({ headers, row, index, lastIndex }) => {
        return (
          <div className="menu_Open_close">
            <div
              className="open_close"
              style={{ cursor: "pointer", color: "black" }}
              onClick={() =>
                setOpened(opened == row?.topic_id ? null : row?.topic_id)
              }
            >
              <img
                src="https://res.cloudinary.com/duovxefh6/image/upload/v1701489400/menu_hrpzcb.png"
                alt=""
                style={{ width: "20px", height: "20px" }}
              />
            </div>
            {row?.topic_id == opened ? (
              <div
                className={
                  index == lastIndex - 1 ||
                  index == lastIndex - 2 ||
                  index == lastIndex - 3
                    ? "actions-views row-actions-view"
                    : "actions-views column-actions-view"
                }
              >
                {/* <button
                  className="btn btn-danger"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setDeleteId(row);
                  }}
                >
                  Delete
                </button> */}
                <button
                  className="btn btn-primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setEditId(row);
                  }}
                >
                  Edit
                </button>
                {/* setEditId */}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      label: "Change status",
      type: "children",
      children: ({ row }) => {
        if (row.hidden !== "yes") {
          return (
            <span
              className="btn btn-success"
              style={{ color: "black" }}
              onClick={() =>
                setHiddenId({
                  topic_id: row?.topic_id,
                  topic_name: row?.topic_name,
                  status: "yes",
                })
              }
            >
              Active
            </span>
          );
        } else {
          return (
            <span
              className="btn btn-danger"
              style={{ color: "black" }}
              onClick={() =>
                setHiddenId({
                  topic_id: row?.topic_id,
                  topic_name: row?.topic_name,
                  status: "no",
                })
              }
            >
              Inactive
            </span>
          );
        }
      },
    },
  ];
  window.addEventListener("click", (e) => {
    if (
      opened &&
      !e.target?.classList?.contains("actions-views") &&
      !e.target?.parentNode?.classList?.contains("open_close")
    ) {
      setOpened(null);
    }
  });
  const addTopic = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    try {
      const response = await axios.post(
        BASE_URL + "courses/topics/insert_topic.php",
        {
          topic_name: topicName?.value,
          university_id: 1,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          orderNumber: orderNumber?.value,
          grade_id: 1,
        }
      );

      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setShowAdd(false);
        form.reset();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const setArrangeNumber = async (material_id, material_number) => {
    setLoader(true);
    await axios
      .post(BASE_URL + "content/update_video_num.php", {
        topic_id: material_id,
        number: material_number,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      })
      .then(async (response) => {
        toast.info(response?.data?.message);
        await fetchData();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "courses/topics/select_topic.php",
        {
          university_id: 1,
          grade_id: 1,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };

  const hideUniv = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL + "courses/topics/show_hide_topic.php",
        {
          grade_id: 1,
          topic_id: hiddenId?.topic_id,
          hidden: hiddenId?.status,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          university_id: 1,
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setHiddenId(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const editTopic = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    try {
      const response = await axios.post(
        BASE_URL + "courses/topics/update_topic.php",
        {
          topic_id: editId?.topic_id,
          topic_name: topicName ? topicName : editId?.topic_name,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      if (response.data?.status == "success") {
        fetchData();
        setEditId(false);
        setTopicLogo(null);
        setTopicName(null);
        form.reset();
        setLoading(false);
        toast.success(response.data?.message);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  if (!location?.state?.university_id) {
    navigate(-1);
  }

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span>Topics For Grade ({location.state?.grade_name})</span>
              <button
                className="btn btn-primary"
                onClick={() => setShowAdd(true)}
              >
                <span>{addition}</span>
                <span>Add Topic</span>
              </button>
            </h4>
            {(perms?.includes("*17_4") || perms?.startsWith("17_4")) &&
            data?.length ? (
              <button
                className="btn btn-primary exportBtn"
                onClick={() => {
                  exportToCSV(
                    data,
                    location?.state?.chapter_name + " - Topics Data"
                  );
                }}
              >
                Export
              </button>
            ) : null}
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />
          </div>
        }
      />
      <Modal
        headerTitle={"Add Topic"}
        open={showAdd}
        toggle={setShowAdd}
        children={
          <form
            action="#"
            onSubmit={!loading ? addTopic : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="topic_name">Topic Name</label>
              <Input
                type={"text"}
                id={"topic_name"}
                name={"topic_name"}
                onChange={setTopicName}
              />
            </div>
            <div className="inputField">
              <label htmlFor="topic_number">Topic Number</label>
              <Input
                type={"text"}
                id={"topic_number"}
                name={"topic_number"}
                onChange={setOrderNumber}
              />
            </div>

            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
      <Modal
        open={hiddenId}
        toggle={setHiddenId}
        headerTitle={"Show / Hide Topic"}
        children={
          <>
            <h5>
              Are You Sure To{" "}
              {hiddenId?.status == "true" ? "Active - " : "Inactive - "}
              {hiddenId?.topic_name} ?
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  return !loading ? hideUniv() : null;
                }}
              >
                {!loading ? "Yes" : <Loader />}
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setHiddenId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />
      <Modal
        headerTitle={"Edit Topic"}
        open={editId}
        toggle={setEditId}
        children={
          <form
            action="#"
            onSubmit={!loading ? editTopic : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="topic_name">Topic Name</label>
              <Input
                type={"text"}
                id={"topic_name"}
                name={"topic_name"}
                defaultValue={editId?.topic_name}
                onChange={(e) => setTopicName(e.value)}
              />
            </div>

            <button className="btn btn-success">
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        }
      />
    </div>
  );
};

export default Topics;
