export const permissions = [
  {
    id: 1,
    label: 'subjects_page',
    values: [
      { id: 1, value: 'view courses' },
      { id: 2, value: 'add' },
      { id: 3, value: 'edit' },
      { id: 4, value: 'show_hide' },
    ],
  },
  {
    id: 4,
    label: 'courses_page',
    values: [
      { id: 1, value: 'view chapters' },
      { id: 2, value: 'add' },
      { id: 3, value: 'edit' },
      { id: 4, value: 'show_hide' },
      { id: 5, value: 'Auto Subscription' },
      { id: 6, value: 'Subscription' },
      { id: 7, value: 'Quizzes' },
      { id: 8, value: 'Notification' },
    ],
  },
  {
    id: 5,
    label: 'chapters_page',
    values: [
      { id: 1, value: 'view content' },
      { id: 2, value: 'add' },
      { id: 3, value: 'edit' },
      { id: 4, value: 'show_hide' },
      { id: 5, value: 'Auto Subscription' },
      { id: 6, value: 'Subscription' },
      { id: 7, value: 'Quizzes' },
      { id: 8, value: 'Notification' },
    ],
  },
  {
    id: 6,
    label: 'content_page',
    values: [
      { id: 2, value: 'add' },
      { id: 3, value: 'edit' },
      { id: 4, value: 'show_hide' },
      { id: 5, value: 'duplicate' },
      { id: 6, value: 'sort' },
    ],
  },
  {
    id: 7,
    label: 'students',
    values: [
      { id: 1, value: 'view' },
      { id: 2, value: 'ban' },
      { id: 3, value: 'reset serial' },
      { id: 4, value: 'assgin subjects' },
      { id: 5, value: 'sim card reset' },
      { id: 6, value: 'profile with proccesses' },
    ],
  },
  // {
  //   id: 8,
  //   label: 'profile_page',
  //   values: [{ id: 1, value: 'show' }],
  // },
  {
    id: 9,
    label: 'Supscription_page',
    values: [{ id: 1, value: 'show' }],
  },
  {
    id: 10,
    label: 'Banned Students Page',
    values: [
      { id: 1, value: 'show' },
      { id: 2, value: 'un ban' },
    ],
  },
  {
    id: 11,
    label: 'Logs',
    values: [
      { id: 1, value: 'Show Dashboard Logs' },
      { id: 2, value: 'Show App Logs' },
    ],
  },
  {
    id: 12,
    label: 'Posts Page',
    values: [
      { id: 1, value: 'view posts' },
      { id: 2, value: 'add' },
      { id: 3, value: 'edit' },
      { id: 4, value: 'edit grades' },
      { id: 5, value: 'show_hide' },
    ],
  },
  {
    id: 13,
    label: 'Reasons Page',
    values: [
      { id: 1, value: 'show' },
      { id: 2, value: 'add' },
      { id: 3, value: 'edit' },
      { id: 4, value: 'delete' },
      { id: 5, value: 'change status' },
    ],
  },
  {
    id: 14,
    label: 'Inquires Page',
    values: [
      { id: 2, value: 'cards' },
      { id: 3, value: 'students' },
    ],
  },
  {
    id: 15,
    label: 'Settings',
    values: [
      { id: 1, value: 'show' },
      { id: 2, value: 'tts' },
      { id: 3, value: 'dashboard' },
      { id: 4, value: 'app' },
    ],
  },
  {
    id: 16,
    label: 'admins',
    values: [
      { id: 1, value: "view" },
      { id: 2, value: 'add' },
      { id: 3, value: 'disable' },
      { id: 4, value: 'edit' },
    ],
  },
  {
    id: 17,
    label: 'Export Excel',
    values: [
      { id: 1, value: "University" },
      { id: 2, value: 'Grades' },
      { id: 3, value: 'Students' },
      { id: 4, value: 'Materials' },
      { id: 5, value: "Banned Students" },
      { id: 7, value: "Reasons" },
      { id: 8, value: "Posts" },
      { id: 9, value: "Admins" },
      { id: 10, value: "Subscriptions" },
      { id: 11, value: "Phone List" },
      { id: 12, value: "Dashboard Logs" },
    ],
  },
];

export const transformedPermissions = permissions.reduce((acc, permission) => {
  acc[permission.id] = permission.values.map((item) => item.id);
  return acc;
}, {});

function convertStringToObject(str) {
  const subObject = {};
  if (str && str.length) {
    const pairs = str.split('**');
    pairs.forEach((pair) => {
      const [categoryId, valueId] = pair.split('_');

      if (!subObject[categoryId]) {
        subObject[categoryId] = [];
      }

      subObject[categoryId].push(parseInt(valueId, 10));
    });
  }
  return subObject;
}
// ===============================================================

export const numberedObject = (xxxString) => convertStringToObject(xxxString);
