import React, { useState } from "react";
import Aside from "../sidenav";
import Header from "../header";
import "./style.css";

const DefaultLayout = ({ children, setSearchHeaderKey }) => {
  const [showAside, setShowAside] = React.useState(false);

  return (
    <div className="site_container">
      <Aside showAside={showAside} setShowAside={setShowAside} />
      <div className="content_container">
        <Header
          setShowAside={setShowAside}
          showAside={showAside}
          setSearchHeaderKey={setSearchHeaderKey}
        />
        <div className="childs">{children}</div>
      </div>
    </div>
  );
};

export default DefaultLayout;
