import CryptoJS from "crypto-js";
import {
  Admins,
  Info,
  PhoneList,
  Post,
  Reasons,
  Settings,
  StudentIcon,
  SubjIcons,
  UnivIcon,
  UserIcon,
  about,
  alerts,
  dashboardIcon,
  logs,
  permission,
  tmscons,
} from "../assets/svgIcons";
import { userData } from "./fake_login_data";
const x = 90;

let decodedData = decodeURIComponent(userData?.permission);

// "U2FsdGVkX1+/oFe9XiXKB77AwED2oZgzxTaKZawD7ZRVscFkWlkadtrL1ksks7NV"
// "U2FsdGVkX1+/oFe9XiXKB77AwED2oZgzxTaKZawD7ZRVscFkWlkadtrL1ksks7NV"
// "U2FsdGVkX1+ThZKOHiP+CGeLrsCGOpCprH8oFK/S6VteH15OxLq9s5nvvWSF5Zlgxj9lgk8TNnCsLbOoUiQbKQ=="

let key = "*&&^##/TS/Dashboard/ADMINS/##^&&*";
export let perms = CryptoJS.AES.decrypt(decodedData, key).toString(
  CryptoJS.enc.Utf8
);
export const sideNavData = [
  // {
  //   label: "Dashboard",
  //   icon: dashboardIcon,
  //   path: "/",
  // },
  perms?.includes("*1_1") || perms?.startsWith("1_1")
    ? {
        label: "Subjects",
        icon: UnivIcon,
        path: "#",
        subLinks: [
          perms?.includes("*1_1") || perms?.startsWith("1_1")
            ? {
                label: "Subjects",
                path: "/chains",
              }
            : {},
        ],
      }
    : {},

  // {
  //   label: "Phone List",
  //   icon: PhoneList,
  //   path: '/phonelist',
  // },
  //
  perms?.includes("*7_1") ||
  perms?.startsWith("7_1") ||
  perms?.includes("*9_1") ||
  perms?.startsWith("9_1") ||
  perms?.includes("*10_1") ||
  perms?.startsWith("10_1")
    ? {
        label: "Students Module",
        icon: StudentIcon,
        path: "#",
        subLinks: [
          perms?.includes("*7_1") || perms?.startsWith("7_1")
            ? {
                label: "Students",
                path: "/Students",
              }
            : [],
          perms?.includes("*9_1") || perms?.startsWith("9_1")
            ? {
                label: "Supscription",
                path: "/Supscription",
              }
            : {},
          // perms?.includes("*10_1") || perms?.startsWith("10_1")
          //   ? {
          //       label: "Banned Students",
          //       path: "/Students/banned",
          //     }
          //   : {},
        ],
      }
    : {},
  perms?.includes("*11_2") ||
  perms?.startsWith("11_2") ||
  perms?.includes("*11_1") ||
  perms?.startsWith("11_1")
    ? {
        label: "Logs",
        icon: logs,
        path: "#",
        subLinks: [
          perms?.includes("*11_1") || perms?.startsWith("11_1")
            ? {
                label: "Dashboard Logs",
                path: "/logs",
              }
            : {},
          perms?.includes("*11_2") || perms?.startsWith("11_2")
            ? {
                label: "Device Logs",
                path: "/DeviceLogs",
              }
            : {},
          // {
          //   label: "App Logs",
          //   path: "/logs/applogs",
          // },
        ],
      }
    : {},
  // {
  //   label: "Permissions",
  //   icon: permission,
  //   path: "#",
  //   subLinks: [
  //     {
  //       label: "Dashboard permissions",
  //       path: "/dashperms",
  //     },

  //     {
  //       label: "App permisions",
  //       path: "/appperms",
  //     },

  //   ],
  // },
  // {
  //   label: "Admin",
  //   icon: UserIcon,
  //   path: "/adminroles",
  // },
  // {
  //   label: "Terms And Conditions",
  //   icon: tmscons,
  //   path: "/tmscons",
  // },
  // {
  //   label: "About",
  //   icon: about,
  //   path: "/about",
  // },
  // {
  //   label: "Alerts",
  //   icon: alerts,
  //   path: "/alerts",
  // },
  perms?.includes("*12_1") || perms?.startsWith("12_1")
    ? {
        label: "Posts",
        icon: Post,
        path: "/mainposts",
      }
    : {},{
        label: "Cards Orders",
        icon: Post,
        path: "/CardsOrders",
      },
  // perms?.includes("*13_1") || perms?.startsWith("13_1")
  //   ? {
  //       label: "ٌReasons",
  //       icon: Reasons,
  //       path: "/reasons",
  //     }
  //   : {},
  perms?.includes("*14_3") ||
  perms?.startsWith("14_3") ||
  perms?.includes("*14_2") ||
  perms?.startsWith("14_2")
    ? {
        label: "Inquires",
        icon: Info,
        subLinks: [
          perms?.includes("*14_2") || perms?.startsWith("14_2")
            ? {
                label: "Cards",
                path: "/inquires",
              }
            : {},
          perms?.includes("*14_3") || perms?.startsWith("14_3")
            ? {
                label: "Students",
                path: "/EnquiryStudents",
              }
            : {},

          // {
          //   label: "App Logs",
          //   path: "/logs/applogs",
          // },
        ],
      }
    : {},
  // perms?.includes("*15_1") || perms?.startsWith("15_1")
  //   ? {
  //       label: "Settings",
  //       icon: Settings,
  //       path: "/settings/TTS",
  //     }
  //   : {},
  perms?.includes("*16_1") || perms?.startsWith("16_1")
    ? {
        label: "Admins",
        icon: Admins,
        path: "/admins",
      }
    : null,
    {
      label: "CardsTempletes",
      icon: Admins,
      path: "/CardsTempletes",
    }
  // {
  //   label: "Subject & Courses",
  //   icon: SubjIcons,
  //   subLinks: [
  //     {
  //       label: "Courses",
  //       path: "/Courses",
  //     },
  //   ],
  // },
];

export const settingsSideNavData = [
  {
    label: "TTS",
    icon: UnivIcon,
    path: "/settings/TTS",
  },
  {
    label: "Dashboard",
    icon: UnivIcon,
    // path: "/settings/Dashboard",
  },
  // {
  //   label: "App",
  //   icon: UnivIcon,
  //   // path: "/settings/APP",
  // },
];
