import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, SECOND_URL } from "../../constants";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader, Toggle } from "rsuite";
import { Input } from "../../components/form-elements";
import { addition } from "../../assets/svgIcons";
import { userData } from "../../data/fake_login_data";
import Select from 'react-select';
import './mainpost.css';
import ImagesInput from "../../components/imageInput/ImageInputMulti";
import TextArea from "../../components/form-elements/textarea";
import { perms } from "../../data/side_nav_data";
import { exportToCSV } from "../../functions";
const Mainpost = () => {
  // const navigate = useNavigate();
  // const location = useLocation();
  const [unies, setUnies] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState('');
  const [postContent, setPostContent] = useState([]);
  const [newImages, setNewImages] = useState([]);
  // const [grades,setGrades]=useState([]);
  const [subType, setSubType] = useState(false);
  const [selectedUni, setSelectedUni] = useState('');
  const [showToGradesModal, setShowToGradesModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [universities, setUniversities] = useState([]);
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [hiddenId, setHiddenId] = useState(false);
  const [postName, setPostName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [editId, setEditId] = useState(false);
  const [opened, setOpened] = useState(null);
  const [images, setImages] = useState([]);
  const [editGradesLoading, setEditGradesLoading] = useState(false);
  const headers = [
    {
      label: "ID",
      dataIndex: "post_id",
      search: true,
      sort: true,
    },
    perms?.includes("*12_4") || perms?.startsWith("12_4")
      ? {
          label: "Actions",
          type: "children",
          children: ({ headers, row, index, lastIndex }) => {
            return (
              <div className="menu_Open_close">
                <div
                  className="open_close"
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() =>
                    setOpened(opened == row?.post_id ? null : row?.post_id)
                  }
                >
                  <img
                    src="https://res.cloudinary.com/duovxefh6/image/upload/v1701489400/menu_hrpzcb.png"
                    alt=""
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
                {row?.post_id == opened ? (
                  <div
                    className={
                      index == lastIndex - 1 ||
                      index == lastIndex - 2 ||
                      index == lastIndex - 3
                        ? "actions-views row-actions-view"
                        : "actions-views column-actions-view"
                    }
                  >
                    {/* <button
                  className="btn btn-danger"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setDeleteId(row);
                  }}
                >
                  Delete
                </button> */}
                    {perms?.includes("*12_3") || perms?.startsWith("12_3") ? (
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          setEditId(row);
                        }}
                      >
                        Edit
                      </button>
                    ) : null}
                    {/* setEditId */}
                  </div>
                ) : null}
              </div>
            );
          },
        }
      : {},

    {
      label: "post_content",
      dataIndex: "post_content",
      search: true,
      sort: true,
      type: "children",
      children: ({ row }) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "130px" }}>
            {row?.post_content}
          </div>
        );
      },
    },
    {
      label: "Date",
      dataIndex: "post_date",
      // search: true,
      sort: true,
    },
    {
      label: "Current status",
      type: "children",
      children: ({ row }) => {
        if (row?.hidden == "false") {
          return <span className="text-success textCurrent">Show</span>;
        } else {
          return <span className="text-danger textCurrent">Hide</span>;
        }
      },
    },
    perms?.includes("*12_5") || perms?.startsWith("12_5")
      ? {
          label: "Change status",
          type: "children",
          children: ({ row }) => {
            if (row.hidden !== "false") {
              return (
                <span
                  className="btn btn-success"
                  style={{ color: "black" }}
                  onClick={() =>
                    setHiddenId({
                      post_id: row?.post_id,
                      status: row?.hidden == "false" ? "true" : "false",
                    })
                  }
                >
                  Show
                </span>
              );
            } else {
              return (
                <span
                  className="btn btn-danger"
                  style={{ color: "black" }}
                  onClick={() =>
                    setHiddenId({
                      post_id: row?.post_id,
                      post_name: row?.post_title,
                      status: "true",
                    })
                  }
                >
                  Hide
                </span>
              );
            }
          },
        }
      : {},
    perms?.includes("*12_4") || perms?.startsWith("12_4")
      ? {
          label: "Post Grades",
          dataIndex: "show",
          type: 'children',
          children: ({ row, headers }) => {
            return (
              <button
                onClick={() => {
                  setRowData(row);
                  setSelectedPostId(row.post_id);
                  getUnisGrades(row.grades_ids);
                  setSubType(row?.subscriptions);
                  // setgrades_ids(row.grades_ids);
                  setShowToGradesModal(row);
                }}
                className="btn btn-primary"
              >
                Edit Grades
              </button>
            );
          },
        }
      : {},
    // setHiddenId
  ];
  useEffect(() => {
    fetchDataUnis();
  }, []);
  window.addEventListener("click", (e) => {
    if (
      opened &&
      !e.target?.classList?.contains("actions-views") &&
      !e.target?.parentNode?.classList?.contains("open_close")
    ) {
      setOpened(null);
    }
  });
  const fetchDataUnis = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "universities/select_universitiesR.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      setUniversities(response?.data?.message);

      // let AllUnis=[...response?.data?.message];
      // for(let u=0;u<AllUnis.length;u++){
      //   console.log(AllUnis[u])
      // }
      setSelectedUni(response?.data?.message[0].university_id);
    } catch (err) {
      setUniversities([]);
      toast.error(err?.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + "posts/select_posts1.php", {
        // university_id: '2',
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });
      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const addPost = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    // console.log(form.university_id.value)

    try {
      const formData = new FormData();

      // Assuming your file input has the attribute 'multiple'
      const images = [];

      // Use Promise.all to wait for all image uploads to complete
      await Promise.all(
        Array.from(form.image.files).map(async (file) => {
          formData.append('image', file);
          const imageUploadResponse = await axios.post(
            BASE_URL + "item_img_uploader.php",
            formData
          );
          images.push(imageUploadResponse?.data?.message);
        })
      );

      const imageLinks = images;
      const response = await axios.post(BASE_URL + "posts/insert_post.php", {
        university_id: form.university_id.value,
        post_content: e?.target?.post_title?.value,
        images: imageLinks.join('**camp**'),
        admin_id: userData?.user_id,
        access_token: userData?.access_token, // Separate links with '***'
      });

      if (response.data?.status === "success") {
        toast.success(response.data?.message);
        fetchData();
        setShowAdd(false);
        form.reset();
      } else {
        toast.error(response.data?.message);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  const handleDeletePost = () => {
    const data_send = {
      post_id: deleteId?.post_id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
    };
    axios
      .post(BASE_URL + "posts/delete_post.php", JSON.stringify(data_send))
      .then((res) => {
        if (res.data.status == 'success') {
          toast.success(res.data.message);
          setDeleteId(false);
          fetchData();
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      });
  };
  const handleEditPost = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    try {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData(form);
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
      if (
        imageUpload?.data?.status == "success" ||
        !e.target?.image?.files?.length
      ) {
        const data_send = {
          university_id: '2',
          post_id: editId?.post_id,
          post_title: form?.post_title?.value,
          image_url:
            imageUpload?.data?.status == "success" && form?.image?.files?.length
              ? imageUpload?.data?.message
              : editId?.post_photo,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        };
        // console.log(data_send)
        const response = await axios.post(BASE_URL + "posts/update_post.php", {
          university_id: '2',
          post_id: editId?.post_id,
          post_title: form?.post_title?.value,
          image_url:
            imageUpload?.data?.status == "success" && form?.image?.files?.length
              ? imageUpload?.data?.message
              : editId?.post_photo,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        });

        if (response.data?.status == "success") {
          toast.success(response.data?.message);
          fetchData();
          setEditId(false);
          form.reset();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const hideUniv = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL + "posts/show_hide_posts.php",
        {
          post_id: hiddenId?.post_id,
          hidden: hiddenId?.status,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setHiddenId(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const getUnisGrades = async (arr) => {
    // console.log("erer")
    // console.log(grades_ids)
    try {
      const response = await axios.post(
        SECOND_URL + "/admin/general/select_universities_and_grades.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      let AllUnis = [...response?.data?.message];
      // console.log(AllUnis)
      let pushedArr = [];
      for (let u = 0; u < AllUnis.length; u++) {
        let uniGrades = [...AllUnis[u].grades];
        for (let g = 0; g < uniGrades.length; g++) {
          let obj = {
            ...uniGrades[g],
          };
          if (arr.includes(uniGrades[g].grade_id)) {
            obj['selected'] = true;
          } else {
            obj['selected'] = false;
          }
          uniGrades[g] = obj;
        }
        AllUnis[u].grades = uniGrades;
        // AllUnis[u].grades=
        pushedArr.push(AllUnis[u]);
      }
      setUnies(pushedArr);
      // console.log(pushedArr)
    } catch (err) {
      // setUnies([]);
      // toast.error(err?.message);
    }
  };
  const handleChangeChecked = (item, it, index, ind) => {
    let list = [...unies];
    list[index]['grades'][ind].selected = !list[index]['grades'][ind].selected;
    setUnies(list);
  };
  const handleEditShowGrades = async () => {
    setEditGradesLoading(true);
    let newGradesArr = [];
    let unisList = [...unies];
    for (let g = 0; g < unisList.length; g++) {
      let allUnisGrades = [...unisList[g]?.grades];
      for (let g = 0; g < allUnisGrades.length; g++) {
        if (allUnisGrades[g].selected) {
          newGradesArr.push(allUnisGrades[g].grade_id);
        }
      }
    }
    let newGradesIds = newGradesArr.join("**");
    const response = await axios.post(BASE_URL + "posts/update_show.php", {
      post_id: showToGradesModal?.post_id,
      grades_ids: newGradesIds,
      subscriptions: subType,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
    });
    toast.info(response?.data?.message);
    await fetchData();
  };
  const fetchPostImages = async () => {
    const images = await axios.post(
      BASE_URL + "posts/select_img_by_post_id.php",
      {
        post_id: editId?.post_id,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );
    setImages(images?.data?.message);
  };

  const updatePostText = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const deletition = await axios.post(
        BASE_URL + "posts/update_post_text.php",
        {
          post_id: editId?.post_id,
          post_content: postContent,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      if (deletition?.data?.status == "success") await fetchData();
      toast.info(deletition?.data?.message);
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };
  const deleteImage = async (post_image_id, post_id) => {
    setLoading(true);
    try {
      const deletition = await axios.post(BASE_URL + "posts/delete_img.php", {
        post_id: post_id,
        post_image_id: post_image_id,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });
      if (deletition?.data?.status == "success") fetchPostImages();
      toast.info(deletition?.data?.message);
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };
  const AddPostImage = async (item, index) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", item);
    const imageUploadResponse = await axios.post(
      BASE_URL + "item_img_uploader.php",
      formData
    );
    if (imageUploadResponse?.data?.status != "failed")
      try {
        const deletition = await axios.post(BASE_URL + "posts/insert_img.php", {
          post_id: editId?.post_id,
          image_url: imageUploadResponse?.data?.message,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        });
        if (deletition?.data?.status == "success") {
          fetchPostImages();
          setNewImages(
            newImages?.map((item, n_index) => {
              if (n_index == index) {
                item.added = true;
              }
              return item;
            })
          );
        }
        toast.info(deletition?.data?.message);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        toast.error(e.message);
      }
    else {
      toast?.error("حدث خطأ أثناء رفع الصورة ");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (editId) fetchPostImages();
  }, [editId]);
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span>Posts</span>
              {perms?.includes("*12_4") || perms?.startsWith("12_4") ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowAdd(true)}
                >
                  <span>{addition}</span>
                  <span>Add Post</span>
                </button>
              ) : null}
            </h4>
            {(perms?.includes("*17_8") || perms?.startsWith("17_8")) &&
            data?.length ? (
              <button
                className="btn btn-primary exportBtn"
                onClick={() => {
                  exportToCSV(data, "Posts Data");
                }}
              >
                Export
              </button>
            ) : null}
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />
          </div>
        }
      />
      <Modal
        headerTitle={"Add Post"}
        open={showAdd}
        toggle={setShowAdd}
        children={
          <form
            action="#"
            onSubmit={!loading ? addPost : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="post_title">University</label>
              <Select
                name="university_id"
                onChange={(e) => {
                  // console.log(e)
                }}
                options={universities.map((item, index) => {
                  return {
                    label: item.university_name,
                    value: item.university_id,
                  };
                })}
              />
            </div>
            <div className="inputField">
              <label htmlFor="post_title">Post Title</label>
              <TextArea
                type={"text"}
                id={"post_title"}
                name={"post_title"}
                onChange={() => null}
              />
            </div>
            <div className="inputField">
              <label htmlFor="images">Image URL</label>
              <Input
                type={"file"}
                id={"images"}
                name={"image"}
                showImage={true}
                onChange={() => null}
              />
            </div>

            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
      <Modal
        open={hiddenId}
        toggle={setHiddenId}
        headerTitle={"Show / Hide Post"}
        children={
          <>
            <h5>
              Are You Sure To{" "}
              {hiddenId?.status == "true" ? "Hide - " : "Show - "}
              Post ?
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  return !loading ? hideUniv() : null;
                }}
              >
                {!loading ? "Yes" : <Loader />}
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setHiddenId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />
      <Modal
        open={deleteId}
        toggle={setDeleteId}
        headerTitle={"Delete Post"}
        children={
          <>
            <h5>
              {"Are You Sure To Delete Post - " + deleteId?.post_title + " ?"}
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  handleDeletePost();
                }}
              >
                Yes
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setDeleteId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />

      <Modal
        headerTitle={"Edit Post"}
        open={editId}
        toggle={setEditId}
        children={
          <form
            action="#"
            onSubmit={!loading ? updatePostText : (e) => e.preventDefault()}
          >
            <div className="inputField" style={{ marginBottom: "12px" }}>
              <label htmlFor="post_name">Post Name</label>
              <TextArea
                type={"text"}
                id={"post_name"}
                name={"post_title"}
                defaultValue={editId?.post_content}
                onChange={(e) => setPostContent(e?.value)}
              />
              <button className="btn btn-success">
                {!loading ? "Save" : <Loader />}
              </button>
            </div>
            <div className="links_images">
              {images?.map((item) => {
                return (
                  <div className="postImage">
                    <img src={item?.image_url} alt="" />
                    <div
                      className="btn btn-danger"
                      onClick={() =>
                        loading
                          ? null
                          : deleteImage(item?.post_image_id, item?.post_id)
                      }
                    >
                      {loading ? <Loader /> : "Delete"}
                    </div>
                  </div>
                );
              })}
            </div>
            <h3>Add New Images</h3>
            <div className="links_new_images">
              <ImagesInput
                getImages={setNewImages}
                values={newImages}
                id={"newPostImages"}
                name={"newPostImages"}
                children={({ item, index }) =>
                  !item?.added ? (
                    <div
                      className="btn btn-success"
                      onClick={() => {
                        return !loading ? AddPostImage(item, index) : null;
                      }}
                    >
                      {!loading ? "Add Image" : <Loader />}
                    </div>
                  ) : (
                    "Addedd"
                  )
                }
              />
            </div>{" "}
          </form>
        }
      />
      <Modal
        headerTitle={"Change Show To Grades"}
        open={showToGradesModal}
        toggle={setShowToGradesModal}
        children={
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              handleEditShowGrades();
            }}
          >
            <ul className="all_postuni">
              {unies.map((item, index) => {
                return (
                  <>
                    <li>{item?.university_name}</li>
                    <ul className="post_uni">
                      {item?.grades?.map((it, ind) => {
                        return (
                          <li
                            onClick={() => {
                              handleChangeChecked(item, it, index, ind);
                            }}
                            className={it.selected ? 'active' : ''}
                          >
                            {it.grade_name}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                );
              })}
            </ul>
            For Subscriptions only: &nbsp;&nbsp;
            <Toggle checked={subType} onChange={(e) => setSubType(e)} />
            <br />
            <button className="btn btn-success">Edit</button>
          </form>
        }
      />
    </div>
  );
};

export default Mainpost;
