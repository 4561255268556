import React from "react";
import { Logo, Menu, NotificationIcon, search } from "../../assets/svgIcons";
import "./style.css";
const Header = ({ setSearchHeaderKey, showAside, setShowAside }) => {
  return (
    <header>
      <div className="header_left">
        <span
          className="menu"
          onClick={() => {
            if (setShowAside) {
              setShowAside(!showAside);
            }
          }}
        >
          {Menu}
        </span>
        {/* <div className="search">
          {search}
          <input
            type="text"
            onChange={(e) => setSearchHeaderKey(e?.target?.value)}
            placeholder="Search for anything"
          />
        </div> */}
        <div className="notification">{NotificationIcon}</div>
      </div>
    </header>
  );
};

export default Header;
