import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BurdCrumbs from "../../components/burdCrumbs";
import './termesconditions.css';
import { termsData } from "./data";
import Modal from "../../components/modal";
import { Spinner } from "reactstrap";
const TermsConditions = () => {
  const [editedData, setEditedData] = useState('');
  const [showEditTitle, setShowEditTitle] = useState(false);
  const [showEditDes, setShowEditDes] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [showCheckIfMakeChange, setShowCheckIfMakeChange] = useState(false);
  const [rowData, setRowData] = useState({});
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [terms, setTerms] = useState([]);
  const getTermsConditions = () => {
    let allData = [...termsData];
    setTerms(
      allData.map((item) => {
        return { ...item, showedti: false, showeddes: false };
      })
    );
    // setTerms(termsData)
  };
  const handleChangeVal = (val, name) => {
    let newObj = {
      ...rowData,
    };
    newObj[`${name}`] = val;
    setRowData({ newObj });
    // setRowData(...newObj);
  };
  const hanldeEdit = () => {
    setEditLoading(true);
    const data_send = {
      ...rowData,
    };
    setEditLoading(false);
  };
  const handleChangeShowStatus = (id, name) => {
    let list = [...terms];
    if (name == 'title') {
      setTerms(
        list.map((item, index) => {
          if (item.id == id) {
            return { ...item, showedti: true, showeddes: false };
          } else return { ...item, showedti: false, showeddes: false };
        })
      );
    } else {
      setTerms(
        list.map((item, index) => {
          if (item.id == id) {
            return { ...item, showeddes: true, showedti: false };
          } else return { ...item, showedti: false, showeddes: false };
        })
      );
    }
  };
  const handleMakeAllFalse = () => {
    let allData = [...terms];
    setTerms(
      allData.map((item) => {
        return { ...item, showedti: false, showeddes: false };
      })
    );
  };
  const handleReqChanges = () => {
    const data_send = {};
  };
  useEffect(() => {
    getTermsConditions();
  }, []);
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <div className="ed_bur">
              <BurdCrumbs title={"Terms and Conditions"} />
              {/* <img onClick={()=>{
                setShowEditModal(true)
              }} src={require("../../assets/images/edit.png")} alt="" /> */}
            </div>
            <div className="bg-light terms_page">
              {terms.map((item, index) => {
                return (
                  <div>
                    <h4 className="d-flex my-3 align-items-center justify-content-between">
                      <span>{item.title}</span>
                      <img
                        onClick={() => {
                          // setRowData(item);
                          // setShowEditModal(true)
                          handleChangeShowStatus(item.id, 'title');
                          setEditedData(item.title);
                          setShowEditTitle(true);
                        }}
                        style={{ width: '20px', cursor: 'pointer' }}
                        src={require("../../assets/images/edit.png")}
                        alt=""
                      />
                    </h4>
                    <div className="col-lg-6 col-md-12 col-sm-6">
                      {item.showedti ? (
                        <input
                          onBlur={() => {
                            setShowCheckIfMakeChange(true);
                          }}
                          onChange={(e) => {
                            setEditedData(e.target.value);
                          }}
                          className="form-control my-2"
                          type="text"
                          value={editedData}
                        />
                      ) : null}
                    </div>
                    <p className="d-flex align-items-start justify-content-between">
                      <span>{item.description}</span>
                      <img
                        onClick={() => {
                          // setRowData(item);
                          // setShowEditModal(true)
                          handleChangeShowStatus(item.id, 'description');
                          setEditedData(item.description);
                          setShowEditTitle(true);
                        }}
                        style={{ width: '20px', cursor: 'pointer' }}
                        src={require("../../assets/images/edit.png")}
                        alt=""
                      />
                    </p>
                    {item.showeddes ? (
                      <textarea
                        onBlur={() => {
                          setShowCheckIfMakeChange(true);
                        }}
                        onChange={(e) => {
                          setEditedData(e.target.value);
                        }}
                        className="form-control my-2"
                        name=""
                        value={editedData}
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        }
      />
      <Modal
        open={showEditModal}
        toggle={setShowEditModal}
        headerTitle={"Edit Terms & Conditions"}
        children={
          <div>
            <form action="">
              <div className="col-lg-12">
                <label className="mb-1" htmlFor="">
                  Title:
                </label>
                <input
                  onChange={(e) => {
                    handleChangeVal(e.target.value, 'title');
                  }}
                  name={Object.keys(rowData)[1]}
                  className="form-control"
                  type="text"
                  value={rowData.title}
                />
              </div>
              <div className="col-lg-12 my-2">
                <label className="mb-1" htmlFor="">
                  Description:
                </label>
                <textarea
                  onChange={(e) => {
                    handleChangeVal(e.target.value, 'description');
                  }}
                  name={Object.keys(rowData)[2]}
                  className="form-control"
                  value={rowData.description}
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <button
                style={{ width: '100px', marginTop: '20px', padding: '10px' }}
                onClick={() => {
                  hanldeEdit();
                }}
                className="btn btn-success mx-auto d-block"
              >
                {editLoading ? <Spinner /> : 'Edit'}
              </button>
            </form>
          </div>
        }
      />
      <Modal
        open={showCheckIfMakeChange}
        toggle={setShowCheckIfMakeChange}
        headerTitle={"Edit Terms & Conditions"}
        children={
          <div>
            <p>Are You Want To make Thoses Changes</p>
            <div className="btns-actions">
              <button
                onClick={() => {
                  handleReqChanges();
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setShowCheckIfMakeChange(false);
                  handleMakeAllFalse();
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default TermsConditions;
