import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import "../subjects/style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { Loader } from "rsuite";
import { ChevDown } from "../../assets/svgIcons";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
const SupscriptionsType = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const univ_id = location?.state?.university_id;
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [AssignLoading, setAssignLoading] = useState(false);
  let [choosedDocs, setChoosedDocs] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [banId, setBanId] = useState(false);
  const [resetId, setResetId] = useState(false);
  const [data, setData] = useState(false);
  const [uData, setUData] = useState(false);
  const [stu_id, set_stu_id] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [opened, setOpened] = useState(null);
  useEffect(() => {
    if (stu_id) fetchUData();
  }, [stu_id]);

  useEffect(() => {
    fetchData();
  }, []);

  const subscribe = async (course_id, sub_type, type) => {
    await axios
      .post(
        type == "yes"
          ? BASE_URL + "subscriptions/add_subscription.php"
          : type == "no"
          ? BASE_URL + "subscriptions/end_subscription.php"
          : "",
        {
          student_id: stu_id?.student_id,
          course_id: course_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          sub_type: sub_type, // course - chapter
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
      });
    await fetchUData();
  };
  const fetchUData = async () => {
    try {
      setAssignLoading(true);
      const response = await axios.post(
        BASE_URL + "students/select_student_material.php",
        {
          student_id: stu_id?.student_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setAssignLoading(false);
      setUData(response?.data?.message);
    } catch (err) {
      toast.error(err?.message);
      setAssignLoading(false);
    }
  };
  const headers = [
    {
      label: "ID",
      dataIndex: "card_id",
      sort: true,
      search: true,
    },
    // {
    //   label: "Used",
    //   dataIndex: "card_used",
    //   type: "children",
    //   children: ({ headers, row }) => {
    //     return <div>{row?.card_used != 0 ? "Used" : "Not Used"}</div>;
    //   },
    //   sort: true,
    //   search: true,
    // },
    // {
    //   label: "Actions",
    //   type: "children",
    //   children: ({ headers, row, index, lastIndex }) => {
    //     return (
    //       <div className="menu_Open_close">
    //         <div
    //           className="open_close"
    //           style={{ cursor: "pointer", color: "black" }}
    //           onClick={() =>
    //             setOpened(opened == row?.card_id ? null : row?.card_id)
    //           }
    //         >
    //           <img
    //             src="https://res.cloudinary.com/duovxefh6/image/upload/v1701489400/menu_hrpzcb.png"
    //             alt=""
    //             style={{ width: "20px", height: "20px" }}
    //           />
    //         </div>
    //         {row?.card_id == opened ? (
    //           <div
    //             className={
    //               index == lastIndex - 1 ||
    //               index == lastIndex - 2 ||
    //               index == lastIndex - 3
    //                 ? "actions-views row-actions-view"
    //                 : "actions-views column-actions-view"
    //             }
    //           >
    //             <button
    //               className="btn btn-danger"
    //               style={{ marginRight: "10px" }}
    //               onClick={() => {
    //                 setResetId(false);
    //                 setBanId(row);
    //               }}
    //             >
    //               Ban
    //             </button>
    //             <button
    //               className="btn btn-danger"
    //               style={{ marginRight: "10px" }}
    //               onClick={() => {
    //                 setResetId(row);
    //                 setBanId(false);
    //               }}
    //             >
    //               Reset
    //             </button>
    //             <button
    //               className="btn btn-danger"
    //               style={{ marginRight: "10px" }}
    //               onClick={() => {
    //                 setDeleteId(row);
    //                 setBanId(false);
    //                 setResetId(false);
    //               }}
    //             >
    //               Delete
    //             </button>
    //           </div>
    //         ) : null}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   label: "Type",
    //   dataIndex: "card_type",
    //   sort: true,
    //   search: true,
    // },
    {
      label: "Card Code",
      dataIndex: "card_code",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return <div>{row?.card_code}</div>;
      },
    },
    // student_data

    {
      label: "Create Date",
      dataIndex: "create_date",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return <div>{row?.create_date}</div>;
      },
    },
    {
      label: "Used Date",
      dataIndex: "used_date",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return <div>{row?.used_date}</div>;
      },
    },
    {
      label: "Student Name",
      dataIndex: "student_name",
      search: true,
      sort: true,
    },
    {
      label: "Student Phone",
      dataIndex: "student_phone",
      search: true,
      sort: true,
    },
  ];
  window.addEventListener("click", (e) => {
    if (
      opened &&
      !e.target?.classList?.contains("actions-views") &&
      !e.target?.parentNode?.classList?.contains("open_close")
    ) {
      setOpened(null);
    }
  });
  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(
        location?.state?.subType == "Course"
          ? BASE_URL + "subscriptions/courses/select_cards.php"
          : location?.state?.subType == "Cahpter"
          ? BASE_URL + "subscriptions/chapters/select_student_sub_chapter1.php"
          : "",
        {
          course_id: location?.state?.course_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          chapter_id: location?.state?.chapter_id,
        }
      );

      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const handleDeletePhone = () => {
    const data_send = {
      student_id: deleteId?.student_id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
    };
    axios
      .post(
        BASE_URL + "students/delete_student_new.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.data.status == 'success') {
          toast.success(res.data.message);
          setDeleteId(false);
          fetchData();
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      });
  };
  const resetUser = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL + "students/update_serial.php",
        {
          student_id: resetId?.student_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setResetId(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      document.querySelectorAll(".chain") &&
      document.querySelectorAll(".chain")?.length
    ) {
      document.querySelectorAll(".chain").forEach((value, index, array) => {
        if (value[index] && value[index].querySelector("li")) {
          value[index].querySelector("li").style.height = 0;
          value[index].style.overflow = "hidden";
        }
        value.querySelector("span").addEventListener("click", (e) => {
          const valueLength = value.querySelector("li");
          if (parseInt(valueLength.style.height.split("px")[0])) {
            valueLength.style.height = 0;
            valueLength.style.overflow = "hidden";
            if (value.querySelector("span svg"))
              value.querySelector("svg").classList.remove("active");
          } else {
            valueLength.style.height = valueLength?.scrollHeight + "px";
            valueLength.style.overflow = "auto";
            if (value.querySelector("span svg"))
              value.querySelector("svg").classList.add("active");
          }
        });
      });

      // ==============================================
      document
        .querySelectorAll("ul.chain > li > ul > li ")
        .forEach((value, index, array) => {
          value.querySelector("span").addEventListener("click", (e) => {
            const valueLength = value.querySelector("ul");
            if (parseInt(valueLength.style.height.split("px")[0])) {
              valueLength.style.height = 0;
              valueLength.style.overflow = "hidden";
              if (value.querySelector("span svg"))
                value.querySelector("span svg").classList.remove("active");
            } else {
              valueLength.style.height = valueLength?.scrollHeight + "px";
              valueLength.style.overflow = "auto";
              if (value.querySelector("span svg"))
                value.querySelector("span svg").classList.add("active");
            }
          });
        });
    }
  }, [uData, stu_id, document, fetchData, fetchUData, data]);

  return (
    <div id="home">
      {console.log(location?.state)}
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <BurdCrumbs
              title={`${location?.state?.type} Supscription For ${
                location?.state?.subType == "Course"
                  ? "Course ( "
                  : "Chapter ( "
              }
                  ${
                    location?.state?.subType == "Course"
                      ? location?.state?.chainDetails[0]?.course_name
                      : location?.state?.chainDetails[0]?.chapter_title
                  } 
                 )`}
            />

            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />

            <Modal
              open={showExport}
              toggle={setShowExport}
              headerTitle={"Export Data As Excel File"}
              children={
                <div className="flex-box">
                  {choosedDocs && choosedDocs?.length ? (
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        exportToCSV(choosedDocs, "cients Data");
                      }}
                    >
                      Export Only Selected
                    </button>
                  ) : null}

                  <button
                    className="btn btn-primary"
                    width={"fit-content"}
                    onClick={() => {
                      exportToCSV(data, "Student Data");
                    }}
                  >
                    Export all Data
                  </button>
                </div>
              }
            />

            <Modal
              open={banId}
              toggle={setBanId}
              headerTitle={"Ban Student"}
              children={
                <>
                  <h5>
                    {"Are You Sure To Ban User - " + banId?.student_name + " ?"}
                  </h5>
                  <div className="flex-box">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        toast.success("Success");
                      }}
                    >
                      Yes
                    </button>

                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setBanId(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />

            <Modal
              open={resetId}
              toggle={setResetId}
              headerTitle={"Reset Student"}
              children={
                <>
                  <h5>
                    {"Are You Sure To Reset User - " +
                      resetId?.student_name +
                      " ?"}
                  </h5>
                  <div className="flex-box">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        return !loading ? resetUser() : null;
                      }}
                    >
                      {!loading ? "Yes" : <Loader />}
                    </button>

                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setResetId(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />

            <Modal
              open={deleteId}
              toggle={setDeleteId}
              headerTitle={"Delete Student"}
              children={
                <>
                  <h5>
                    {"Are You Sure To Delete User - " +
                      deleteId?.student_name +
                      " ?"}
                  </h5>
                  <div className="flex-box">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        handleDeletePhone();
                      }}
                    >
                      Yes
                    </button>

                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setDeleteId(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />

            <Modal
              open={uData}
              toggle={() => {
                setUData(false);
                set_stu_id(false);
              }}
              headerTitle={
                "Assign Subject To Student  -  ( " + stu_id?.student_name + " )"
              }
              children={
                <>
                  <div className="chainsContainer">
                    {uData && uData?.length
                      ? uData?.map((item, index) => {
                          return (
                            <ul className="chain">
                              <span>
                                <span>{item?.chain_name}</span>
                                <span>{ChevDown}</span>
                              </span>
                              <li>
                                {!item?.courses ? (
                                  <Loader />
                                ) : item?.courses?.length ? (
                                  item?.courses?.map((c_item) => {
                                    return (
                                      <ul>
                                        <li>
                                          <span>
                                            <span>{c_item?.course_name}</span>
                                            <button
                                              onClick={(e) => {
                                                return c_item?.subscribed ==
                                                  "no"
                                                  ? subscribe(
                                                      c_item?.course_id,
                                                      "course",
                                                      "yes"
                                                    )
                                                  : subscribe(
                                                      c_item?.course_id,
                                                      "course",
                                                      "no"
                                                    );
                                              }}
                                              className={
                                                c_item?.subscribed == "no"
                                                  ? "btn btn-success"
                                                  : "btn btn-danger"
                                              }
                                            >
                                              {c_item?.subscribed == "no"
                                                ? "Subscribe"
                                                : "UnSubscribe"}
                                            </button>
                                            <span>{ChevDown}</span>
                                          </span>
                                          <ul>
                                            {!c_item?.chapters ? (
                                              <Loader />
                                            ) : c_item?.chapters?.length ? (
                                              c_item?.chapters?.map(
                                                (s_item) => {
                                                  return (
                                                    <li>
                                                      <span>
                                                        <span>
                                                          {
                                                            s_item?.chapter_title
                                                          }
                                                        </span>
                                                        {c_item?.subscribed ==
                                                        "no" ? (
                                                          <button
                                                            onClick={() => {
                                                              return s_item?.subscribed ==
                                                                "no"
                                                                ? subscribe(
                                                                    s_item?.chapter_id,
                                                                    "chapter",
                                                                    "yes"
                                                                  )
                                                                : subscribe(
                                                                    s_item?.chapter_id,
                                                                    "chapter",
                                                                    "no"
                                                                  );
                                                            }}
                                                            className={
                                                              s_item?.subscribed ==
                                                              "no"
                                                                ? "btn btn-success"
                                                                : "btn btn-danger"
                                                            }
                                                          >
                                                            {s_item?.subscribed ==
                                                            "no"
                                                              ? "Subscribe"
                                                              : "UnSubscribe"}
                                                          </button>
                                                        ) : null}
                                                      </span>
                                                    </li>
                                                  );
                                                }
                                              )
                                            ) : null}
                                          </ul>
                                        </li>
                                      </ul>
                                    );
                                  })
                                ) : null}
                              </li>
                            </ul>
                          );
                        })
                      : null}
                  </div>
                </>
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default SupscriptionsType;
