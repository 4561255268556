import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants";
import { Loader } from "rsuite";
import { addition } from "../../assets/svgIcons";
import Modal from "../../components/modal";
import { Input } from "../../components/form-elements";
import { userData } from "../../data/fake_login_data";
import { perms } from "../../data/side_nav_data";
import { exportToCSV } from "../../functions";
import Delete from "../chains/delete";
const ChainsCoursesChapters = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [data, setData] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [chapterName, setChapterName] = useState(false);
  const [chapterLogo, setChapterLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [filteredData, setFilteredData] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [editId, setEditId] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "courses/chapters/select_course_chapters.php",
        {
          course_id: location?.state?.course_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const addChapter = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("image", chapterLogo?.files[0]);
    try {
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
      if (imageUpload?.data?.status == "success") {
        const response = await axios.post(
          BASE_URL + "courses/chapters/insert_chapter.php",
          {
            chapter_title: chapterName?.value,
            chapter_photo: imageUpload?.data?.message,
            course_id: location?.state?.course_id,
            admin_id: userData?.user_id,
            access_token: userData?.access_token,
          }
        );

        if (response.data?.status == "success") {
          toast.success(response.data?.message);
          fetchData();
          setChapterLogo(null);
          setChapterName(null);
          setShowAdd(false);
          form.reset();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      } else {
        setChapterLogo(false);
        toast.error("Failed on Upload Image");
        setLoading(false);
      }
      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const editChapter = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const formData = new FormData();
    formData.append("image", chapterLogo);
    try {
      const imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
      if (
        imageUpload?.data?.status == "success" ||
        !chapterLogo ||
        editId?.chapter_photo
      ) {
        const response = await axios.post(
          BASE_URL + "courses/chapters/update_chapter.php",
          {
            chapter_id: editId?.chapter_id,
            course_id: location?.state?.course_id,
            chapter_photo:
              imageUpload?.data?.message &&
              imageUpload?.data?.message?.length &&
              chapterLogo
                ? imageUpload?.data?.message
                : editId?.chapter_photo,
            chapter_title: chapterName ? chapterName : editId?.chapter_title,
            admin_id: userData?.user_id,
            access_token: userData?.access_token,
          }
        );
        if (response.data?.status == "success") {
          fetchData();
          setEditId(false);
          setChapterLogo(null);
          setChapterName(null);
          form.reset();
          setLoading(false);
          toast.success(response.data?.message);
        } else {
          setLoading(false);
          toast.error(response.data?.message);
        }
      } else {
        setChapterLogo(false);
        toast.error("Failed on Upload Image");
        setLoading(false);
      }
      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const handleDeleteChapter = () => {
    const data_send = {
      chapter_id: deleteId?.chapter_id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
      hidden: deleteId?.hidden == "no" ? "yes" : "no",
    };
    axios
      .post(
        BASE_URL + "courses/chapters/show_hide_chapter.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setDeleteId(false);
          fetchData();
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      });
  };
  useEffect(() => {
    if (searchHeaderKet && searchHeaderKet?.length) {
      setFilteredData(
        data?.filter(
          (item) =>
            item?.chapter_id == searchHeaderKet ||
            item?.chapter_title?.includes(searchHeaderKet) ||
            item?.chapter_description?.includes(searchHeaderKet)
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [searchHeaderKet, data]); // Use a Set to keep track of unique items

  if (!location?.state?.course_id) {
    navigate(-1);
  }

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <>
            <h4 className="customBreadCrumbs">
              <span>Chapters For Course ({location?.state?.course_name})</span>
              {perms?.includes("*5_2") || perms?.startsWith("5_2") ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowAdd(true)}
                >
                  <span>{addition}</span>
                  <span>Add Chapter</span>
                </button>
              ) : null}
            </h4>
            {(perms?.includes("*17_4") || perms?.startsWith("17_4")) &&
            data?.length ? (
              <button
                className="btn btn-primary exportBtn"
                onClick={() => {
                  exportToCSV(
                    data,
                    location?.state?.course_name + " - Chapters Data"
                  );
                }}
              >
                Export
              </button>
            ) : null}
            <div className="filters_box">
              {/* <div className="orderby"> */}
              {/* <input type="text" id="orderBy" /> */}
              {/* </div> */}
            </div>
            {!data || !filteredData ? (
              <Loader />
            ) : data?.length || filteredData?.length ? (
              <div className="course_Container">
                {filteredData?.map((item) => {
                  return (
                    <div className="course">
                      <img
                        src={item?.chapter_photo}
                        alt=""
                        onClick={() =>
                          perms?.includes("*5_1") || perms?.startsWith("5_1")
                            ? navigate("content", {
                                state: {
                                  university_id: 1,
                                  course_id: location?.state?.course_id,
                                  grade_id: 1,

                                  chain_id: location?.state?.chain_id,
                                  chapter_id: item?.chapter_id,
                                  chapter_name: item?.chapter_title,
                                },
                              })
                            : null
                        }
                      />
                      <div className="topCourseHeader">
                        <div
                          className="left"
                          onClick={() =>
                            perms?.includes("*5_1") || perms?.startsWith("5_1")
                              ? navigate("content", {
                                  state: {
                                    university_id:
                                      location?.state?.university_id,
                                    grade_id: 1,
                                    course_id: location?.state?.course_id,
                                    chain_id: location?.state?.chain_id,
                                    chapter_id: item?.chapter_id,
                                    chapter_name: item?.chapter_title,
                                  },
                                })
                              : null
                          }
                        >
                          {item?.chapter_title}
                        </div>
                        <div className="right">
                          <span
                            onClick={() => {
                              setShowActions(
                                showActions !== item?.chapter_id
                                  ? item?.chapter_id
                                  : false
                              );
                            }}
                          >
                            ...
                          </span>

                          {item?.chapter_id == showActions ? (
                            <div className="courseActions">
                              {perms?.includes("*5_3") ||
                              perms?.startsWith("5_3") ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) => setEditId(item)}
                                >
                                  Edit
                                </button>
                              ) : null}
                              <button
                                className="btn btn-danger mx-2"
                                onClick={() =>
                                  setDeleted({
                                    value: item?.chapter_id,
                                    table: "course_chapter",
                                    column: "chapter_id",
                                  })
                                }
                              >
                                Delete
                              </button>
                              {perms?.includes("*5_4") ||
                              perms?.startsWith("5_4") ? (
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => setDeleteId(item)}
                                >
                                  {item?.hidden == "no" ? "Hide" : "Show"}
                                </button>
                              ) : null}
                              {perms?.includes("*5_6") ||
                              perms?.startsWith("5_6") ? (
                                <div
                                  className="bottomCourseCategory btn btn-success"
                                  onClick={() =>
                                    navigate("subscriptions", {
                                      state: {
                                        university_id:
                                          location?.state?.university_id,
                                        course_id: location?.state?.course_id,
                                        chain_id: location?.state?.chain_id,
                                        chapter_id: item?.chapter_id,
                                        chapter_name: item?.chapter_title,
                                      },
                                    })
                                  }
                                >
                                  Subscriptions
                                </div>
                              ) : null}
                              {perms?.includes("*5_7") ||
                              perms?.startsWith("5_7") ? (
                                <div
                                  className="bottomCourseCategory btn btn-success"
                                  onClick={() =>
                                    navigate("subscriptions", {
                                      state: {
                                        university_id:
                                          location?.state?.university_id,
                                        course_id: location?.state?.course_id,
                                        chain_id: location?.state?.chain_id,
                                        chapter_id: item?.chapter_id,
                                        chapter_name: item?.chapter_title,
                                      },
                                    })
                                  }
                                >
                                  Quizzes
                                </div>
                              ) : null}{" "}
                              {perms?.includes("*5_8") ||
                              perms?.startsWith("5_8") ? (
                                <div
                                  onClick={() => {
                                    navigate("chapnots", {
                                      data: item,
                                      ...{
                                        university_id:
                                          location?.state?.university_id,
                                        course_id: location?.state?.course_id,
                                        chain_id: location?.state?.chain_id,
                                        chapter_id: item?.chapter_id,
                                        chapter_name: item?.chapter_title,
                                      },
                                    });
                                  }}
                                  className="btn text-light btn-warning"
                                >
                                  Notifications
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className="bottomCourseCategory"
                        onClick={() =>
                          perms?.includes("*5_1") || perms?.startsWith("5_1")
                            ? navigate("content", {
                                state: {
                                  university_id: 1,
                                  course_id: location?.state?.course_id,
                                  chain_id: location?.state?.chain_id,
                                  chapter_id: item?.chapter_id,
                                  chapter_name: item?.chapter_title,
                                },
                              })
                            : null
                        }
                      >
                        Chapter
                      </div>
                      {perms?.includes("*5_5") || perms?.startsWith("5_5") ? (
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            navigate("chanpphonelist", {
                              state: {
                                chain_id: location?.state?.chain_id,
                                university_id: 1,
                                grade_id: 1,

                                course_id: location?.state?.course_id,
                                chapter_id: item?.chapter_id,
                              },
                            })
                          }
                        >
                          Auto Subuscription
                        </button>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : (
              <h4>No Chapters</h4>
            )}
          </>
        }
      />
      <Modal
        headerTitle={"Add Chapter"}
        open={showAdd}
        toggle={setShowAdd}
        children={
          <form
            action="#"
            onSubmit={!loading ? addChapter : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="chapter_name">Chapter Name</label>
              <Input
                type={"text"}
                id={"chapter_name"}
                name={"chapter_name"}
                onChange={setChapterName}
              />
            </div>
            <div className="inputField">
              <label htmlFor="chapter_Logo">Chapter Logo</label>
              <Input
                type={"file"}
                id={"chapter_Logo"}
                name={"chapter_Logo"}
                onChange={setChapterLogo}
                showImage={true}
              />
            </div>

            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
      <Modal
        headerTitle={"Edit Chapter"}
        open={editId}
        toggle={setEditId}
        children={
          <form
            action="#"
            onSubmit={!loading ? editChapter : (e) => e.preventDefault()}
          >
            <div className="inputField">
              <label htmlFor="chapter_title">Chapter Name</label>
              <Input
                type={"text"}
                id={"chapter_title"}
                name={"chapter_title"}
                defaultValue={editId?.chapter_title}
                onChange={(e) => setChapterName(e.value)}
              />
            </div>

            <div className="inputField">
              <label htmlFor="chapter_Logo">Chapter Logo</label>
              <Input
                type={"file"}
                id={"chapter_Logo"}
                name={"chapter_Logo"}
                onChange={(e) => setChapterLogo(e.files[0])}
                showImage={true}
              />
            </div>

            <button className="btn btn-success">
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        }
      />
      <Modal
        open={deleteId}
        toggle={setDeleteId}
        headerTitle={"Delete Chapter"}
        children={
          <>
            <h5>
              {"Are You Sure To Delete Chapter - " +
                deleteId?.chapter_title +
                " ?"}
            </h5>
            <div className="flex-box">
              <button
                className="btn btn-danger"
                onClick={() => {
                  handleDeleteChapter();
                }}
              >
                Yes
              </button>

              <button
                className="btn btn-primary"
                width={"fit-content"}
                onClick={() => {
                  setDeleteId(false);
                }}
              >
                No
              </button>
            </div>
          </>
        }
      />
      <Delete data={deleted} setData={setDeleted} fetchData={fetchData} />
    </div>
  );
};

export default ChainsCoursesChapters;
