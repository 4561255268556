import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./dashlogs.css";
import "../subjects/style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { Loader } from "rsuite";
import { ChevDown, backIcon } from "../../assets/svgIcons";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
import { dashLogsData, logsDetails } from "./data";
import { perms } from "../../data/side_nav_data";
const DashLogs = () => {
  const [rowData, setRowData] = useState({});
  const [showUnDoModal, setShowUnDoModal] = useState(false);
  const [rendDetails, setRendDetails] = useState('dashlogs');
  const location = useLocation();
  const navigate = useNavigate();
  const univ_id = location?.state?.university_id;
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [AssignLoading, setAssignLoading] = useState(false);
  let [choosedDocs, setChoosedDocs] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [banId, setBanId] = useState(false);
  const [resetId, setResetId] = useState(false);
  const [data, setData] = useState(false);
  const [uData, setUData] = useState(false);
  const [stu_id, set_stu_id] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [opened, setOpened] = useState(null);
  useEffect(() => {
    if (stu_id) fetchUData();
  }, [stu_id]);

  useEffect(() => {
    fetchData();
  }, []);

  const subscribe = async (course_id, sub_type, type) => {
    await axios
      .post(
        type == "yes"
          ? BASE_URL + "subscriptions/add_subscription.php"
          : type == "no"
          ? BASE_URL + "subscriptions/end_subscription.php"
          : "",
        {
          student_id: stu_id?.student_id,
          course_id: course_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          sub_type: sub_type, // course - chapter
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
      });
    await fetchUData();
  };

  const fetchUData = async () => {
    try {
      setAssignLoading(true);
      const response = await axios.post(
        BASE_URL + "students/select_student_material.php",
        {
          student_id: stu_id?.student_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setAssignLoading(false);
      setUData(response?.data?.message);
    } catch (err) {
      toast.error(err?.message);
      setAssignLoading(false);
    }
  };

  const headers2 = [
    {
      label: "ID",
      dataIndex: "log_id",
      sort: true,
      search: true,
    },
    {
      label: "Date & Time",
      dataIndex: "time",
      sort: true,
      search: true,
    },
    // {
    //   label: "Admin Name",
    //   dataIndex: "user_name",
    //   sort: true,
    //   search: true,
    // },
    {
      label: "Admin Email",
      dataIndex: "user_email",
      sort: true,
      search: true,
    },
    {
      label: "Log Text",
      dataIndex: "log_value",
      sort: true,
      search: true,
    },
    {
      label: "Status",
      dataIndex: "status",
      sort: true,
      search: true,
    },
  ];
  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + "logs/select_logs.php", {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      });

      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const handleDeletePhone = () => {
    const data_send = {
      student_id: deleteId?.student_id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
    };
    axios
      .post(
        BASE_URL + "students/delete_student_new.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.data.status == 'success') {
          toast.success(res.data.message);
          setDeleteId(false);
          fetchData();
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      });
  };
  const resetUser = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL + "students/update_serial.php",
        {
          student_id: resetId?.student_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setResetId(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      document.querySelectorAll(".chain") &&
      document.querySelectorAll(".chain")?.length
    ) {
      document.querySelectorAll(".chain").forEach((value, index, array) => {
        if (value[index] && value[index].querySelector("li")) {
          value[index].querySelector("li").style.height = 0;
          value[index].style.overflow = "hidden";
        }
        value.querySelector("span").addEventListener("click", (e) => {
          const valueLength = value.querySelector("li");
          if (parseInt(valueLength.style.height.split("px")[0])) {
            valueLength.style.height = 0;
            valueLength.style.overflow = "hidden";
            if (value.querySelector("span svg"))
              value.querySelector("svg").classList.remove("active");
          } else {
            valueLength.style.height = valueLength?.scrollHeight + "px";
            valueLength.style.overflow = "auto";
            if (value.querySelector("span svg"))
              value.querySelector("svg").classList.add("active");
          }
        });
      });

      // ==============================================
      document
        .querySelectorAll("ul.chain > li > ul > li ")
        .forEach((value, index, array) => {
          value.querySelector("span").addEventListener("click", (e) => {
            const valueLength = value.querySelector("ul");
            if (parseInt(valueLength.style.height.split("px")[0])) {
              valueLength.style.height = 0;
              valueLength.style.overflow = "hidden";
              if (value.querySelector("span svg"))
                value.querySelector("span svg").classList.remove("active");
            } else {
              valueLength.style.height = valueLength?.scrollHeight + "px";
              valueLength.style.overflow = "auto";
              if (value.querySelector("span svg"))
                value.querySelector("span svg").classList.add("active");
            }
          });
        });
    }
  }, [uData, stu_id, document, fetchData, fetchUData, data]);

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <div className="log_login">
              <BurdCrumbs title={"Dashboard Logs"} />
              {(perms?.includes("*17_12") || perms?.startsWith("17_12")) &&
              data?.length ? (
                <button
                  className="btn btn-primary exportBtn"
                  onClick={() => {
                    exportToCSV(data, "Dashboard Logs");
                  }}
                >
                  Export
                </button>
              ) : null}
              {/* {rendDetails == 'dashlogs' ? (
                <button
                  onClick={() => {
                    setRendDetails('moredet');
                  }}
                  className="dash_login"
                >
                  Dashboard Logins
                </button>
              ) : (
                <span
                  onClick={() => {
                    setRendDetails('dashlogs');
                  }}
                  style={{
                    cursor: 'pointer',
                    fontSize: '22px',
                    marginRight: '20px',
                    display: 'block',
                  }}
                >
                  {backIcon}
                </span>
              )} */}
            </div>

            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers2}
              data={data}
            />

            <Modal
              open={showExport}
              toggle={setShowExport}
              headerTitle={"Export Data As Excel File"}
              children={
                <div className="flex-box">
                  {choosedDocs && choosedDocs?.length ? (
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        exportToCSV(choosedDocs, "cients Data");
                      }}
                    >
                      Export Only Selected
                    </button>
                  ) : null}

                  <button
                    className="btn btn-primary"
                    width={"fit-content"}
                    onClick={() => {
                      exportToCSV(data, "Student Data");
                    }}
                  >
                    Export all Data
                  </button>
                </div>
              }
            />

            <Modal
              open={banId}
              toggle={setBanId}
              headerTitle={"Ban Student"}
              children={
                <>
                  <h5>
                    {"Are You Sure To Ban User - " + banId?.student_name + " ?"}
                  </h5>
                  <div className="flex-box">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        toast.success("Success");
                      }}
                    >
                      Yes
                    </button>

                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setBanId(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />

            <Modal
              open={showUnDoModal}
              toggle={setShowUnDoModal}
              headerTitle={"Undo Action"}
              children={
                <>
                  <h5>{"Are You Sure To Undo this ?"}</h5>
                  <div className="flex-box">
                    <button className="btn btn-success">
                      {!loading ? "Yes" : <Loader />}
                    </button>

                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setShowUnDoModal(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />

            <Modal
              open={deleteId}
              toggle={setDeleteId}
              headerTitle={"Delete Student"}
              children={
                <>
                  <h5>
                    {"Are You Sure To Delete User - " +
                      deleteId?.student_name +
                      " ?"}
                  </h5>
                  <div className="flex-box">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        handleDeletePhone();
                      }}
                    >
                      Yes
                    </button>

                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setDeleteId(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />

            <Modal
              open={uData}
              toggle={() => {
                setUData(false);
                set_stu_id(false);
              }}
              headerTitle={
                "Assign Subject To Student  -  ( " + stu_id?.student_name + " )"
              }
              children={
                <>
                  <div className="chainsContainer">
                    {uData && uData?.length
                      ? uData?.map((item, index) => {
                          return (
                            <ul className="chain">
                              <span>
                                <span>{item?.chain_name}</span>
                                <span>{ChevDown}</span>
                              </span>
                              <li>
                                {!item?.courses ? (
                                  <Loader />
                                ) : item?.courses?.length ? (
                                  item?.courses?.map((c_item) => {
                                    return (
                                      <ul>
                                        <li>
                                          <span>
                                            <span>{c_item?.course_name}</span>
                                            <button
                                              onClick={(e) => {
                                                return c_item?.subscribed ==
                                                  "no"
                                                  ? subscribe(
                                                      c_item?.course_id,
                                                      "course",
                                                      "yes"
                                                    )
                                                  : subscribe(
                                                      c_item?.course_id,
                                                      "course",
                                                      "no"
                                                    );
                                              }}
                                              className={
                                                c_item?.subscribed == "no"
                                                  ? "btn btn-success"
                                                  : "btn btn-danger"
                                              }
                                            >
                                              {c_item?.subscribed == "no"
                                                ? "Subscribe"
                                                : "UnSubscribe"}
                                            </button>
                                            <span>{ChevDown}</span>
                                          </span>
                                          <ul>
                                            {!c_item?.chapters ? (
                                              <Loader />
                                            ) : c_item?.chapters?.length ? (
                                              c_item?.chapters?.map(
                                                (s_item) => {
                                                  return (
                                                    <li>
                                                      <span>
                                                        <span>
                                                          {
                                                            s_item?.chapter_title
                                                          }
                                                        </span>
                                                        {c_item?.subscribed ==
                                                        "no" ? (
                                                          <button
                                                            onClick={() => {
                                                              return s_item?.subscribed ==
                                                                "no"
                                                                ? subscribe(
                                                                    s_item?.chapter_id,
                                                                    "chapter",
                                                                    "yes"
                                                                  )
                                                                : subscribe(
                                                                    s_item?.chapter_id,
                                                                    "chapter",
                                                                    "no"
                                                                  );
                                                            }}
                                                            className={
                                                              s_item?.subscribed ==
                                                              "no"
                                                                ? "btn btn-success"
                                                                : "btn btn-danger"
                                                            }
                                                          >
                                                            {s_item?.subscribed ==
                                                            "no"
                                                              ? "Subscribe"
                                                              : "UnSubscribe"}
                                                          </button>
                                                        ) : null}
                                                      </span>
                                                    </li>
                                                  );
                                                }
                                              )
                                            ) : null}
                                          </ul>
                                        </li>
                                      </ul>
                                    );
                                  })
                                ) : null}
                              </li>
                            </ul>
                          );
                        })
                      : null}
                  </div>
                </>
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default DashLogs;
