import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../components/modal";
import TableLayout from "../../components/table";
import DefaultLayout from "../../layouts/defaultLayout";
import "../subjects/style.css";
import "./style.css";

import ReactSelect from "react-select";
import makeAnimated from 'react-select/animated';
import { addition } from "../../assets/svgIcons";
import { Input } from "../../components/form-elements";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
const CardsOrders = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newSrc, setNewSrc] = useState(null);
  const [srcObj, setSrcObj] = useState(null);
  const [file, setFile] = useState(null);
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [AssignLoading, setAssignLoading] = useState(false);
  let [choosedDocs, setChoosedDocs] = useState([]);
  const [Courses, setCourses] = useState([]);
  const [Chapters, setChapters] = useState([]);
  const [newCardData, setNewCardData] = useState({
    admin_id: userData?.user_id,
    access_token: userData?.access_token,
    order_title: null,
    count: null,
    sub_type: "course", //'course', 'chapter'
    courses_ids: null,
  });
  // setNewCardData
  const [createCards, setCreateCards] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [banId, setBanId] = useState(false);
  const [templateDesign, setTemplateDesign] = useState(null);
  const [data, setData] = useState(false);
  const [uData, setUData] = useState(false);
  const [template, setTemplate] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [preview, setPreviewCards] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [card, setCard] = useState(null);

  const animatedComponents = makeAnimated();
  const headers = [
    {
      label: "ID",
      dataIndex: "cards_order_id",
      sort: true,
      search: true,
    },
    {
      label: "order_title",
      dataIndex: "order_title",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return <div>{row?.order_title}</div>;
      },
    },
    // student_data
    {
      label: "count",
      dataIndex: "count",
      sort: true,
      search: true,
    },
    {
      label: "Create Date",
      dataIndex: "created_date",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return <div>{row?.created_date}</div>;
      },
    },
    {
      label: "Used Date",
      dataIndex: "used_date",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return <div>{row?.used_date}</div>;
      },
    },
    {
      label: "created by admin name",
      dataIndex: "created_by_admin_name",
      search: true,
      sort: true,
    },
    {
      label: "Actions",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        return (
          <>
            <button
              className="btn btn-primary"
              onClick={() => {
                setPreviewCards(true);
                setCardId(row?.cards_order_id);
              }}
            >
              Preview
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                navigate("/CardsList", {
                  state: {
                    cardId: row,
                  },
                });
              }}
            >
              List
            </button>
          </>
        );
      },
    },
  ];

  const getTemplates = async () => {
    const univsAndGradesChainsCoursesChapters = await axios.post(
      BASE_URL +
        "subscriptions/cards/design_templets/select_design_templets.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );
    setTemplates(univsAndGradesChainsCoursesChapters?.data?.message);
  };
  useEffect(() => {
    getTemplates();
  }, []);

  const printCards = () => {
    if (template) {
      try {
        localStorage.setItem("cardsToken", template);
        window.open("/CardsPreviewPrint", "_blanck");
        setTemplateDesign(JSON.parse(atob(template)));
      } catch (e) {}
    } else {
      setCard(null);
    }
  };

  useEffect(() => {
    if (!preview) {
      setCard(null);
    }
  }, [preview]);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "subscriptions/cards/select_cards_order.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const createTemplate = async () => {
    setLoading(true);

    try {
      const data_send = {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
        ...newCardData,
        courses_ids: newCardData?.courses_ids
          ?.map((item) => item?.value)
          ?.join("**"),
      };
      await axios
        .post(
          BASE_URL + "subscriptions/cards/create_cards.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          if (res.data.status == 'success') {
            toast.success(res.data.message);
            setCreateCards(false);
            setNewCardData({
              admin_id: userData?.user_id,
              access_token: userData?.access_token,
              order_title: null,
              count: null,
              sub_type: "course", //'course', 'chapter'
              courses_ids: [],
            });
            fetchData();
          } else if (res.data.status == 'error') {
            toast.error(res.data.message);
          } else {
            toast.error("Something Went Error");
          }
        });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const getCard = async () => {
    const univsAndGradesChainsCourses = await axios.post(
      BASE_URL + "subscriptions/cards/select_order_cards_list.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
        order_id: cardId,
      }
    );
    if (univsAndGradesChainsCourses?.data?.message?.length) {
      setCard(univsAndGradesChainsCourses?.data?.message);
    } else {
      toast.error("No Cards In THis Order");
    }
  };
  useEffect(() => {
    if (cardId) {
      getCard();
    }
  }, [cardId]);

  const getCourses = async () => {
    const univsAndGradesChainsCourses = await axios.post(
      BASE_URL + "subscriptions/cards/select_courses.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );
    setCourses(univsAndGradesChainsCourses?.data?.message);
  };
  const getChapters = async () => {
    const univsAndGradesChainsCoursesChapters = await axios.post(
      BASE_URL + "subscriptions/cards/select_chapters.php",
      {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );

    setChapters(univsAndGradesChainsCoursesChapters?.data?.message);
  };
  useEffect(() => {
    setNewCardData({ ...newCardData, courses_ids: [] });
    if (newCardData?.sub_type == "course") {
      getCourses();
    } else if (newCardData?.sub_type == "chapter") {
      getChapters();
    }
  }, [newCardData?.sub_type]);
  useEffect(() => {
    if (srcObj) {
      setNewSrc(URL.createObjectURL(srcObj));
      setFile(srcObj);
    }
  }, [srcObj]);
  useEffect(() => {
    console.log(templateDesign);
  }, [templateDesign]);
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <button
              className="btn btn-primary"
              onClick={() => setCreateCards(true)}
            >
              <span>{addition}</span>
              <span>Create Cards</span>
            </button>
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />
          </div>
        }
      />
      <Modal
        open={createCards}
        toggle={setCreateCards}
        headerTitle={"Create Cards"}
        children={
          <>
            <div className="inputField">
              <label htmlFor="">Order Title</label>
              <Input
                type={"text"}
                id={"order_title"}
                name={"order_title"}
                value={newCardData?.order_title}
                onChange={(e) =>
                  setNewCardData({
                    ...newCardData,
                    order_title: e.value,
                  })
                }
              />
            </div>
            <div className="inputField">
              <label htmlFor="">Count</label>
              <input
                type={"number"}
                id={"count"}
                onWheel={(e) => e.target.blur()}
                name={"count"}
                value={newCardData?.count}
                onChange={(e) =>
                  setNewCardData({
                    ...newCardData,
                    count: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label htmlFor="video_title">Subscription Type</label>
              <select
                name=""
                id=""
                value={newCardData?.sub_type}
                onChange={(e) => {
                  setNewCardData({
                    ...newCardData,
                    sub_type: e.target.value,
                  });
                }}
              >
                <option value="course">course</option>
                <option value="chapter">chapter</option>
              </select>
            </div>
            <div>
              <label htmlFor="video_title">Course</label>
              <ReactSelect
                isMulti
                name="colors"
                options={
                  newCardData?.sub_type == "course"
                    ? Courses &&
                      Courses?.length &&
                      Array.isArray(Courses) &&
                      Courses.map((item) => ({
                        value: item?.course_id,
                        label: item?.course_name,
                      }))
                    : Chapters &&
                      Chapters?.length &&
                      Array.isArray(Chapters) &&
                      Chapters.map((item) => ({
                        value: item?.chapter_id,
                        label: item?.chapter_name,
                      }))
                }
                components={animatedComponents}
                className="basic-multi-select"
                classNamePrefix="select"
                value={newCardData?.courses_ids}
                onChange={(e) => {
                  setNewCardData({ ...newCardData, courses_ids: e });
                }}
              />
            </div>
            <button
              className="btn btn-primary"
              onClick={() => createTemplate()}
            >
              Create
            </button>
          </>
        }
      />
      <Modal
        open={preview && card && card?.length}
        toggle={setPreviewCards}
        headerTitle={"Preview Cards"}
        children={
          <form onSubmit={(e) => e.preventDefault()}>
            <label htmlFor="video_title">Select Image</label>
            <div>
              <input
                type="file"
                name="cardImage"
                onChange={(e) => setSrcObj(e.target?.files[0])}
              />
              <br />
              <div
                className="cardImage"
                style={{
                  backgroundImage: `url(${newSrc})`,
                  backgroundSize: '100% 100%',
                  margin: '5px',
                  width: "300px",
                  height: newSrc ? "200px" : "0px",
                  display: 'inline-block',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
            </div>
            <div>
              <label htmlFor="video_title">Select Template</label>
              <select
                name=""
                id=""
                value={template}
                onChange={(e) => setTemplate(e.target.value)}
              >
                {templates?.map((item) => {
                  return (
                    <option
                      value={btoa(
                        JSON.stringify({
                          pos: {
                            cardCode: {
                              top: item?.code_top,
                              left: item?.code_left,
                              font: item?.code_fontsize,
                            },
                            cardId: {
                              top: item?.id_top,
                              left: item?.id_left,
                              font: item?.id_fontsize,
                            },
                            cardImage: {
                              width: item?.card_width,
                              height: item?.card_height,
                            },
                          },
                          src: newSrc ? newSrc : item?.image_url,
                          cards: card,
                        })
                      )}
                    >
                      {item?.templet_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <button
              className="btn btn-primary"
              onClick={() => printCards()}
              style={{ margin: "10px 0" }}
            >
              Print
            </button>
            <input
              type="reset"
              value="Reset"
              className="btn btn-danger"
              onClick={() => {
                setNewSrc(null);
                setFile(null);
              }}
            />

            {/* {
              templateDesign && card && card?.length ? <div className="CardsPreview">
                {card?.map((card) => {
                  return (
                    <div
                      className="cardImage"
                      style={{
                        backgroundImage: `url(${templateDesign?.src})`,
                        backgroundSize: '100% 100%',
                        margin: '5px',
                        width: `${templateDesign?.pos?.cardImage?.width + 'px'}`,
                        height: `${templateDesign?.pos?.cardImage?.height + 'px'}`,
                        display: 'inline-block',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <div>
                        <p
                          style={{
                            letterSpacing: '8px',
                            fontWeight: 'bold',
                            padding: '3px 4px',
                            fontSize: `${templateDesign?.pos?.cardCode?.font + "px"}`,
                            position: 'absolute',
                            margin: `${templateDesign?.pos?.cardCode?.top + "px"} 0 0 ${templateDesign?.pos?.cardCode?.left + "px"
                              }`,
                          }}
                        >
                          {card?.card_code}
                        </p>

                        <p
                          style={{
                            color: 'black',
                            fontSize: `${templateDesign?.pos?.cardId?.font + "px"}`,
                            position: 'absolute',
                            letterSpacing: '3px',
                            margin: `${templateDesign?.pos?.cardId?.top + "px"} 0 0 ${templateDesign?.pos?.cardId?.left + "px"
                              }`,
                            // width: '157px',
                            width: "fit-content",
                            textAlign: 'center',
                          }}
                        >
                          {card?.card_id}

                        </p>
                      </div>
                    </div>
                  );
                })}
              </div> : null
            } */}
          </form>
        }
      />
    </div>
  );
};

export default CardsOrders;
