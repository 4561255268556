import React, { useState } from 'react';
import './enterexceldata.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AiOutlineLoading } from 'react-icons/ai';
import DefaultLayout from '../../layouts/defaultLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants';
const EnterExcelData = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [inputData, setInputData] = useState('');
  const [entLoading, setEntLoading] = useState(false);
  const enterDataFunc = () => {
    if (inputData == '') {
      toast.warn('First Enter Data');
      return;
    }
    setEntLoading(true);
    let words = inputData.replace(/\n/g, ",");
    let WordArr = words.split(",");
    let newArr = [];
    for (let i = 0; i < WordArr.length; i++) {
      if (WordArr[i] !== "") {
        newArr.push(WordArr[i].trim(" "));
      }
    }
    let users = newArr.join(",");
    const data_send = {
      sub_no: location?.state?.course_id,
      users,
    };
    axios
      .post(BASE_URL + "add_auto_sub_student.php", JSON.stringify(data_send))
      .then((res) => {
        if (res.data > 0) {
          toast.success(`${res.data} has been uploaded`);
        } else if (res.data == 0) {
          toast.error(`${res.data} has been uploaded`);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((err) => null)
      .finally(() => {
        setEntLoading(false);
        setInputData('');
      });
  };
  if (!location?.state?.course_id) {
    navigate(-1);
  }
  return (
    <div id="CreateCourses">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="createcourses enter_data_page">
              <div className="enterinput">
                <label htmlFor="">Enter Data Here</label>
                <textarea
                  value={inputData}
                  onChange={(e) => {
                    setInputData(e.target.value);
                  }}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
                <div className="ent_div">
                  <button
                    disabled={entLoading}
                    style={{ cursor: entLoading ? 'no-drop' : 'pointer' }}
                    onClick={() => {
                      enterDataFunc();
                    }}
                  >
                    {entLoading ? <AiOutlineLoading /> : 'Enter'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default EnterExcelData;
