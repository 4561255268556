/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import BurdCrumbs from "../../components/burdCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Loader } from "rsuite";
import { ChevDown } from "../../assets/svgIcons";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
const Subjects = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const univ_id = location?.state?.university_id;
  const stu_id = location?.state?.student_id;
  const [searchHeaderKet, setSearchHeaderKey] = useState('');

  const [data, setData] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const subscribe = async (course_id, sub_type, type) => {
    await axios
      .post(
        type == "yes"
          ? BASE_URL + "subscriptions/add_subscription.php"
          : type == "no"
          ? BASE_URL + "subscriptions/end_subscription.php"
          : "",
        {
          student_id: stu_id,
          course_id: course_id,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          sub_type: sub_type, // course - chapter
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
      });
    await fetchData();
  };

  const fetchData = async () => {
    const response = await axios.post(
      BASE_URL + "students/select_student_material.php",
      {
        student_id: stu_id,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );

    setData(response?.data?.message);
  };
  useEffect(() => {
    if (
      document.querySelectorAll('.chain') &&
      document.querySelectorAll('.chain')?.length
    ) {
      document.querySelectorAll('.chain').forEach((value, index, array) => {
        if (value[index] && value[index].querySelector("li")) {
          value[index].querySelector("li").style.height = 0;
          value[index].style.overflow = "hidden";
        }
        value.querySelector("span").addEventListener('click', (e) => {
          const valueLength = value.querySelector("li");
          if (parseInt(valueLength.style.height.split("px")[0])) {
            valueLength.style.height = 0;
            valueLength.style.overflow = "hidden";
          } else {
            valueLength.style.height = valueLength?.scrollHeight + "px";
            valueLength.style.overflow = "auto";
          }
        });
      });

      // ==============================================
      document
        .querySelectorAll('ul.chain > li > ul > li ')
        .forEach((value, index, array) => {
          value.querySelector("span").addEventListener('click', (e) => {
            const valueLength = value.querySelector("ul");
            console.log(valueLength.style.height);
            if (parseInt(valueLength.style.height.split("px")[0])) {
              valueLength.style.height = 0;
              valueLength.style.overflow = "hidden";
            } else {
              valueLength.style.height = valueLength?.scrollHeight + "px";
              valueLength.style.overflow = "auto";
            }
          });
        });
    }
  }, [document.querySelectorAll('.chain'), data, subscribe, fetchData]);

  if (!univ_id || !stu_id) {
    navigate(-1);
  }
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <BurdCrumbs title={"Subjects"} />
            {!data ? (
              <Loader />
            ) : data?.length ? (
              <div className="chainsContainer">
                {data?.map((item, index) => {
                  return (
                    <ul className="chain">
                      <span>
                        <span>{item?.chain_name}</span>
                        <span>{ChevDown}</span>
                      </span>
                      <li>
                        {!item?.courses ? (
                          <Loader />
                        ) : item?.courses?.length ? (
                          item?.courses?.map((c_item) => {
                            return (
                              <ul>
                                <li>
                                  <span>
                                    <span>{c_item?.course_name}</span>
                                    <button
                                      onClick={() => {
                                        return c_item?.subscribed == "no"
                                          ? subscribe(
                                              c_item?.course_id,
                                              "course",
                                              "yes"
                                            )
                                          : subscribe(
                                              c_item?.course_id,
                                              "course",
                                              "no"
                                            );
                                      }}
                                      className={
                                        c_item?.subscribed == "no"
                                          ? "btn btn-success"
                                          : "btn btn-danger"
                                      }
                                    >
                                      {c_item?.subscribed == "no"
                                        ? "Subscribe"
                                        : "UnSubscribe"}
                                    </button>
                                    <span>{ChevDown}</span>
                                  </span>
                                  <ul>
                                    {!c_item?.chapters ? (
                                      <Loader />
                                    ) : c_item?.chapters?.length ? (
                                      c_item?.chapters?.map((s_item) => {
                                        return (
                                          <li>
                                            <span>
                                              <span>
                                                {s_item?.chapter_title}
                                              </span>
                                              {c_item?.subscribed == "no" ? (
                                                <button
                                                  onClick={() => {
                                                    return s_item?.subscribed ==
                                                      "no"
                                                      ? subscribe(
                                                          s_item?.chapter_id,
                                                          "chapter",
                                                          "yes"
                                                        )
                                                      : subscribe(
                                                          s_item?.chapter_id,
                                                          "chapter",
                                                          "no"
                                                        );
                                                  }}
                                                  className={
                                                    s_item?.subscribed == "no"
                                                      ? "btn btn-success"
                                                      : "btn btn-danger"
                                                  }
                                                >
                                                  {s_item?.subscribed == "no"
                                                    ? "Subscribe"
                                                    : "UnSubscribe"}
                                                </button>
                                              ) : null}
                                            </span>
                                          </li>
                                        );
                                      })
                                    ) : null}
                                  </ul>
                                </li>
                              </ul>
                            );
                          })
                        ) : null}
                      </li>
                    </ul>
                  );
                })}
              </div>
            ) : null}
          </div>
        }
      />
    </div>
  );
};

export default Subjects;
