import React, { useEffect, useState } from "react";
import "./style.css";
import { Loader } from "rsuite";
import DefaultLayout from "../../layouts/defaultLayout";
// import { questions } from './data';

import { useLocation, useSearchParams } from "react-router-dom";
import { ActionList, DeleteIcon } from "../../assets/svgIcons";
import Modal from "../../components/modal";
import { Input } from "../../components/form-elements";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
import { toast } from "react-toastify";
import Delete from "../chains/delete";

const QuizzezQuestion = () => {
  const location = useLocation();
  const [answer, setAnswer] = useState({});
  const [url] = useSearchParams();
  const [questions, setQuestions] = useState([]);
  const [data, setData] = useState([]);
  const [newAnswer, setNewAnswer] = useState({});
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false); // State for edit modal
  const [openShowHide, setOpenShowHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionList, setActionList] = useState();
  const [editQuestion, setEditQuestion] = useState({});
  const [newQuestion, setNewQuestion] = useState({
    question_title: "",
    answers: [{ answer_title: "" }],
  });
  const transformApiQuestionData = (apiData) => {
    return apiData.map((question, index) => {
      return {
        id: question.question_id,
        question_text: question.question_text,
        question_image: question.question_image,
        hidden: question.hidden,
        answers: question.question_answers.map((answer) => {
          return {
            answer_id: index,
            answer_text: answer.answer_text,
            answer_check: answer.answer_check,
          };
        }),
      };
    });
  };
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(-1);
  useEffect(() => {
    if (data && data?.length) {
      setQuestions(transformApiQuestionData(data));
    }
  }, [data]);
  // Function to set the correct answer
  const handleSetCorrectAnswer = (index) => {
    if (correctAnswerIndex === index) {
      setCorrectAnswerIndex(-1); // Deselect the already selected answer
    } else {
      setCorrectAnswerIndex(index); // Select the correct answer
    }
  };

  // Function to check if the current answer is marked as correct
  const isCorrectAnswer = (index) => {
    return correctAnswerIndex === index;
  };

  // Function to add a new question
  const handleAddQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      e.preventDefault();
      setLoading(true);
      let imageUpload = null;
      const form = e.target;
      if (e.target.image?.files?.length) {
        const formData = new FormData(form);
        imageUpload = await axios.post(
          BASE_URL + "item_img_uploader.php",
          formData
        );
      }
      const response = await axios.post(
        BASE_URL +
          (url?.get("type") == "video"
            ? "video_questions/insert_qustion.php"
            : "quiz/insert_qustion.php"),
        {
          quiz_id: location?.state?.quiz?.quiz_id,
          video_id: url?.get("video_id"),
          course_id: location?.state?.course_id,
          question_text: newQuestion?.title,
          question_image: imageUpload?.data?.message,
          question_answers: newQuestion?.answers
            ?.map((item) => item?.answer_title)
            ?.join("//CAMP//"),
          question_valid_answer:
            newQuestion?.answers[correctAnswerIndex]?.answer_title,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        setOpenAdd(false);
        form.reset();
        setLoading(false);
        setNewQuestion({
          question_title: "",
          answers: [{ answer_title: "" }],
        });
        fetchData();
        setCorrectAnswerIndex(-1); // Deselect the already selected answer
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };

  const handleEditQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    e.preventDefault();
    let imageUpload = {};
    if (e.target?.image.files && e.target?.image.files?.length) {
      setLoading(true);
      const formData = new FormData(form);
      imageUpload = await axios.post(
        BASE_URL + "item_img_uploader.php",
        formData
      );
    }
    const response = await axios.post(
      BASE_URL +
        (url?.get("type") == "video"
          ? "video_questions/update_qustion.php"
          : "quiz/update_qustion.php"),
      {
        quiz_id: location?.state?.quiz?.quiz_id,
        video_id: url?.get("video_id"),
        course_id: location?.state?.course_id,
        question_id: editQuestion?.id,
        question_text: editQuestion?.question_text,
        question_image: imageUpload?.data?.message
          ? imageUpload?.data?.message
          : editQuestion?.question_image,
        question_answers: editQuestion?.answers
          ?.map((item) => item?.answer_text)
          ?.join("//CAMP//"),
        question_valid_answer:
          editQuestion?.answers[correctAnswerIndex]?.answer_text,
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );
    if (response.data?.status == "success") {
      toast.success(response.data?.message);
      setOpenEdit(false);
      form.reset();
      setLoading(false);
      setEditQuestion({});
      fetchData();
      setCorrectAnswerIndex(-1); // Deselect the already selected answer
    } else {
      setLoading(false);
      toast.error(response.data?.message);
    }

    // setShowAdd(false);
  };
  const handleShowHideQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(openShowHide);
    const response = await axios.post(
      BASE_URL +
        (url?.get("type") == "video"
          ? "video_questions/show_hide_questions.php"
          : "quiz/show_hide_questions.php"),
      {
        quiz_id: location?.state?.quiz?.quiz_id,
        video_id: url?.get("video_id"),
        question_id: openShowHide?.id,
        hidden: openShowHide?.hidden == "0" ? "1" : "0",

        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      }
    );
    if (response.data?.status == "success") {
      toast.success(response.data?.message);
      setOpenShowHide(false);
      setLoading(false);

      fetchData();
      setCorrectAnswerIndex(-1); // Deselect the already selected answer
    } else {
      setLoading(false);
      toast.error(response.data?.message);
    }
  };

  // Function to delete an answer
  const handleDeleteAnswer = (index) => {
    const updatedAnswers = [...newQuestion.answers];
    updatedAnswers.splice(index, 1);
    setNewQuestion({
      ...newQuestion,
      answers: updatedAnswers,
    });
  };

  useEffect(() => {}, [newQuestion]);
  const [deleted, setDeleted] = useState(false);

  // Function to add a new answer
  const handleAddAnswer = () => {
    setNewQuestion({
      ...newQuestion,
      answers: [...newQuestion.answers, { answer_title: "", id: 0 }],
    });
  };

  // Function to fetch data
  const fetchData = async () => {
    // console.log(url?.get("type"));
    try {
      const response = await axios.post(
        BASE_URL +
          (url?.get("type") == "video"
            ? "video_questions/select_questions.php"
            : "quiz/select_questions.php"),
        {
          course_id: location?.state?.course_id,
          quiz_id: location?.state?.quiz?.quiz_id,
          video_id: url?.get("video_id"),
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );
      setData(response?.data?.message);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="home">
      <DefaultLayout
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span>
                Quiz
                {" - ( " + location?.state?.quiz?.quiz_name + " ) Questions"}
              </span>
              <button
                className="btn btn-primary"
                onClick={() => setOpenAdd(true)}
              >
                <span>Add Question</span>
              </button>
            </h4>
            <div className="all_questions">
              {!questions ? (
                <Loader size={"lg"} />
              ) : questions?.length ? (
                questions?.map((q_item, q_index) => {
                  return (
                    <div className="all_question">
                      <div className="question">
                        <h3 className="question_title">
                          {q_item?.question_text}
                        </h3>
                        <img
                          src={q_item?.question_image}
                          alt=""
                          style={{ width: "150px" }}
                        />
                        <div className="actionListContainer">
                          <span
                            className="action_list_icon"
                            onClick={() =>
                              setActionList(
                                actionList?.id == q_item?.id ? {} : q_item
                              )
                            }
                          >
                            {ActionList}
                          </span>
                          {actionList?.id == q_item?.id ? (
                            <div className="actionList">
                              <button
                                className={
                                  q_item?.hidden == "1"
                                    ? "btn btn-success"
                                    : "btn btn-danger"
                                }
                                onClick={() => {
                                  setOpenShowHide(q_item);
                                }}
                              >
                                {q_item?.hidden == "1" ? "Show" : "Hide"}
                              </button>
                              <button
                                className="btn btn-danger"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                  setDeleted({
                                    value: q_item?.id,
                                    table: url?.get("type") == "video" ? "video_questions" : "Questions",
                                    column: "question_id",
                                  });
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="btn btn-success"
                                onClick={() => {
                                  setOpenEdit(true);
                                  setEditQuestion(q_item);
                                  let correctedIndex = -1;
                                  q_item?.answers?.map((a_item, a_index) => {
                                    if (a_item?.answer_check) {
                                      correctedIndex = a_index;
                                      return;
                                    }
                                  });
                                  setCorrectAnswerIndex(correctedIndex);
                                }}
                              >
                                Edit
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="counter">
                        <hr />
                        <span>
                          Question {q_index + 1 + " Of " + questions?.length}
                        </span>
                        <hr />
                      </div>
                      <div className="answers">
                        {q_item?.answers &&
                        q_item?.answers &&
                        Array.isArray(q_item?.answers)
                          ? q_item?.answers?.map((a_item, a_index) => {
                              return (
                                <div className="answer" key={a_index}>
                                  {/* <input
                                    type="radio"
                                    id={`${q_index}_answer`}
                                    name={`${q_index}_answer`}
                                    checked={
                                      trueAnswer?.answer_id == a_item?.id &&
                                      trueAnswer?.question_id == q_item?.id
                                        ? true
                                        : a_item?.check
                                    }
                                    value={a_item?.id}
                                    onFocus={() =>
                                      setTrueAnswer({
                                        answer_id: a_item?.id,
                                        text: a_item?.answer_title,
                                        question_id: q_item?.id,
                                      })
                                    }
                                    onBlur={() => setTrueAnswer({})}
                                    onChange={(e) => {
                                      setTrueAnswer({
                                        ...answer,
                                        trueAnswer: e.target?.checked,
                                      });
                                    }}
                                  /> */}
                                  {/* <input
                                    type="text"
                                    style={{
                                      color: a_item?.answer_check
                                        ? "#3d1580"
                                        : "#000000",
                                    }}
                                    className={
                                      answer?.answer_id == a_item?.id &&
                                      answer?.question_id == q_item?.id
                                        ? 'answer_text_changer'
                                        : ""
                                    }
                                    value={
                                      answer?.answer_id == a_item?.id &&
                                      answer?.question_id == q_item?.id
                                        ? answer?.text
                                        : a_item?.answer_text
                                    }
                                    onFocus={() =>
                                      setAnswer({
                                        answer_id: a_item?.id,
                                        text: a_item?.answer_title,
                                        question_id: q_item?.id,
                                      })
                                    }
                                    onBlur={() => setAnswer({})}
                                    onChange={(e) =>
                                      setAnswer({
                                        ...answer,
                                        text: e.target?.value,
                                      })
                                    }
                                  /> */}
                                  {/* <span
                                    className="delete-answer-icon"
                                    onClick={() => handleDeleteAnswer(a_index)}
                                  >
                                    {DeleteIcon}
                                  </span> */}
                                  <span
                                    style={{
                                      color: a_item?.answer_check
                                        ? "#3d1580"
                                        : "#000000",
                                      fontSize: "20px",
                                      fontWeight: a_item?.answer_check
                                        ? 900
                                        : 300,
                                    }}
                                  >
                                    {a_item?.answer_text}
                                  </span>
                                </div>
                              );
                            })
                          : null}
                      </div>
                      {/* <button
                        className="btn btn-primary"
                        style={{ width: "fit-content" }}
                        onClick={() => {
                          setNewAnswer(q_index + 1);
                        }}
                      >
                        Add Answer
                      </button> */}
                      {q_index + 1 == newAnswer ? (
                        <div className="new_answers">
                          <div className="answer">
                            <label>New Answer</label>
                            <input
                              type="text"
                              className="answer_text_changer"
                              style={{ width: "100%" }}
                              onBlur={() => setNewAnswer(false)}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <h4>No Questions</h4>
              )}
            </div>
          </div>
        }
      />

      {/* Edit -> Question Modal */}
      <Modal
        headerTitle={"Edit Question"}
        open={openEdit}
        toggle={setOpenEdit}
        children={
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              handleEditQuestion(e);
            }}
          >
            <input
              className="MR_input_form_element"
              type="text"
              placeholder={`Enter Title`}
              value={editQuestion?.question_text}
              onChange={(e) => {
                setEditQuestion({
                  ...editQuestion,
                  question_text: e.target.value,
                });
              }}
            />
            <div className="inputField">
              <label htmlFor="image">Image URL</label>
              <Input
                type={"file"}
                id={"image"}
                name={"image"}
                showImage={true}
                onChange={() => null}
              />
            </div>
            Answers
            {editQuestion?.answers?.map((answer, index) => (
              <div className="rowDiv">
                <input
                  type="checkbox"
                  checked={isCorrectAnswer(index)}
                  onChange={() => handleSetCorrectAnswer(index)}
                />{" "}
                <input
                  key={index}
                  className="MR_input_form_element"
                  type="text"
                  placeholder={`Enter answer ${index + 1}`}
                  value={answer.answer_text}
                  onChange={(e) => {
                    const updatedAnswers = [...editQuestion.answers];
                    updatedAnswers[index].answer_text = e.target.value;
                    setEditQuestion({
                      ...editQuestion,
                      answers: updatedAnswers,
                    });
                  }}
                />
                <span
                  className="btn btn-danger"
                  onClick={() => {
                    const updatedAnswers = [...editQuestion.answers];
                    updatedAnswers.splice(index, 1);
                    setEditQuestion({
                      ...editQuestion,
                      answers: updatedAnswers,
                    });
                  }}
                >
                  -
                </span>
              </div>
            ))}
            <div style={{ margin: "20px 0" }}>
              <span
                className="btn btn-success"
                onClick={() =>
                  setEditQuestion({
                    ...editQuestion,
                    answers: [...editQuestion.answers, { answer_text: "" }],
                  })
                }
              >
                Add New Answer
              </span>
            </div>
            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
      {/* Edit -> Question Modal */}
      <Modal
        headerTitle={"Add Question"}
        open={openAdd}
        toggle={setOpenAdd}
        children={
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              handleAddQuestion(e);
            }}
          >
            <input
              className="MR_input_form_element"
              type="text"
              placeholder={`Enter Title`}
              value={newQuestion?.title}
              onChange={(e) => {
                setNewQuestion({
                  ...newQuestion,
                  title: e.target.value,
                });
              }}
            />
            <div className="inputField">
              <label htmlFor="image">Image URL</label>
              <Input
                type={"file"}
                id={"image"}
                name={"image"}
                showImage={true}
                onChange={() => null}
              />
            </div>
            {newQuestion.answers.map((answer, index) => (
              <div className="rowDiv">
                <input
                  type="checkbox"
                  checked={isCorrectAnswer(index)}
                  onChange={() => handleSetCorrectAnswer(index)}
                />{" "}
                <input
                  key={index}
                  className="MR_input_form_element"
                  type="text"
                  placeholder={`Enter answer ${index + 1}`}
                  value={answer.answer_title}
                  onChange={(e) => {
                    const updatedAnswers = [...newQuestion.answers];
                    updatedAnswers[index].answer_title = e.target.value;
                    setNewQuestion({
                      ...newQuestion,
                      answers: updatedAnswers,
                    });
                  }}
                />
                <span
                  className="btn btn-danger"
                  onClick={() => handleDeleteAnswer(index)}
                >
                  -
                </span>
              </div>
            ))}
            <div style={{ margin: "20px 0" }}>
              <span
                className="btn btn-success"
                onClick={() =>
                  setNewQuestion({
                    ...newQuestion,
                    answers: [...newQuestion.answers, { answer_title: "" }],
                  })
                }
              >
                Add New Answer
              </span>
            </div>
            <button className="btn btn-success">
              {!loading ? "Save" : <Loader />}
            </button>
          </form>
        }
      />
      {/* Hide - Show -> Question Modal */}
      <Modal
        headerTitle={"Hide -> Question"}
        open={openShowHide}
        toggle={setOpenShowHide}
        children={
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              handleShowHideQuestion(e);
            }}
          >
            <h4>Are You Sure ..?</h4>
            <button className="btn btn-success">
              {!loading ? "Hide" : <Loader />}
            </button>
          </form>
        }
      />
      <Delete data={deleted} setData={setDeleted} fetchData={fetchData} />
    </div>
  );
};

export default QuizzezQuestion;
