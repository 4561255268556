import React from 'react'

const AppPermissions = () => {
  return (
    <div>
      AppPermissions
    </div>
  )
}

export default AppPermissions
