import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import TableLayout from "../../components/table";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineClose, AiOutlineLoading } from 'react-icons/ai';
// import './phonelist.css';
import { addition } from "../../assets/svgIcons";
import Modal from "../../components/modal";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
const ChapterPhoneList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const course_id = location?.state?.chapter_id;
  // location
  const [rowData, setRowData] = useState({});
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [phones, setPhones] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [inputData, setInputData] = useState('');
  const [entLoading, setEntLoading] = useState(false);
  const getData = () => {
    const data_send = {
      sub_no: course_id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
      type: "chapter",
    };
    axios
      .post(
        BASE_URL + "auto_sub/select_auto_sub_phone.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (Array.isArray(res.data.message)) {
          setPhones(res.data.message);
        }
      })
      .catch((err) => null)
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDeletePhone = () => {
    setDelLoading(true);
    const data_send = {
      id: rowData.id,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
      type: "chapter",
    };
    axios
      .post(
        BASE_URL + "auto_sub/delete_auto_sub_no.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.data.status == 'success') {
          toast.success(res.data.message);
          getData();
          setShowDeleteModal(false);
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .finally(() => {
        setDelLoading(false);
      });
  };
  const handleWhatsCon = (phone) => {
    let msg =
      "ازى حضرتك كنت حابب أعرف من حضرتك ليه مسجلتش فى التطبيق لغاية دلوقتى";
    let phoneWithCountryCode = `20${phone}`;

    if (msg) {
      let url =
        "whatsapp://send?text=" + msg + "&phone=" + phoneWithCountryCode;
      window.open(url, "_blank");
    } else {
      alert("Please insert message to send");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const headers = [
    {
      label: 'Phone Number',
      dataIndex: "phone",
      search: true,
      sort: true,
    },
    {
      label: "Status",
      type: 'children',
      sort: true,
      children: ({ headers, row }) => {
        return (
          <div>
            {row.user_registered == true ? (
              <div>
                <img
                  style={{ width: '50px' }}
                  src={require("../../assets/images/sub.png")}
                  alt=""
                />
              </div>
            ) : (
              <div>
                <img
                  onClick={() => {
                    handleWhatsCon(row.phone);
                  }}
                  style={{ width: '50px', cursor: 'pointer' }}
                  src={require("../../assets/images/whats.png")}
                  alt=""
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      label: "action",
      type: 'children',
      children: ({ headers, row }) => {
        return (
          <div>
            <button
              onClick={() => {
                setShowDeleteModal(true);
                setRowData(row);
              }}
              style={{
                backgroundColor: 'red',
                color: 'white',
                padding: '10px',
                width: '100px',
                borderRadius: '4px',
                textTransform: 'capitalize',
                cursor: 'pointer',
                border: 'none',
              }}
            >
              delete
            </button>
          </div>
        );
      },
    },
  ];

  const enterDataFunc = () => {
    if (inputData == '') {
      toast.warn('First Enter Data');
      return;
    }
    // console.log(inputData)
    setEntLoading(true);
    let words = inputData.replace(/\n/g, ",");
    let WordArr = words.split(",");
    let newArr = [];
    for (let i = 0; i < WordArr.length; i++) {
      if (WordArr[i] !== "") {
        newArr.push(WordArr[i].trim(" "));
      }
    }
    let users = newArr.join(",");
    const data_send = {
      sub_no: course_id,
      users,
      admin_id: userData?.user_id,
      access_token: userData?.access_token,
      type: "chapter",
    };
    axios
      .post(
        BASE_URL + "auto_sub/add_auto_sub_student.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        toast.info(res?.data);
        getData();
      })
      .catch((err) => null)
      .finally(() => {
        setEntLoading(false);
        setInputData('');
      });
  };
  if (!location?.state?.course_id) {
    navigate(-1);
  }
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span>Phone List</span>
              <button
                className="btn btn-primary"
                onClick={
                  () => setShowAdd(true)
                  // navigate("enterexceldata", {
                  //   state: { course_id: location?.state?.course_id },
                  // })
                }
              >
                <span>{addition}</span>
                <span>Add Numbers</span>
              </button>
            </h4>
            <div className="phonelisttablediv" style={{ textAlign: 'center' }}>
              <TableLayout
                headers={headers}
                data={phones}
                searchHeaderKet={searchHeaderKet}
              />
            </div>
          </div>
        }
      />

      <Modal
        headerTitle={"Delete Number"}
        open={showDeleteModal}
        toggle={setShowDeleteModal}
        children={
          <div className="">
            <div className="">
              <h4>
                ِAre You Sure That You Want to Delete This Phone Number (
                {rowData.phone})
              </h4>
              <div className="acts">
                <button
                  disabled={delLoading}
                  style={{ cursor: delLoading ? 'no-drop' : 'pointer' }}
                  onClick={() => {
                    handleDeletePhone();
                  }}
                >
                  {delLoading ? <AiOutlineLoading /> : 'yes'}
                </button>
                <button
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}
                >
                  no
                </button>
              </div>
            </div>
          </div>
        }
      />
      <Modal
        headerTitle={"Add Number"}
        open={showAdd}
        toggle={setShowAdd}
        children={
          <div className="createcourses enter_data_page">
            <div className="enterinput">
              <label htmlFor="">Enter Data Here</label>
              <textarea
                value={inputData}
                onChange={(e) => {
                  setInputData(e.target.value);
                }}
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
              <div className="ent_div">
                <button
                  disabled={entLoading}
                  style={{ cursor: entLoading ? 'no-drop' : 'pointer' }}
                  onClick={() => {
                    enterDataFunc();
                  }}
                >
                  {entLoading ? <AiOutlineLoading /> : 'Enter'}
                </button>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ChapterPhoneList;
