import "./App.css";
import "rsuite/dist/rsuite.min.css";
import "./media.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Students from "./pages/students";
import Universities from "./pages/unversities";
import Login from "./pages/auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Fragment, useEffect, useState } from "react";
import Subjects from "./pages/subjects";
import Courses from "./pages/Courses";
import { loggedIn, userData } from "./data/fake_login_data";
import Chains from "./pages/chains";
import ChainsCourses from "./pages/chainsCourses";
import ChainsCoursesChapters from "./pages/chainsCoursesChapters";
import ChapterContent from "./pages/chapterContent";
import PhoneList from "./pages/PhonesList/PhonesList";
import EnterExcelData from "./pages/EnterExcelData/EnterExcelData";
import StudentsChapters from "./pages/studentschapters";
import StudentsCourses from "./pages/studentsCourses";
import Quizzes from "./pages/quizzess";
import Posts from "./pages/posts";
import Banned from "./pages/students/banned";
import QuizzezQuestion from "./pages/quizzesQuestions";
import AdminRoles from "./pages/AdminRoles/AdminRoles";
import DashPermissions from "./pages/DashPermissions/DashPermissions";
import AppPermissions from "./pages/AppPermissions/AppPermissions";
import Roles from "./pages/Roles/Roles";
import EditRole from "./pages/EditRole/EditRole";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import About from "./pages/About/About";
import Alerts from "./pages/Alerts/Alerts";
import ChapterNotifications from "./pages/chapterNotification/ChapterNotifications";
import CourseNotifications from "./pages/courseNotification/CourseNotifications";
import ChapterPhoneList from "./pages/chapterNotification/ChapterPhoneList";
import Mainpost from "./pages/MainPosts/Mainpost";
import axios from "axios";
import { BASE_URL } from "./constants";
import Grades from "./pages/grades";
import Supscriptions from "./pages/Supscription";
import SupscriptionsType from "./pages/supscriptionType";
import GradeStudents from "./pages/gradeStudents";
import DashLogs from "./pages/DashLogs/DashLogs";
import Enquiry from "./pages/enquiries/cards";
import EnquiryStudents from "./pages/enquiries/students";
import UniversityStudents from "./pages/universityStudents";
import Settings from "./pages/Settings/Settings";
import StudentProfile from "./pages/studentProdile";
import DeviceLogs from "./pages/DeviceLogs/DeviceLogs";
import Topics from "./pages/chainsCoursesChaptersTopics";
import Reasons from "./pages/Reasons";
import Admins from "./pages/admins";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, getPermissions } from "./store/userData";
import NotFound from "./pages/notFound";
import { perms } from "./data/side_nav_data";
import DefaultLayout from "./layouts/defaultLayout";
import ChainsCoursesCards from "./pages/chainsCourses/chainsCoursesCards";
import Cards from "./pages/cards";
import CardsPreview from "./pages/cards/cardsPreview";
import CardsOrders from "./pages/cards/cardsOrders";
import CardsTempletes from "./pages/cards/cardsTempletes";
import CardsPreviewPrint from "./pages/cards/cardsPreviewToPrint";
import CardsList from "./pages/cards/cardsOrdersList";
function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchUserData());
  }, [location, navigate]);
  useEffect(() => {
    if (loggedIn) {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            BASE_URL + "universities/select_universitiesR.php",
            {
              admin_id: userData?.user_id,
              access_token: userData?.access_token,
            }
          );

          if (response.data.status == "out") {
            localStorage.removeItem("account");
            window.location.reload();
          }
        } catch (err) {}
      };
      fetchData();
    }
  }, [loggedIn]);
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {!loggedIn ? (
          <Route path="*" element={<Login />} />
        ) : (
          <>
            <Route
              path="/posts"
              element={
                perms?.includes("*12_1") || perms?.startsWith("12_1") ? (
                  <Posts />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/profile"
              element={
                perms?.includes("*7_6") || perms?.startsWith("7_6") ? (
                  <StudentProfile />
                ) : (
                  <NotFound />
                )
              }
            />{" "}
            <Route path="/settings">
              <Route
                path=""
                element={
                  perms?.includes("*15_1") || perms?.startsWith("15_1") ? (
                    <Settings />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="TElfeky Center"
                element={
                  perms?.includes("*15_1") || perms?.startsWith("15_1") ? (
                    <Settings />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route path="Dashboard" element={<div>Dashboard</div>} />
              <Route path="APP" element={<div>APP</div>} />
              {/* Cards */}
            </Route>
            <Route path="/cards" element={<Cards />} />
            <Route path="/Students">
              <Route
                path=""
                element={
                  perms?.includes("*7_1") || perms?.startsWith("7_1") ? (
                    <Students />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="banned"
                element={
                  perms?.includes("*10_1") || perms?.startsWith("10_1") ? (
                    <Banned />
                  ) : (
                    <NotFound />
                  )
                }
              />
            </Route>
            <Route path="/courses" element={<Courses />} />
            <Route path="/CardsOrders" element={<CardsOrders />} />
            <Route path="/CardsList" element={<CardsList />} />
            {/* CardsList */}
            {/* CardsOrders */}
            <Route path="/subjects" element={<Subjects />} />
            <Route path="/CardsPreview" element={<CardsPreview />} />
            <Route path="/chains">
              <Route path="" element={<Chains />} />
              <Route path="courses">
                <Route
                  path="ChainsCoursesCards"
                  element={<ChainsCoursesCards />}
                />
                <Route path="cournot" element={<CourseNotifications />} />
                <Route path="" element={<ChainsCourses />} />
                <Route path="phonelist">
                  <Route path="" element={<PhoneList />} />
                  <Route path="enterexceldata" element={<EnterExcelData />} />
                </Route>
                <Route path="subscriptions" element={<StudentsCourses />} />
                <Route path="quizzes">
                  <Route path="" element={<Quizzes />} />
                  <Route path="QuizzezQuestion" element={<QuizzezQuestion />} />
                  {/* QuizzezQuestion */}
                </Route>
                {/* quizzes */}
                <Route path="chapters">
                  <Route path="" element={<ChainsCoursesChapters />} />
                  <Route path="VidoeQuestion" element={<QuizzezQuestion />} />
                  <Route path="content" element={<ChapterContent />} />
                  <Route path="subscriptions" element={<StudentsChapters />} />
                  <Route path="chapnots" element={<ChapterNotifications />} />
                  <Route path="chanpphonelist" element={<ChapterPhoneList />} />
                  <Route path="Topics">
                  <Route path="content" element={<Topics />} />
                  </Route>
                  {/* Topics */}
                  {/* StudentsChapters */}
                </Route>
              </Route>
            </Route>
            <Route path="/adminroles" element={<AdminRoles />} />
            <Route path="/dashperms" element={<DashPermissions />} />
            <Route path="/appperms" element={<AppPermissions />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/editroles" element={<EditRole />} />
            <Route path="/tmscons" element={<TermsConditions />} />
            <Route path="/CardsTempletes" element={<CardsTempletes />} />
            <Route path="/CardsPreviewPrint" element={<CardsPreviewPrint />} />
            {/* CardsPreviewPrint */}
            {/* CardsTempletes */}
            <Route
              path="/mainposts"
              element={
                perms?.includes("*12_1") || perms?.startsWith("12_1") ? (
                  <Mainpost />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route path="/about" element={<About />} />
            <Route
              path="/reasons"
              element={
                perms?.includes("*13_1") || perms?.startsWith("13_1") ? (
                  <Reasons />
                ) : (
                  <NotFound />
                )
              }
            />{" "}
            <Route path="/alerts" element={<Alerts />} />
            <Route
              path="*"
              element={
                perms?.includes("*1_1") || perms?.startsWith("1_1") ? (
                  <Chains />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/"
              element={
                perms?.includes("*1_1") || perms?.startsWith("1_1") ? (
                  <Chains />
                ) : (
                  <DefaultLayout
                    children={<div className="childs">Dashboard</div>}
                  />
                )
              }
            ></Route>
            <Route path="/subscription" element={<UniversityStudents />} />
            <Route path="/grades">
              <Route path="" element={<Grades />} />
              <Route path="students" element={<GradeStudents />} />
            </Route>
            <Route path="/Supscription">
              <Route
                path=""
                element={
                  perms?.includes("*9_1") || perms?.startsWith("9_1") ? (
                    <Supscriptions />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route path="supscriptionType" element={<SupscriptionsType />} />
            </Route>
            <Route
              path="logs"
              element={
                perms?.includes("*11_1") || perms?.startsWith("11_1") ? (
                  <DashLogs />
                ) : (
                  <NotFound />
                )
              }
            />{" "}
            <Route
              path="admins"
              element={
                perms?.includes("*16_1") || perms?.startsWith("16_1") ? (
                  <Admins />
                ) : (
                  <NotFound />
                )
              }
            />{" "}
            {/* SupscriptionsType */}
            {/* grades */}
            <Route
              path="inquires"
              element={
                perms?.includes("*14_2") || perms?.startsWith("14_2") ? (
                  <Enquiry />
                ) : (
                  <NotFound />
                )
              }
            />{" "}
            <Route
              path="EnquiryStudents"
              element={
                perms?.includes("*14_3") || perms?.startsWith("14_3") ? (
                  <EnquiryStudents />
                ) : (
                  <NotFound />
                )
              }
            />{" "}
            <Route
              path="DeviceLogs"
              element={
                perms?.includes("*11_2") || perms?.startsWith("11_2") ? (
                  <DeviceLogs />
                ) : (
                  <NotFound />
                )
              }
            />{" "}
          </>
        )}
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
