import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import Modal from "../../components/modal";
import './editrole.css';
import '../DashPermissions/dashpermissions.css';
import { rolesData } from "./data";
import { assignedRoles } from "../DashPermissions/data";
const EditRole = () => {
  const [phone, setPhone] = useState('');
  const [showEndAssign, setShowEndAssign] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [showAddRuleModal, setShowAddRuleModal] = useState(false);
  const [showEndMessage, setShowEndMessage] = useState(false);
  const [roles, setRoles] = useState([]);
  const getRules = () => {
    setRoles(rolesData);
  };
  const handleEndAssign = () => {
    setShowEndMessage(true);
  };
  const handleAssign = (data) => {
    let list = [...roles];
    setRoles(
      list.map((item, index) => {
        if (item.id == data.id) {
          return { ...item, checked: !item.checked };
        } else return { ...item };
      })
    );
  };
  useEffect(() => {
    getRules();
  }, []);
  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            <BurdCrumbs title={"Edit Role"} />
            <div className="role_div">
              <div>
                <label htmlFor="name">Student Name - Phone Number</label>
                <input
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  id="name"
                  type="text"
                  placeholder="Shahid-113254"
                />
              </div>
              <div className="role_icon">
                <h3>Role Icon</h3>
                <div className="icon">
                  <div className="left">
                    <img src={require("../../assets/images/icon.png")} alt="" />
                  </div>
                  <div className="right">
                    <h3>Change Image</h3>
                    <p>Select png Image in Square (64 x 64 Pixels)</p>
                  </div>
                </div>
              </div>
              <div className="person_div">
                <div className="per_img_det">
                  <div className="left">
                    <img src={require("../../assets/images/girl.png")} alt="" />
                  </div>
                  <div className="right">
                    <h6>
                      <span>Victoria Malik</span>
                      <img
                        style={{ width: '15px' }}
                        src={require("../../assets/images/icon.png")}
                        alt=""
                      />
                    </h6>
                    <p>Today at 10:00 PM</p>
                  </div>
                </div>
              </div>
              <div className="btndivaddrule">
                <button
                  onClick={() => {
                    setShowAddRuleModal(true);
                  }}
                  className="btn btn-success"
                >
                  Edit Rule
                </button>
              </div>
            </div>
          </div>
        }
      />
      <div className="modedrole">
        <Modal
          open={showAddRuleModal}
          toggle={setShowAddRuleModal}
          headerTitle={"Assign Roles "}
          children={
            <div className="">
              {roles.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      handleAssign(item);
                    }}
                    key={index}
                    className="role_div_check"
                  >
                    <div className="left">
                      <input type="checkbox" checked={item.checked} />
                    </div>
                    <div className="right">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })}
              <div className="btndivaddrule">
                <button
                  onClick={() => {
                    setShowEndAssign(true);
                    setShowAddRuleModal(false);
                  }}
                  className="btn btn-success"
                >
                  assign
                </button>
              </div>
            </div>
          }
        />
        <Modal
          open={showEndAssign}
          toggle={setShowEndAssign}
          headerTitle={"Assign New Role"}
          children={
            <div>
              <div className="role_div">
                <div>
                  <label htmlFor="name">Student Name - Phone Number</label>
                  <input
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    id="name"
                    type="text"
                    placeholder="Shahid-113254"
                  />
                </div>
                <h4>Assigning Roles</h4>
                {assignedRoles.map((item, index) => {
                  return (
                    <div
                      style={{ cursor: 'initial' }}
                      key={index}
                      className="role_div_check"
                    >
                      <div className="left">
                        <input
                          value={phone}
                          style={{ cursor: 'initial' }}
                          type="checkbox"
                          checked={item.checked}
                        />
                      </div>
                      <div className="right">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  );
                })}
                <div className="mainpass">
                  <label htmlFor="">Enter Main Dashboard Password</label>
                  {showEndMessage ? (
                    <h1 className="endmessage">Roles Successfuly Assigned</h1>
                  ) : (
                    <textarea type="text" />
                  )}
                </div>
                <div className="btndivaddrule done_one">
                  <button
                    onClick={() => {
                      handleEndAssign();
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default EditRole;
