import React, { useState } from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import { search } from '../../../assets/svgIcons';
import "./style.css";
import axios from 'axios';
import { BASE_URL } from '../../../constants';
import { userData } from '../../../data/fake_login_data';
import { ToastContainer, toast } from 'react-toastify';
import { Loader, Toggle } from 'rsuite';
import TableLayout from '../../../components/table';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../components/modal';
const Enquiry = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(null);
  const [data, setData] = useState(null);
  const [type, setType] = useState(1);
  const [loading, setLoading] = useState(false);
  const [revoke, setRevoke] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        BASE_URL + "subscriptions/cards/check_card.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          code: searchValue,
          type: type ? "id" : "code",
        }
      );

      if (response?.data?.status == "success") {
        response.data.message.used_success =
          response.data.message.card_used == 1 ? 1 : 0;
        response.data.message.card_used =
          response.data.message.card_used == 1 ? "مستخدم" : "غير مستخدم";

        // delete response.data.message.student_id;
        delete response.data.message.card_used_for;
        delete response.data.message.courses_ids;
        setData(response?.data?.message);
      } else {
        toast.error("Card Not Found");
        setData([]);
      }
      setLoading(false);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const makeUsed = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        revoke?.type == "reset"
          ? BASE_URL + "subscriptions/cards/reset_card.php"
          : revoke?.type == "stop"
          ? BASE_URL + "subscriptions/cards/stop_card.php"
          : null,
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          ...revoke,
        }
      );

      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setRevoke(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const hiddenArr = ["used_success"];
  return (
    <div id="home" className="enquiry">
      <DefaultLayout
        children={
          <div className="childs">
            <h4 className="customBreadCrumbs">
              <span>Enquiries</span>
            </h4>
            <div className="inquiresType">
              <span>By Card Code - </span>
              <Toggle checked={type} onChange={(e) => setType(e)} />
              <span> - By Card Id</span>
            </div>
            <div className="inquiries">
              <div className="searchCustomInput">
                <label htmlFor="search">{search}</label>
                <input
                  type="search"
                  id="search"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={() => (!loading ? fetchData() : null)}
              >
                {!loading ? "Search" : <Loader />}
              </button>
            </div>

            <div className="cardData">
              {data && data?.card_id ? (
                <>
                  <div className="card_btns">
                    <button
                      style={{ margin: "0 10px" }}
                      className="btn btn-danger"
                      // disabled={data?.used_success == 0}
                      onClick={() => {
                        setRevoke({
                          card_id: data?.card_id,
                          type: "reset",
                        });
                      }}
                    >
                      {!loading ? "Reset" : <Loader />}
                    </button>
                    <button
                      className="btn btn-danger"
                      // disabled={data?.used_success == 1}
                      onClick={() => {
                        setRevoke({
                          card_id: data?.card_id,
                          type: "stop",
                        });
                      }}
                    >
                      {!loading ? "Stop" : <Loader />}
                    </button>
                  </div>
                  <table border="1">
                    <tbody>
                      {data
                        ? Object.keys(data)?.map((item) => {
                            if (
                              !hiddenArr.filter((h_item) => h_item == item)
                                ?.length
                            )
                              return (
                                <tr className="cardRow">
                                  <td>{item}</td>
                                  <td>
                                    <div className="tdRow">
                                      {item == "student_name" &&
                                      data?.student_id > 0 ? (
                                        <div>
                                          <a
                                            className="btn btn-primary"
                                            href={
                                              "/profile?stu_id=" +
                                              data?.student_id
                                            }
                                            style={{ textDecoration: "none" }}
                                            target="_blanck"
                                          >
                                            Show Profile
                                          </a>
                                        </div>
                                      ) : null}
                                      <span>{data[item]}</span>
                                    </div>
                                  </td>
                                </tr>
                              );
                          })
                        : null}
                    </tbody>
                  </table>
                </>
              ) : null}
            </div>
            <Modal
              open={revoke}
              toggle={setRevoke}
              headerTitle={"Card Reset"}
              children={
                <>
                  <h5>{"Are You Sure To Continue ?"}</h5>
                  <div className="flex-box">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        return !loading ? makeUsed() : null;
                      }}
                    >
                      {!loading ? "Yes" : <Loader />}
                    </button>
                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setRevoke(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
};

export default Enquiry;
