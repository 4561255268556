import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/defaultLayout";
import "./style.css";
import "../subjects/style.css";
import BurdCrumbs from "../../components/burdCrumbs";
import TableLayout from "../../components/table";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants";
import { userData } from "../../data/fake_login_data";
import { Input } from "../../components/form-elements";
import { Loader } from "rsuite";
import Modal from "../../components/modal";
import { addition } from "../../assets/svgIcons";
import {
  numberedObject,
  permissions,
  transformedPermissions,
} from "../../profileData";
import TagPicker from "../../components/tagPicker/tagPicker";
import CryptoJS from "crypto-js";
import { perms } from "../../data/side_nav_data";
import { exportToCSV } from "../../functions";
import { getPermissions } from "../../store/userData";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
const Admins = () => {
  const [searchHeaderKet, setSearchHeaderKey] = useState("");
  const [disable, setDisable] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const [editId, setEditId] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [chains, setChains] = useState([]);
  const [selectedChains, setSelectedChains] = useState([]);
  const [data, setData] = useState(null);
  const [opened, setOpened] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData();
  }, []);

  const headers = [
    {
      label: "ID",
      dataIndex: "admin_user_id",
      sort: true,
      search: true,
      equal: true,
    },
    perms?.includes("*16_4") ||
    perms?.startsWith("16_4") ||
    perms?.includes("*16_3") ||
    perms?.startsWith("16_3")
      ? {
          label: "Actions",
          type: "children",
          children: ({ headers, row, index, lastIndex }) => {
            return (
              <div className="menu_Open_close">
                <div
                  className="open_close"
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() =>
                    setOpened(
                      opened == row?.admin_user_id ? null : row?.admin_user_id
                    )
                  }
                >
                  <img
                    src="https://res.cloudinary.com/duovxefh6/image/upload/v1701489400/menu_hrpzcb.png"
                    alt=""
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
                {row?.admin_user_id == opened ? (
                  <div
                    className={
                      index == lastIndex - 1 ||
                      index == lastIndex - 2 ||
                      index == lastIndex - 3
                        ? "actions-views row-actions-view"
                        : "actions-views column-actions-view"
                    }
                  >
                    {perms?.includes("*16_4") || perms?.startsWith("16_4") ? (
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          setEditId(row);
                          setSelectedChains(row?.course_chains_id?.split("**")?.map(item=>({value:item, label: chains?.filter(c_item=>c_item?.chain_id == item)[0]?.chain_name})))
                        }}
                      >
                        Edit
                      </button>
                    ) : null}
                    {perms?.includes("*16_3") || perms?.startsWith("16_3") ? (
                      <button
                        className="btn btn-danger"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          setDisable(row);
                        }}
                      >
                        {row?.disable == "false" ? "Disable" : "Enable"}
                      </button>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          },
        }
      : {},
    {
      label: "Admin Email",
      dataIndex: "user_email",
      search: true,
      sort: true,
    },
    {
      label: "Admin Password",
      dataIndex: "password",
      search: true,
      sort: true,
    },
  ];
  window.addEventListener("click", (e) => {
    if (
      opened &&
      !e.target?.classList?.contains("actions-views") &&
      !e.target?.parentNode?.classList?.contains("open_close")
    ) {
      setOpened(null);
    }
  });

  const fetchData = async () => {
    try {
      const response = await axios.post(
        BASE_URL + "admin_user/select_admin_user.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      const responseChains = await axios.post(
        BASE_URL + "courses/chains/get_all.php",
        {
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
        }
      );

      setData(response?.data?.message);
      console.log(responseChains)
      setChains(responseChains?.data);
    } catch (err) {
      setData([]);
      toast.error(err?.message);
    }
  };
  const getUserData = async () => {
    await axios
      .post(BASE_URL + "admin_user/get_admin_data.php", {
        admin_id: userData?.user_id,
        access_token: userData?.access_token,
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          res.data.message.user_id = res.data.message?.admin_user_id;
          res.data.message.access_token = res.data.message?.token_value;
          localStorage.setItem(
            "account",
            btoa(JSON.stringify(res?.data?.message))
          );
          dispatch(getPermissions(userData?.permission));
        } else {
          localStorage.removeItem("account");
          window?.location?.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addAdmin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    // selectedTags
    const encryptedValue = CryptoJS.AES.encrypt(
      selectedTags,
      "*&&^##/TS/Dashboard/ADMINS/##^&&*"
    ).toString();
    try {
      const response = await axios.post(
        BASE_URL + "admin_user/insert_admin.php",
        {
          ...adminData,
          permissions: encryptedValue,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          course_chains_id:selectedChains?.map(item=>item?.value)?.join("**")
        }
      );

      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setShowAdd(false);
        setAdminData(false);
        form.reset();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const editAdmin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    const encryptedValue = CryptoJS.AES.encrypt(
      selectedTags,
      "*&&^##/TS/Dashboard/ADMINS/##^&&*"
    ).toString();
    try {
      const response = await axios.post(
        BASE_URL + "admin_user/update_admin_data.php",
        {
          ...editId,
          permission: encryptedValue,
          admin_id: userData?.user_id,
          access_token: userData?.access_token,
          course_chains_id:selectedChains?.map(item=>item?.value)?.join("**")
        }
      );

      if (response.data?.status == "success") {
        toast.success(response.data?.message);
        fetchData();
        setShowAdd(false);
        setAdminData(false);
        form.reset();
        getUserData();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  const onSelect = (selectedTags) => {
    try {
      const combine = Object.keys(selectedTags).map((tag) =>
        selectedTags[tag]
          ?.map((t) => tag?.toString() + "_" + t?.toString())
          .join("**")
      );
      setSelectedTags(combine.join('**'));
    } catch (error) {
      console.log(error);
    }
  };
  const disableAdmin = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL + "admin_user/disable_admin.php",
        {
          admin_id: userData?.user_id,
          admin_user_id: disable?.admin_user_id,
          disable: disable?.disable == "true" ? "false" : "true",
          access_token: userData?.access_token,
        }
      );

      if (response.data?.status == "success") {
        if (response.data?.message) {
          toast.success(response.data?.message);
          fetchData();
          setDisable(null);
          setLoading(false);
        } else {
          toast.error("This Student has already banned");
          setDisable(null);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error(response.data?.message);
      }

      // setShowAdd(false);
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    try {
      if (editId) {
        const selectedTags = numberedObject(
          CryptoJS.AES.decrypt(
            decodeURIComponent(editId?.permission),
            "*&&^##/TS/Dashboard/ADMINS/##^&&*"
          ).toString(CryptoJS.enc.Utf8)
        );
        onSelect(selectedTags);
      }
    } catch (err) {
      console.log(err);
    }
  }, [editId]);

  return (
    <div id="home">
      <DefaultLayout
        setSearchHeaderKey={setSearchHeaderKey}
        children={
          <div className="childs">
            {/* <BurdCrumbs title={"Admins"} /> */}
            <h4 className="customBreadCrumbs">
              <span>Admins</span>
              {perms?.includes("*16_2") || perms?.startsWith("16_2") ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowAdd(true)}
                >
                  <span>{addition}</span>
                  <span>Add Admin</span>
                </button>
              ) : null}
            </h4>
            {(perms?.includes("*17_9") || perms?.startsWith("17_9")) &&
            data?.length ? (
              <button
                className="btn btn-primary exportBtn"
                onClick={() => {
                  exportToCSV(data, "Admins Data");
                }}
              >
                Export
              </button>
            ) : null}
            <TableLayout
              searchHeaderKet={searchHeaderKet}
              headers={headers}
              data={data}
            />
            <Modal
              headerTitle={"Add Admin"}
              open={showAdd}
              toggle={setShowAdd}
              children={
                <form
                  action="#"
                  onSubmit={!loading ? addAdmin : (e) => e.preventDefault()}
                >
                  <div className="inputField">
                    <label htmlFor="Admin_email">Admin Email</label>
                    <Input
                      type={"text"}
                      id={"Admin_email"}
                      name={"Admin_email"}
                      required={true}
                      onChange={(e) =>
                        setAdminData({
                          ...adminData,
                          user_email: e.value,
                        })
                      }
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="Admin_password">Admin Password</label>
                    <Input
                      type={"text"}
                      id={"Admin_password"}
                      name={"Admin_password"}
                      required={true}
                      onChange={(e) =>
                        setAdminData({ ...adminData, password: e.value })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Permissions</label>
                    {/* permissions */}
                    <TagPicker
                      options={permissions}
                      onSelect={onSelect}
                      numberedObject={selectedTags}
                    />
                  </div>
                  <div className="mb-3">
                  <label className="form-label">Subjects</label>
                  <ReactSelect
                  value={selectedChains}
                  options={chains?.map(item=>{
                    return {label:item?.chain_name, value: item?.chain_id}
                  })}
isMulti={true}
onChange={(e)=>{
  setSelectedChains(e)
}}
                  />
                  </div>
                  <button className="btn btn-success">
                    {!loading ? "Save" : <Loader />}
                  </button>
                </form>
              }
            />
            <Modal
              headerTitle={"Edit Admin"}
              open={editId}
              toggle={setEditId}
              children={
                <form
                  action="#"
                  onSubmit={!loading ? editAdmin : (e) => e.preventDefault()}
                >
                  <div className="inputField">
                    <label htmlFor="Admin_email">Admin Email</label>
                    <Input
                      type={"text"}
                      id={"Admin_email"}
                      name={"Admin_email"}
                      defaultValue={editId?.user_email}
                      onChange={(e) =>
                        setEditId({
                          ...editId,
                          user_email: e.value,
                        })
                      }
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="Admin_password">Admin Password</label>
                    <Input
                      type={"text"}
                      id={"Admin_password"}
                      name={"Admin_password"}
                      defaultValue={editId?.password}
                      onChange={(e) => {
                        setEditId({ ...editId, password: e.value });
                      }}
                    />
                  </div>
                  {/* numberedObject */}
                  <div className="mb-3">
                    <label className="form-label">Permissions</label>
                    {/* permissions */}
                    <TagPicker
                      options={permissions}
                      onSelect={onSelect}
                      numberedObject={numberedObject(
                        CryptoJS.AES.decrypt(
                          decodeURIComponent(editId?.permission),
                          "*&&^##/TS/Dashboard/ADMINS/##^&&*"
                        ).toString(CryptoJS?.enc?.Utf8)
                      )}
                    />
                  </div>
                  <div className="mb-3">
                  <label className="form-label">Subjects</label>
                  <ReactSelect
                  value={selectedChains}
                  options={chains?.map(item=>{
                    return {label:item?.chain_name, value: item?.chain_id}
                  })}
isMulti={true}
onChange={(e)=>{
  setSelectedChains(e)
}}
                  />
                  </div>
                  <button className="btn btn-success">
                    {!loading ? "Save" : <Loader />} 
                  </button>
                </form>
              }
            />
            <Modal
              open={disable}
              toggle={setDisable}
              headerTitle={"Disable Admin"}
              children={
                <>
                  {disable?.disable == "true" ? (
                    <h5>
                      {"Are You Sure To Disable Admin - " +
                        disable?.user_email +
                        " ?"}
                    </h5>
                  ) : (
                    <h5>
                      {"Are You Sure To Enable Admin - " +
                        disable?.user_email +
                        " ?"}
                    </h5>
                  )}
                  <div className="flex-box">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        return !loading ? disableAdmin() : null;
                      }}
                    >
                      {!loading ? "Yes" : <Loader />}
                    </button>

                    <button
                      className="btn btn-primary"
                      width={"fit-content"}
                      onClick={() => {
                        setDisable(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </>
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default Admins;
